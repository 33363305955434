<template>
  <el-popover placement="right" :title="$t('login.pswValidatePopover.title')" width="296" trigger="focus">
    <ul class="rules">
      <li class="rule">
        <i class="el-icon-success" :class="{ 'pass': validate.rule1 }"></i>
        <p>{{ $t('login.pswValidatePopover.rule1') }}</p>
      </li>
      <li class="rule">
        <i class="el-icon-success" :class="{ 'pass': validate.rule2 }"></i>
        <p>{{ $t('login.pswValidatePopover.rule2') }}</p>
      </li>
      <li class="rule">
        <i class="el-icon-success" :class="{ 'pass': validate.rule3 }"></i>
        <p>{{ $t('login.pswValidatePopover.rule3') }}</p>
      </li>
      <li class="rule">
        <i class="el-icon-success" :class="{ 'pass': validate.rule4 }"></i>
        <p>{{ $t('login.pswValidatePopover.rule4') }}</p>
      </li>
    </ul>
    <template slot="reference">
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
import { validate } from 'json-schema'


export default {
  data() {
    return {
      validate: {
        rule1: false,
        rule2: false,
        rule3: false,
        rule4: false
      },
      childInstance: null
    }
  },
  watch: {
    "childInstance.value": function (newV) {
      const firReg = new RegExp('^.{8,15}$')
      const secReg = new RegExp('(?=.*?[A-Z])')
      const thiReg = new RegExp('(?=.*?[a-z])')
      const fouReg = new RegExp('(?=.*?[0-9])(?=.*?[#?!@_$%^&*-])')
      this.validate.rule1 = firReg.test(newV)
      this.validate.rule2 = secReg.test(newV)
      this.validate.rule3 = thiReg.test(newV)
      this.validate.rule4 = fouReg.test(newV)
    }
  },
  mounted() {
    this.childInstance = this.$slots.default[0].componentInstance;
  }
}
</script>

<style scoped lang="scss">
.rules {
  .rule {
    display: flex;
    align-items: center;
    line-height: 20px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .el-icon-success.pass {
      color: #0acc8b
    }

    p {
      margin-left: 9px;
    }
  }
}
</style>
