<style scoped lang="scss">
.root-container {
  background-color: #f7fafd;
  height: 100%;
  display: flex;
  flex-direction: column;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px 32px;
    height: 60px;
    margin-bottom: 20px;
    background-color: #193966;
    flex-shrink: 0;
  }

  .loginLogo {
    float: left;
  }

  .head-msg {
    float: right;
    padding-top: 12px;
    display: flex;
    color: #333;
    padding-left: 15px;
  }

  .login-msg-item {
    border-right: 1px solid #d7d7d7;
    height: 20px;
    padding: 0 15px;
    align-items: center;
    display: flex;
  }

  .head-user {
    border-right: 0px solid #d7d7d7;
    position: relative;
    padding-right: 0px;
  }

  .head-user-icon {
    background: url(../static/img/menu/i-todown.png) no-repeat right center;
    width: 10px;
    height: 10px;
  }

  .head-box {
    width: 120px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    position: absolute;
    top: 33px;
    right: 0px;
    z-index: 10;
    background-color: #fff;
  }

  .head-item {
    height: 36px;
    width: 120px;
    cursor: pointer;
    color: #333;
    line-height: 36px;
    font-size: 14px;
    padding-left: 38px;
  }

  .head-logout {
    border-bottom: 1px solid #e6e6e6;
    background: url(../static/img/auto/logout_d.png) no-repeat 15px center;
    border-radius: 5px 5px 0 0;
  }

  .head-logout:hover {
    background: #00a0e4 url(../static/img/auto/logout_a.png) no-repeat 15px
      center;
    color: #fff;
  }

  .head-reset {
    background: url(../static/img/auto/reset_d.png) no-repeat 15px center;
    border-radius: 0 0 5px 5px;
  }

  .head-reset:hover {
    background: #00a0e4 url(../static/img/auto/reset_a.png) no-repeat 15px
      center;
    color: #fff;
  }

  .main {
    display: flex;
    top: 50px;
  }

  /*主内容界面样式*/
  .content-container-root {
    flex: 1;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    /*background-color:#ECEEF0;*/
    width: calc(100vw - 170px);
    height: 100%;
    padding: 0px 30px 30px 25px;
    /* padding-bottom: 30px; */
  }

  .content-wrapper {
    height: 100%;
    width: 100%;
  }

  .bgwatermark {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

  .bglogo {
    background: url(../static/img/bglogo.png) no-repeat;
    width: 130px;
    height: 130px;
    float: left;
    margin-left: 200px;
    margin-top: 100px;
    background-size: 70%;
  }

  .bgname {
    font-size: 25px;
    opacity: 0.1;
    transform: rotate(-32deg);
    width: 80px;
    margin-top: 76px;
    margin-left: 11px;
  }

  .nav-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      width: auto;
      height: 32px;
    }

    .enlarge {
      width: 105px;
      height: auto;
    }
  }

  .nav-logo-text {
    margin-left: 24px;
    color: #2E5A99;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  }

  @media screen and (max-width: 600px) {
    .nav-title {
      display: none;
    }
  }

  .el-dropdown-link {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: #fff;
    margin-right: 24px;
    vertical-align: middle;

    .icon {
      width: 16px;
      height: 16px;
    }

    .el-icon-arrow-down {
      color: #fff;
    }
  }

  .el-dropdown-link:hover .el-icon-arrow-down {
    transform: rotate(180deg);
  }

  .el-icon-arrow-down {
    font-size: 12px;
    color: #4a4a4a;
    font-weight: bold;
    transition: all 0.3s ease;
  }

  .nav-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;

    .buy-car {
      position: relative;
      margin-right: 16px;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
      }

      .dot {
        position: absolute;
        top: -50%;
        right: -50%;
        display: inline-block;
        padding: 0 4px;
        opacity: 1;
        background: #ff6666;
        border-radius: 50%;
        font-size: 12px;
        // font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .account-setting {
      margin-right: 24px;
    }
  }

  .nav-right-text {
    color: #fff;
    font-size: 12px;
    margin-right: 10px;
  }

  .nav-right-time {
    border-radius: 16px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 32px;
    // width: 184px;
    height: 32px;
    padding-left: 32px;
    background: rgba(90, 156, 248, 0.2) url('../static/projImg/time-left.svg')
      10px center no-repeat;
    color: #fff;
    font-weight: bold;
    margin-right: 30px;
    padding-right: 8px;
  }

  ::v-deep .menu-box {
    height: calc(100vh - 100px);
    overflow: auto;
    width: 200px;
    margin-right: 5px;

    .el-submenu {
      .el-menu-item {
        padding-left: 40px !important;
      }

      .el-submenu__title {
        padding: 0 8px !important;
        background-color: unset;
      }
    }

    .el-submenu__icon-arrow,
    .el-icon-arrow-right {
      right: 0 !important;
    }

    .el-menu-item {
      background-color: unset;
    }

    .el-menu-item.is-active {
      background-color: #d9e9ff;
    }

    // margin-top: 34px;
    .el-menu-item .el-popover__reference {
      display: block;
    }
  }

  .menu-box {
    ::v-deep {
      .el-submenu__title {
        display: flex;
        align-items: center;
      }

      .el-submenu__title i {
        flex-shrink: 0;
      }
    }
  }

  .menu-title1 {
    position: relative;
    flex-grow: 1;
    margin-left: 8px;
  }

  .menu-title1 .menu-title-more {
    float: right;
    margin-top: 18px;
    font-size: 14px;
  }

  .menu-title-more.menu-selected {
    font-weight: bold;
  }

  .menu-sub-box {
    position: absolute;
    right: -137px;
    top: 0px;
    width: 120px;
    box-sizing: border-box;
    /*height: 189px;*/
    /*padding: 10px 0px;*/
    border-radius: 8px;
    border: 1px solid #adcefc;
    background: #ffffff;
    z-index: 9;

    /*display: none;*/
  }

  .menu-sub-box-en {
    position: absolute;
    right: -168px;
    top: 0px;
    width: 150px;
    box-sizing: border-box;
    /*height: 189px;*/
    /*padding: 10px 0px;*/
    border-radius: 8px;
    border: 1px solid #adcefc;
    background: #ffffff;
    z-index: 9;

    /*display: none;*/
  }

  .menu-sub-box-item {
    padding: 0 10px;
    font-weight: normal;
    font-size: 14px;
    color: #4a4a4a;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }

  .menu-sub-box-item:hover {
    background-color: rgba(90, 156, 248, 0.15);
  }

  .menu-sub-box-item.menu-sub-box-item-actived {
    background-color: rgba(90, 156, 248, 0.15);
  }

  .menu-title1-box:hover .menu-title1 .menu-sub-box {
    /*display: unset;*/
  }

  .short-menu-box {
    width: 50px;
    height: 50px;
    // box-shadow: 0 0 10px 0 rgba(87, 138, 209, 0.05);
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
    // position: absolute;
    // left: 0px;
    // top: 4px;
    background: transparent url('~@/static/projImg/short-menu-icon.svg') center
      center no-repeat;
    cursor: pointer;

    .wrap-short-menu-box {
      width: 100%;
      height: 100%;
      position: relative;

      .short-menu-sub-box {
        left: 0px;
        top: 50px;
        // width: 165px;
        position: absolute;
        z-index: 9;
        margin-top: 0px;
      }
    }
  }

  .icon-exam {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('~@/static/img/exam-mode.svg') center center no-repeat;
    margin-bottom: -3px;
  }
}

.icon-shopping-bag::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/static/img/shopping-bag.svg') center center no-repeat;
  margin-bottom: -4px;
}

.icon-template::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/static/img/template.svg') center center no-repeat;
  margin-bottom: -4px;
}

@media screen and (max-width: 1140px) {
  .menu-box-show {
    display: none;
  }

  .title-PDL15 {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1140px) {
  .short-menu-box {
    display: none;
  }
}

::v-deep .resetPwd-box {
  .el-dialog {
    min-width: 320px;
    max-width: 605px;

    .el-dialog__footer {
      .dialog-footer {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px 0px 5px 0px;
      }
    }
  }
}

.menu-mask {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.shopDropdownList {
  .el-dropdown-menu__item{
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-dropdown-menu__item:first-of-type {
    padding: 0px;

    ::v-deep .el-input__inner {
      padding-left: 20px;
      border: 0;
    }
  }
}

.download-mgm {
  margin-right: 24px;
}

.download-status {
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }

  &.download-status_success {
    color: #0acc8b;
  }

  &.download-status_failed {
    color: #ff4040;
  }

  &.download-status_pending {
    color: #ff9326;
  }
}
</style>
<style lang="scss">
.menu-popper {
  min-width: 100px !important;
  left: 186px !important; // popover弹窗位置，写死不太好又要向右偏移，暂时这样子
  padding: 0px !important;
  border: 1px solid #3e8cfa !important;
  border-radius: 8px !important;
  box-shadow: 2px 2px 10px 0px rgba(46, 90, 153, 0.2) !important;

  .subPopover {
    height: 30px;
    line-height: 30px;
    padding: 0px 20px;
    cursor: pointer;
  }

  .subPopover-active {
    color: #5a9cf8;
    background-color: rgba(90, 156, 248, 0.15);
  }

  .subPopover:hover {
    color: #2e5a99;
    background-color: #ebf3ff;
    /* border-radius: 8px; */
  }

  .subPopover:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .subPopover:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

//菜单换行
.menu-item-wrap {
  display: inline-block;
  white-space: pre-wrap;
  line-height: 20px;
}

</style>

<template>
  <div class="root-container">
    <div v-show="shortMenuShow" class="menu-mask"></div>
    <!--头部登录信息-->
    <nav>
      <div class="nav-logo title-PDL15">
        <div class="short-menu-box" v-clickoutside="clickoutsideFunShort">
          <div
            class="wrap-short-menu-box"
            @click="shortMenuShow = !shortMenuShow"
          >
            <div v-if="shortMenuShow" class="menu-box short-menu-sub-box">
              <el-menu
                :default-active="menuIndex"
                class="el-menu-vertical-demo"
                :collapse="isCollapse"
                @select="selectMenu"
                v-clickoutside="clickoutsideFun"
              >
                <div
                  v-for="(m1, index) in navs"
                  :key="index"
                  @click.stop.prevent="showSubPopper(null)"
                >
                  <el-submenu
                    v-if="m1.children && m1.children.length > 0"
                    :index="m1.id"
                  >
                    <template slot="title">
                      <i :class="m1.icon"></i>
                      <span class="menu-title1 menu-item-wrap">{{
                        $i18n.locale == 'en' ? m1.nameEn : m1.name
                      }}</span>
                    </template>
                    <div
                      v-for="m2 in m1.children"
                      :key="m2.id"
                      @click="showSubPopper(m2.id, 'shortMenu')"
                    >
                      <el-menu-item
                        v-if="m2.children && m2.children.length > 0"
                        :index="m2.id"
                      >
                        <el-popover
                          placement="right"
                          :visible-arrow="false"
                          popper-class="menu-popper"
                          trigger="click"
                          :ref="`popoverRef${m2.id}`"
                        >
                          <div
                            v-for="(m3, index) in m2.children"
                            :key="index"
                            :class="[
                              'subPopover',
                              m3subActive === m3.id ? 'subPopover-active' : '',
                            ]"
                            @click="subMenuClick(m3, m1)"
                          >
                            <span class="menu-item-wrap">{{
                              $i18n.locale == 'en' ? m3.nameEn : m3.name
                            }}</span>
                          </div>
                          <span slot="reference">
                            <span class="menu-item-wrap">{{
                              $i18n.locale == 'en' ? m2.nameEn : m2.name
                            }}</span>
                            <i
                              class="el-icon-arrow-right"
                              style="
                                        zoom: 0.75;
                                        position: absolute;
                                        right: 17px;
                                        top: 25px;
                                      "
                            ></i>
                          </span>
                        </el-popover>
                      </el-menu-item>
                      <el-menu-item
                        v-else
                        :index="m2.id"
                        @click="subMenuClick(m2, m1)"
                      >
                        <i :class="m2.icon"></i>
                        <span slot="title" class="menu-item-wrap">{{
                          $i18n.locale == 'en' ? m2.nameEn : m2.name
                        }}</span>
                      </el-menu-item>
                    </div>
                  </el-submenu>
                  <el-menu-item v-else :index="m1.id" @click="menuclick(m1)">
                    <i :class="m1.icon"></i>
                    <span slot="title" class="menu-title1 menu-item-wrap">{{
                      $i18n.locale == 'en' ? m1.nameEn : m1.name
                    }}</span>
                  </el-menu-item>
                </div>
              </el-menu>
            </div>
          </div>
        </div>
        <img :src="require('@/assets/dttLogin/dtt-login.svg')" alt="logo" class="logo" />
        <!-- <switchProduct
          :navs="navsSwitch"
          @switchProduct="switchProduct"
          @gotoAcc="gotoAcc"
        /> -->
      </div>
      <div class="nav-right">
        <template v-if="iddTimeShow">
          <!-- 闭市截止时间 -->
          <div class="nav-right-text">
            {{ $t('common.countdownMarketClosure') }}
          </div>
          <div class="nav-right-time">
            {{ timeObj.hour }}{{ $t('common.hour') }}:{{ timeObj.minute
            }}{{ $t('common.minute') }}:{{ timeObj.second
            }}{{ $t('common.second') }}
          </div>
        </template>
        <!-- ========================================= 购物车 ========================================= -->
        <div class="buy-car" @click="goBuyCar" v-if="addCartShow">
          <img src="@/static/projImg/buyCar.svg" alt="/" />
          <span class="dot">{{ cartNum }}</span>
        </div>
        <div class="account-setting" v-if="navsBtnShow">
          <el-button type="text" @click="handleUserSetting"
            ><img src="@/static/projImg/account-setting.svg" alt=""
          /></el-button>
        </div>
        <!-- ========================================= 资金方台账下载 ========================================= -->
        <DigipoDownloadMgm v-if="productType == 'DIGIPO'"></DigipoDownloadMgm>
        <el-popover
          v-else
          placement="bottom"
          @show="handlePopShow"
          :title="$t('bigHome.downloadManagement')"
          width="600"
          trigger="click"
        >
          <el-table :data="gridData" :loading="downloadTableLoading">
            <el-table-column type="index" width="50" :label="$t('bigHome.No')">
            </el-table-column>
            <el-table-column
              width="180"
              :show-overflow-tooltip="true"
              property="fileName"
              :label="$t('bigHome.fileName')"
            ></el-table-column>
            <el-table-column width="100" :label="$t('bigHome.status')">
              <template slot-scope="{ row }">
                <div
                  class="download-status"
                  :class="STATUS_MAP[row.statusKey].class"
                >
                  <img :src="STATUS_MAP[row.statusKey].path" />
                  <span>{{ row.statusDisplay }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="200"
              property="createTime"
              :label="$t('bigHome.createTime')"
            ></el-table-column>
            <el-table-column
              width="100"
              :label="$t('bigHome.action')"
              fixed="right"
            >
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  plain
                  @click="handleDownload(row)"
                  size="small"
                  >{{ $t('bigHome.download') }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-button slot="reference" class="download-mgm" type="text"
            ><img src="@/static/projImg/download.svg" alt=""
          /></el-button> -->
        </el-popover>
        <!-- ========================================= 大首页切换语言 ========================================= -->
        <el-dropdown style="margin-right: 8px">
          <span class="el-dropdown-link">
            <!--{{userName}}：{{$store.state.currentCompany.entEnName | filterUndefinedNull}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            {{ bigHomeLangString | filterUndefinedNull }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item @click.native="changeLangBigHome('zh')"
              >简体中文</el-dropdown-item
            > -->
            <el-dropdown-item @click.native="changeLangBigHome('en')"
              >English</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- ========================================= 子应用语言 ========================================= -->
        <!-- <el-dropdown v-else-if="showLangChange" style="margin-right: 8px">
          <span class="el-dropdown-link"> -->
        <!--{{userName}}：{{$store.state.currentCompany.entEnName | filterUndefinedNull}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!-- {{ langString | filterUndefinedNull }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changeLang('zh')">简体中文</el-dropdown-item>
            <el-dropdown-item @click.native="changeLang('en')">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <!-- 店铺名称下拉 -->
        <div>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            class="shopDropdown"
            ref="shopDropdownRef"
          >
            <span class="el-dropdown-link">
              {{ shopName }}
              <img class="icon" src="@/static/projImg/exam-mode.svg" alt="" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-input
                  v-model="compName"
                  @input="filterShopName"
                  size="mini"
                  placeholder="Search"
                  suffix-icon="el-icon-search"
                ></el-input>
              </el-dropdown-item>
              <el-dropdown-item
                v-for="item in shopNameList"
                :key="item.enterpriseId"
                @click.native="changeShop(item)"
              >
                <i class="icon-shopping-bag"></i>
                <span :title="$i18n.locale == 'zh'
                      ? item.companyNameCn || item.companyName
                      : item.companyName || item.companyNameCn">
                  {{
                    $i18n.locale == 'zh'
                      ? item.companyNameCn || item.companyName
                      : item.companyName || item.companyNameCn
                  }}
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-dropdown>
          <span class="el-dropdown-link" :title="userName | filterUndefinedNull">
            <!--{{userName}}：{{$store.state.currentCompany.entEnName | filterUndefinedNull}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            {{ userName | filterUndefinedNull }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- 重置密码 -->
            <el-dropdown-item @click.native="resetPwd">
              {{ $t('login.resetPassword') }}
            </el-dropdown-item>
            <!-- 退出登录 -->
            <el-dropdown-item @click.native="logout">
              {{ $t('login.logout') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </nav>
    <div class="main" id="main">
      <!-- 菜单组件 -->
      <div class="menu-box menu-box-show">
        <el-menu
          v-if="navs.length"
          :default-active="menuIndex"
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          @select="selectMenu"
          v-clickoutside="clickoutsideFun"
          ref="menuRef"
          :default-openeds="allMenuIds"
        >
          <div v-for="item in showingProduct.children" :key="item.id">
            <template v-if="item.children && item.children.length > 0">
              <el-submenu :index="item.id">
                <template slot="title">
                  <svg-icon :iconClass="item.icon" fill="#2E5A99"></svg-icon>
                  <span class="menu-title1 menu-item-wrap">{{
                    $i18n.locale == 'en' ? item.nameEn : item.name
                  }}</span>
                </template>
                <div
                  v-for="m2 in item.children"
                  :key="m2.id"
                  @click="showSubPopper(m2.id)"
                >
                  <el-menu-item
                    v-if="m2.children && m2.children.length > 0"
                    :index="m2.id"
                  >
                    <el-popover
                      placement="right"
                      :visible-arrow="false"
                      popper-class="menu-popper"
                      trigger="click"
                      :ref="`popoverRef${m2.id}`"
                    >
                      <div
                        v-for="m3 in m2.children"
                        :key="m3.id"
                        :class="[
                          'subPopover',
                          m3subActive === m3.id ? 'subPopover-active' : '',
                        ]"
                        @click="subMenuClick(m3, m2)"
                      >
                        <span class="menu-item-wrap">{{
                          $i18n.locale == 'en' ? m3.nameEn : m3.name
                        }}</span>
                      </div>
                      <span slot="reference">
                        <span class="menu-item-wrap">{{
                          $i18n.locale == 'en' ? m2.nameEn : m2.name
                        }}</span>
                        <i
                          class="el-icon-arrow-right"
                          style="
                            zoom: 0.75;
                            position: absolute;
                            right: 17px;
                            top: 25px;
                          "
                        ></i>
                      </span>
                    </el-popover>
                  </el-menu-item>
                  <el-menu-item
                    v-else
                    :index="m2.id"
                    @click="subMenuClick(m2, showingProduct)"
                  >
                    <i :class="m2.icon"></i>
                    <span slot="title" class="menu-item-wrap">{{
                      $i18n.locale == 'en' ? m2.nameEn : m2.name
                    }}</span>
                  </el-menu-item>
                </div>
              </el-submenu>
            </template>
            <el-menu-item v-else :index="item.id" @click="menuclick(item)">
              <svg-icon :iconClass="item.icon" fill="#2E5A99"></svg-icon>
              <span slot="title" class="menu-title1 menu-item-wrap">{{
                $i18n.locale == 'en' ? item.nameEn : item.name
              }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
      <!--右侧主页面-->
      <section class="content-container-root">
        <!--内容页-->
        <div class="content-wrapper" v-if="finishHome">
          <!--显示默认效果-->
          <router-view></router-view>
        </div>
      </section>
    </div>
    <el-dialog
      class="resetPwd-box"
      :title="$t('login.resetPassword')"
      :visible.sync="isResetPwd"
      @close="toClose"
      :close-on-click-modal="false"
      width="85%"
    >
      <div class="err-text"></div>
      <el-form
        :model="formReset"
        :rules="formResetrules"
        ref="formReset"
        status-icon
        hide-required-asterisk
        :validate-on-rule-change="false"
      >
        <el-form-item
          prop="oldPassword"
          ref="oldPassword"
          :label="$t('login.oldPassword')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formReset.oldPassword"
            show-password
            autocomplete="off"
            @focus="clearMsgErr('oldPassword')"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          ref="password"
          :label="$t('login.newPassword')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formReset.password"
            show-password
            autocomplete="off"
            @focus="clearMsgErr('password')"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          prop="confirmPassword"
          ref="confirmPassword"
          :label="$t('login.confirmNewPassword')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formReset.confirmPassword"
            show-password
            autocomplete="off"
            @focus="clearMsgErr('confirmPassword')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="err-text" style="padding-left: 120px">{{ resetErrMsg }}</div>
      <div slot="footer" class="dialog-footer">
        <!-- 取 消 -->
        <el-button @click="toClose">{{ $t('login.cancel') }}</el-button>
        <!-- 确 定 -->
        <el-button type="primary" @click="toReset('formReset')">
          {{ $t('login.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 
    // attention
    companyChooseAlert 选择企业页面，route路由 -> path: '/companyChoose'
    在登录的时候已经访问了一次，home页面再次引入会跑多一次里面的逻辑，需要注意
    在这里之所以直接引入的原因：切换店铺之后又要跑companyChoose的逻辑（例如调setOrgId，平台协议等等）
    后续如果因为这个地方又调多一次逻辑导致需求互斥（或者逻辑混乱）就要把companyChoose选择店铺的逻辑给抽离出来
    -->
    <!-- <div v-if="showCompChoose"> -->
    <!-- @sure="compSure" -->
    <companyChooseAlert
      :showCompChoose.sync="showCompChoose"
      :tableList="userCompanyList"
      @close="compClose"
      @getProductInfo="getProductInfo"
      ref="companyDialogRef"
    ></companyChooseAlert>
    <!-- </div> -->
  </div>
</template>

<script>
// import Menu from '@/components/menu/menu.vue'
import DigipoDownloadMgm from './digipoDownloadMgm.vue'
import { mapState } from 'vuex'
import companyChooseAlert from '@/views/companyChooseAlert/companyChooseAlert.vue'
import switchProduct from '@/views/components/switchProduct'
import { filterDictParam } from '@/common/util.js'
export default {
  name: 'home',
  components: {
    // Menu
    companyChooseAlert,
    switchProduct,
    DigipoDownloadMgm,
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.pleaseEnterNewPassword')))
      } else if (value !== this.formReset.password) {
        callback(new Error(this.$t('login.passwordIncorrectAgain')))
      } else {
        callback()
      }
    }
    return {
      downloadTableLoading: false,
      STATUS_MAP: {
        PENDING: {
          path: require('@/assets/warning--filled.svg'),
          class: 'download-status_pending',
        },
        FAILED: {
          path: require('@/assets/warning--filled2.svg'),
          class: 'download-status_failed',
        },
        SUCCESS: {
          path: require('@/assets/checkmark--filled.svg'),
          class: 'download-status_success',
        },
      },
      addCartShow: false,
      cartNum: 0,
      menuHide: true,
      menuIndex: '1',
      m3subActive: '',
      isCollapse: false,
      isMore: false, //是否展示退出登录和重置密码等功能
      userName: '',
      bglogoArr: [],
      //以下是密码重置相关的
      isResetPwd: false, //是否显示重置密码弹框
      // pwd: "",//新密码
      // pwdConfirm: "",//确认密码
      resetErrMsg: '', //重置密码的错误提示信息
      isReseting: false, //是否重置密码中
      navs: [],
      navsUser: [], //账户管理
      navsBtnShow: true, //是否展示账户中心按钮
      navsSwitch: [], //除去账户管理
      finishHome: false,
      showCompChoose: false,
      userCompanyList: [],
      compName: '',
      timer: null,
      timeObj: {
        hour: '00',
        minute: '00',
        second: '00',
      },
      //重置密码
      formReset: {
        confirmPassword: '',
        oldPassword: '',
        password: '',
      },
      formLabelWidth: '170px',
      formResetrules: {
        oldPassword: [
          {
            required: true,
            message: this.$t('login.pleaseEnterOldPassword'),
            trigger: 'submit',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('login.pleaseEnterNewPassword'),
            trigger: 'submit',
          },
        ],
        confirmPassword: [{ validator: validatePass2, trigger: 'submit' }],
      },
      iddTimeShow: false,
      shortMenuShow: false,
      // projName: '联易融数科', // 项目名称
      projName: 'Dddddd', //项目名称
      projectNameLogo: null,
      bigHomeLangString: this.$i18n.locale == 'zh' ? '简体中文' : 'English',
      langString: '',
      iddSupFlag: false,
      customer: '',
      shopName: '',
      shopNameList: [],
      menuProductType: '',
      // PURCHASE_PAYMENT_finish:false,
      gridData: [],
      showingProduct: {},
    }
  },
  computed: {
    ...mapState({
      listByEnterpriseId: (state) => state.user.listByEnterpriseId,
    }),
    productType() {
      return filterDictParam(
        this.$store.state.user.listByEnterpriseId?.[0]?.productType
      )
    },
    allMenuIds() {
      return this.showingProduct.children?.map((menu) => menu.id)
    },
  },
  methods: {
    handleUserSetting() {
      this.navs = this.navsUser
      this.switchProduct(this.navs[0])
      this.$router.push({
        path: '/child-accountSettingPortal/userSettings',
      })
    },
    handlePopShow() {
      this.getCurrentUserDownLoadTasks()
    },
    handleDownload({ url }) {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    },
    getCurrentUserDownLoadTasks() {
      this.downloadTableLoading = true
      this.$http
        .post('/kj-tools/kjLedgerDownloadInfo/getCurrentUserDownLoadTasks')
        .then(({ data: { data } }) => {
          this.gridData = data.map((item) => ({
            ...item,
            ...(item.status
              ? {
                  statusDisplay: JSON.parse(item.status).displayName,
                  statusKey: JSON.parse(item.status).dictParam,
                }
              : {}),
          }))
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.downloadTableLoading = false
        })
    },
    // 跳转到购物车
    goBuyCar() {
      this.$router.push({
        path: '/child-marketplacePortal/buyCar',
      })
    },
    //跳转到账户系统
    gotoAcc() {
      let fromUrl = window.location.origin
      let enterpriseId = sessionStorage.getItem('enterpriseId')
      let accUrl
      if (fromUrl.includes('localhost')) {
        accUrl = 'http://localhost:9090'
      } else if (fromUrl.includes('lianyirong.com.cn')) {
        accUrl = 'https://projectc.lianyirong.com.cn'
      } else if (fromUrl.includes('qhhrly.cn')) {
        accUrl = 'https://projectc.qhhrly.cn'
      } else if (fromUrl.includes('hrlyit.com')) {
        accUrl = 'https://projectc.hrlyit.com'
      } else {
        accUrl = 'https://projectc.wellschain.com'
      }
      if (fromUrl && enterpriseId && accUrl) {
        let url = `${accUrl}/#/jumpingLoading?fromUrl=${fromUrl}&enterpriseId=${enterpriseId}&lang=${this.$i18n.locale}`
        window.open(url)
      } else {
        this.$alert('配置错误，请联系管理员', this.$t('login.tips'), {
          confirmButtonText: this.$t('login.confirm'),
        })
      }
    },
    //修改
    jumpPath() {
      const res = this.$router.history.current.path
      const isJump = res?.split('/')
      if (isJump && isJump[1] !== 'child-efinancePortal') {
        return
      }
      this.$router.push({
        path: '/child-efinancePortal/page?design.projectCode=0a668c42f0724a0897a6b5415d193ae3&design.appCode=e9aa8c41588745eb8454f00c9e54e39f&design.appVersion=V1.0.0&design.menuCode=51890f36b6424f7ab2e8e6a73a748614',
      })
    },
    // 切换大首页语言
    changeLangBigHome(lang) {
      localStorage.setItem('locale', lang)
      // 切换语言的时候保存当前菜单
      sessionStorage.setItem(
        'currentProductNavs',
        JSON.stringify(this.showingProduct)
      )
      this.$i18n.locale = lang
      console.log('this.$route.query.lang', this.$route)

      if (lang == 'zh') {
        this.bigHomeLangString = '简体中文'
      } else {
        this.bigHomeLangString = 'English'
      }
      //通知子应用更新
      this.$setGlobalState({ langlocale: lang })
      window.location.reload()
      // this.$router.go(0)
      // location.reload()
    },
    //切换子应用语言
    /*     changeLang(lang) {
          if (lang == 'zh') {
            this.langString = '简体中文'
          } else {
            this.langString = 'English'
          }
          //通知子应用更新
          switch (this.currentProduct) {
            case 'idd':
              this.$setGlobalState({ iddLocal: lang })
              localStorage.setItem('iddLocal', lang)
              break
            case 'efinance':
              this.$setGlobalState({ efinanceLocal: lang })
              localStorage.setItem('efinanceLocal', lang)
              break
            default:
              break
          }
          this.$setGlobalState({ childLocale: lang })
        }, */
    //点击菜单外的
    clickoutsideFunShort() {
      this.shortMenuShow = false
    },
    clickoutsideFun() {
      this.navs.map((li) => {
        li.selected = false
        return li
      })
    },
    clearMsgErr(formName) {
      this.resetErrMsg = ''
      if (typeof formName == 'string') {
        this.$refs[formName].clearValidate()
      }
    },
    //弹窗确定
    // compSure(data) {
    //   this.compName = data.entEnName || ''
    //   this.finishHome = true
    // },
    //弹窗关闭
    compClose() {
      this.showCompChoose = false
      this.$router.replace('/login')
    },
    //获取用户的基础信息
    async getUserInfo() {
      return new Promise((reslove, reject) => {
        this.$http.post(`/kj-web/sys/getCurSysUser`).then((data) => {
            let userObj = data.data.data || {}
            sessionStorage.setItem(
              'objCurUserInfo',
              JSON.stringify(data.data.data)
            )
            this.userName = userObj.name || '-'
            reslove()
          }
        )
      })
    },
    //通过path查找当前菜单的对象
    getHightLightByPath(path) {
      let father = this.navs.filter((item) => {
        return item.menuUrl == path
      })
      if (father && father.length > 0) {
        //一级菜单
        this.menuclick(father[0])
      } else {
        //二级菜单
        let children = []
        let childrenFather = []
        this.navs.map((item) => {
          if (item.children && item.children.length > 0) {
            let child = item.children.filter((li) => {
              return path.includes(item.menuUrl)
            })
            if (child && child.length > 0) {
              children = children.concat(child)
              childrenFather = childrenFather.concat(item)
            }
          }
          return item
        })
        if (children.length > 0) {
          // this.subMenuClick(children[0], childrenFather[0])
          childrenFather[0].children.map((li) => {
            li.selected = false
            return li
          })
          children[0].selected = true
          childrenFather[0].selected = false
          this.menuIndex = childrenFather[0].id
          // this.menuIndex  = "1"
          this.shortMenuShow = false
        }
      }
    },
    //高亮菜单
    getHightLightByPathNOFlash(path) {
      let father = this.navs.filter((item) => {
        return item.menuUrl == path
      })
      if (father && father.length > 0) {
        //一级菜单
        // this.menuclick(father[0]);
        this.menuIndex = father[0].id
      } else {
        //二级菜单
        let children = []
        let childrenFather = []
        this.navs.map((item) => {
          if (item.children && item.children.length > 0) {
            let child = item.children.filter((li) => {
              return li.menuUrl == path
            })
            if (child && child.length > 0) {
              children = children.concat(child)
              childrenFather = childrenFather.concat(item)
            }
          }
          return item
        })
        if (children.length > 0) {
          // this.subMenuClick(children[0], childrenFather[0]);
          this.menuIndex = childrenFather[0].id
        }
      }
    },
    //跳转页面
    goPage() {
      //初始化3级menuIndex
      let curM3subActive = sessionStorage.getItem('curM3subActive')
      if (curM3subActive) {
        this.m3subActive = curM3subActive // 刷新菜单时重置字段
      } else {
        this.m3subActive = '' // 刷新菜单时重置字段
      }
      //初始化2级menuIndex
      let menuIndex = sessionStorage.getItem('curMenuIndex')
      if (menuIndex) {
        //刷新跳转路由
        this.menuIndex = menuIndex
        // let curMenuPath =  sessionStorage.getItem('curMenuPath')
        // this.$router.push()
      } else {
        //首次进来页面跳转
        this.firstTimeRoute()
      }
      //初始化产品类型
      let curMenuProductType = sessionStorage.getItem('curMenuProductType')
      if (curMenuProductType) {
        this.judgeProdType(curMenuProductType)
      }
    },

    //首次进来页面跳转
    firstTimeRoute() {
      console.log(
        '🚀 ~ file: home.vue:1415 ~ firstTimeRoute ~ this.navs:',
        this.navs
      )
      console.log(
        '🚀 ~ file: home.vue:1415 ~ firstTimeRoute ~ this.showingProduct:',
        this.showingProduct
      )
      this.judgeProdType(this.showingProduct.menuProductType)
      if (this.navs && this.navs.length > 0) {
        //判断是否有2级子菜单
        if (
          this.showingProduct.children &&
          this.showingProduct.children.length > 0
        ) {
          //判断是否有3级子菜单
          if (
            this.showingProduct.children[0].children &&
            this.showingProduct.children[0].children.length > 0
          ) {
            this.menuIndex = this.showingProduct.children[0].id
            this.m3subActive = this.showingProduct.children[0].children[0].id
            this.$router.push({
              path: this.showingProduct.children[0].children[0].menuUrl,
            })
          } else {
            //无3级菜单有2级菜单
            this.menuIndex = this.showingProduct.children[0].id
            this.$router.push({
              path: this.showingProduct.children[0].menuUrl,
            })
          }
        } else {
          //无2级菜单有1级菜单
          this.menuIndex = this.showingProduct.id
          this.$router.push({ path: this.showingProduct.menuUrl })
        }
      } else {
        //无菜单
      }
    },
    //获取菜单
    async getMenu() {
      this.menuHide = false
      var _this = this
      await _this.$http
        .post('/kj-web/sys/getUserMenuTreeList', { sysChannel: 'kj_my' })
        .then(
          (data) => {
            var code = data.data.code
            if (code == '200') {
              // _this.$router.push('/login');
              let tl = data.data.data || []
              tl = tl.filter((i) => i.menuProductType !== 'BIG_HOME')
              // let tl = navTest
              //深拷贝原始数
              //获取页面菜单
              let navs = []
              //先处理一级菜单
              // {
              //     "name": "单据管理",
              //     "icon": 'idd-bill-icon',
              //     "id": 1,
              //     "menuUrl":"/child-iDDPortal/documentManagement",
              //     selected:false,
              //     "children" : [],
              //
              // },
              tl.map((item) => {
                //低代码页面url处理
                //page?design.projectCode=0a668c42f0724a0897a6b5415d193ae3&design.appCode=faae84d2d42e469ea5f52f82c561b1aa&design.appVersion=V1.0.0&design.menuCode=c6bb532763fd48f89718c1ae4c274066
                if (item.appCode) {
                  item.url =
                    item.url +
                    `?design.projectCode=${item.projectCode}&design.appCode=${item.appCode}&design.appVersion=${item.appVersion}&design.menuCode=${item.menuCode}`
                }
                // if (item.url == '/bigHome') {
                //   this.menuIndex = item.id
                // }
                let navObj = {
                  //一级菜单处理
                  mainTitle: item.menuName || '',
                  name: item.menuName || '',
                  nameEn: item.menuEnName || '',
                  icon: item.icon || 'lls-system',
                  id: item.id.toString(),
                  menuUrl: item.url || '',
                  selected: false,
                  children: [],
                  menuProductType: item.menuProductType,
                }
                if (sessionStorage.getItem('enterpriseProductType') === 'ABT') {
                  navObj.name = navObj.nameEn
                }
                if (!item.children) {
                  //不存在二级菜单
                } else {
                  //存在二级菜单
                  item.children.map((li) => {
                    if (li.appCode) {
                      li.url =
                        li.url +
                        `?design.projectCode=${li.projectCode}&design.appCode=${li.appCode}&design.appVersion=${li.appVersion}&design.menuCode=${li.menuCode}`
                    }
                    let navObjsub = {
                      name: li.menuName || '',
                      nameEn: li.menuEnName || '',
                      icon: li.icon || '',
                      menuUrl: li.url || '/',
                      id: li.id,
                      parentId: li.parentId,
                      selected: false,
                      children: [],
                      menuProductType: li.menuProductType,
                    }
                    if (!li.children) {
                      //不存在三级级菜单
                    } else {
                      //存在三级级菜单
                      li.children.map((three) => {
                        if (three.appCode) {
                          three.url =
                            three.url +
                            `?design.projectCode=${three.projectCode}&design.appCode=${three.appCode}&design.appVersion=${three.appVersion}&design.menuCode=${three.menuCode}`
                        }
                        let navObjsubthree = {
                          name: three.menuName || '',
                          nameEn: three.menuEnName || '',
                          menuUrl: three.url || '/',
                          id: three.id,
                          parentId: li.parentId,
                          selected: false,
                          children: [],
                          menuProductType: three.menuProductType,
                        }
                        if (!three.children) {
                          // 不存在四级菜单
                        } else {
                          // 存在四级菜单
                          three.children.map((four) => {
                            if (four.appCode) {
                              four.url =
                                four.url +
                                `?design.projectCode=${four.projectCode}&design.appCode=${four.appCode}&design.appVersion=${four.appVersion}&design.menuCode=${four.menuCode}`
                            }
                            let navObjsubfour = {
                              name: four.menuName || '',
                              nameEn: four.menuEnName || '',
                              menuUrl: four.url || '/',
                              id: four.id,
                              parentId: li.parentId,
                              selected: false,
                              menuProductType: four.menuProductType,
                            }
                            navObjsubthree.children.push(navObjsubfour)
                            return four
                          })
                        }
                        navObjsub.children.push(navObjsubthree)
                        return three
                      })
                    }
                    navObj.children.push(navObjsub)
                    return item
                  })
                }
                navs.push(navObj)
                return item
              })
              this.navs = navs
              this.navsUser = []
              this.navsSwitch = []
              navs.forEach((item) => {
                if (item.menuProductType === 'DEFAULT') {
                  this.navsUser.push(item)
                } else {
                  this.navsSwitch.push(item)
                }
              })
              // 如果有缓存值，取缓存，否则取第一个菜单
              if (sessionStorage.getItem('currentProductNavs')) {
                this.showingProduct = JSON.parse(
                  sessionStorage.getItem('currentProductNavs')
                )
              } else {
                this.showingProduct = this.navsSwitch[0] || this.navsUser[0]
              }
              let isReFlag = this.$route.query.isReFlag || 0
              if (isReFlag == 1) {
              } else {
                this.goPage()
              }
            } else {
              // $.fn.mAlert('body',{tips:data.data.msg});
              _this.$alert(data.data.msg, 'Tips', {
                confirmButtonText: 'Confirm',
              })
            }
          },
          function (response) {
            // $.fn.mAlert('body',{tips:response});
            _this.$alert(response, 'Tips', {
              confirmButtonText: 'Confirm',
            })
          }
        )
      await _this.$http
        .post('/kj-sys/user/whetherShowCommonMenu')
        .then((res) => {
          this.navsBtnShow = res.data.data
        })
      this.menuHide = true
    },
    //菜单语言切换

    //获取企业信息
    async getCompany() {
      // const result = await this.$http.post('/kj-web/sys/getEnterpriseVO')
      const result = await this.$http
        .post('/kj-sys/kjEnterprise/getEnterpriseByType', {
          productType: [
            'IDD',
            'DOC_EXCHANGE',
            'PURCHASE_PAYMENT',
            'E_FINANCE',
            'MARKET',
            'TRADE_FINANCE',
            'DIGIPO',
            'ASSET_BACKED_TOKENISATION',
            'DIGITAL_TRADE_TOKEN',
          ],
        })
        .then(
          ({ data }) => {
            if (data.code == '200') {
              const tl = data.data || []

              let enterpriseId =
                sessionStorage.getItem('enterpriseId') || tl[0].id
              sessionStorage.setItem('enterpriseId', enterpriseId) // dyy 企业id
              let companyName =
                sessionStorage.getItem('companyName') || tl[0].companyName
              sessionStorage.setItem('companyName', companyName) // dyy 企业名字
              sessionStorage.setItem('companyIdentCode', tl[0].companyIdentCode) // dyy 企业code

              this.shopNameList = this.userCompanyList = tl
              const enterpriseRole = tl[0]?.enterpriseRole
                ? JSON.parse(tl[0]?.enterpriseRole)?.dictParam
                : null
              sessionStorage.setItem('enterpriseInfo', JSON.stringify(tl[0]))
              sessionStorage.setItem('enterpriseRole', enterpriseRole)
              // 内管端有且只有一个企业
              // tl.map(item=>{
              //     this.userName = item.entCnName || ''
              // })
              // if (tl.length > 1) {
              //   this.userCompanyList = tl
              //   return false
              // } else {
              //   if (tl.length == 1) {
              //     this.compName = tl[0].entEnName || ''
              //   }
              //   return true
              // }
            }
          }
        )
      return result
    },
    subMenuClick(item, table) {
      this.m3subActive = item.id
      sessionStorage.setItem('curM3subActive', this.m3subActive)
      if (!item.children) {
        //说明是三级菜单，让二级菜单高亮
        this.menuIndex = table.id
        sessionStorage.setItem('curMenuIndex', this.menuIndex)
      } else {
        //说明没有三级菜单，自身高亮
        this.menuIndex = item.id
        sessionStorage.setItem('curMenuIndex', this.menuIndex)
      }
      if (item.menuUrl) {
        this.$router.push({ path: item.menuUrl })
        sessionStorage.setItem('curMenuPath', item.menuUrl)
        this.menuProductType = item.menuProductType
        sessionStorage.setItem('curMenuProductType', table.menuProductType)
        this.judgeProdType(this.menuProductType)
      }
      this.shortMenuShow = false
      // 关闭popover三级菜单弹窗
      this.$nextTick(() => {
        this.$refs.popoverRef && this.$refs.popoverRef[0].doClose()
      })
    },
    menuclick(table) {
      // if (table.menuProductType == 'ACCOUNT') {
      //   //账户系统不需要跳转路由
      //   this.gotoAcc()
      //   return
      // }
      if (table.menuUrl && table.children.length == 0) {
        this.$router.push({ path: table.menuUrl })
        sessionStorage.setItem('curMenuPath', table.menuUrl)
        this.menuProductType = table.menuProductType
        sessionStorage.setItem('curMenuProductType', table.menuProductType)
        this.judgeProdType(this.menuProductType)
      }
      this.menuIndex = table.id
      sessionStorage.setItem('curMenuIndex', this.menuIndex)
      //初始化3级index
      this.m3subActive = ''
      sessionStorage.setItem('curM3subActive', '')
    },
    selectMenu(key, keyPath) {},
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
    setPageH() {
      var hei = this.getSrcollHeight()
      let hString = `calc(100vh - ${80}px)`
      $('#main').css('height', hString)
    },
    //获取当前企业的产品信息
    getProductInfo() {
      return new Promise((resolve, reject) => {
        let enterpriseId = sessionStorage.getItem('enterpriseId')
        this.$API.enterprise.getProductByEnterpriseId(enterpriseId).then(
          (data) => {
            this.shopName =
              this.$i18n.locale == 'zh'
                ? data.data.data?.companyNameCn || data.data.data?.companyName
                : data.data.data?.companyName || data.data.data?.companyNameCn
            const result = data.data.data
            sessionStorage.setItem('enterpriseRole', result.enterpriseRole)
            sessionStorage.setItem(
              'enterpriseLocation',
              result.enterpriseLocation
            )
            resolve()
          },
          (err) => {
            reject()
          }
        )
      })
    },
    //页面初始化
    initPage: async function () {
      var hei = this.getSrcollHeight()
      let hString = `calc(100vh - ${80}px)`
      $('#main').css('height', hString)
      var logoArr = []
      for (var i = 0; i < 18; i++) {
        logoArr.push(i)
      }
      this.bglogoArr = logoArr
      //获取用户信息
      await this.getUserInfo()
      //获取企业信息
      await this.getCompany()
      //获取企业产品信息
      await this.getProductInfo()
      // 获取菜单
      await this.getMenu()
      //显示路由组件
      this.finishHome = true
    },
    //字典存储在session
    setSessionStorage() {
      let _this = this
      var objUser = JSON.parse(sessionStorage.getItem('objUser'))
      // this.userName = objUser.name;
      this.$http.post('/kj-web/dict/getAllDictList').then(
        function (data) {
          let DictList = data.data.data || []
          for (let i = 0; i < DictList.length; i++) {
            sessionStorage.setItem(
              DictList[i].dispalyName + '' + DictList[i].dictCode,
              JSON.stringify(DictList[i].subDictList)
            )
          }
          // _this.$setGlobalState({eventName:'allDictChange'});
        },
        function (response) {
          console.log('请求失败')
        }
      )
    },
    // 切换中英文调用枚举接口
    againSetSessionStorage() {
      let _this = this
      var objUser = JSON.parse(sessionStorage.getItem('objUser'))
      // this.userName = objUser.name;
      this.$http.post('/kj-web/dict/getAllDictList').then(
        function (data) {
          let DictList = data.data.data || []
          for (let i = 0; i < DictList.length; i++) {
            sessionStorage.setItem(
              DictList[i].dispalyName + '' + DictList[i].dictCode,
              JSON.stringify(DictList[i].subDictList)
            )
          }
          _this.$setGlobalState({ eventName: 'allDictChange' })
          // _this.$setGlobalState({eventName:''});
        },
        function (response) {
          console.log('请求失败')
        }
      )
    },
    againSetSessionStorageChild() {
      let _this = this
      var objUser = JSON.parse(sessionStorage.getItem('objUser'))
      // this.userName = objUser.name;
      this.$http
        .request({
          url: '/kj-web/dict/getAllDictList',
          method: 'post',
          childHead: true,
        })
        .then(
          function (data) {
            let DictList = data.data.data || []
            for (let i = 0; i < DictList.length; i++) {
              sessionStorage.setItem(
                DictList[i].dispalyName + '' + DictList[i].dictCode,
                JSON.stringify(DictList[i].subDictList)
              )
            }
            _this.$setGlobalState({ eventName: 'allDictChange' })
            // _this.$setGlobalState({eventName:''});
          },
          function (response) {
            console.log('请求失败')
          }
        )
    },
    //退出登录SSO
    async logoutSSO() {
      let res = await this.$sso.logout()
      if (res.code === '200') {
        // localStorage.clear()
        sessionStorage.clear()
        this.$router.replace('/login')
      }
    },
    //退出登录
    async logout() {
      var _this = this
      // _this.$router.push('/login');
      // _this.$http.post(
      //     "/kj-web/account/logout",
      // ).then(function(data){
      //     var code = data.data.code;
      //     if(code == "200") {
      //         sessionStorage.clear()
      //         _this.$router.replace('/login');
      //     } else {
      //         // $.fn.mAlert('body',{tips:data.data.msg});
      //         _this.$alert(data.data.msg, '提示', {
      //             confirmButtonText: '确定',
      //         });
      //     }
      // },function(response){
      //     // $.fn.mAlert('body',{tips:response});
      //     _this.$alert(response, '提示', {
      //         confirmButtonText: '确定',
      //     });
      // })
      try {
        var res = await this.$sso.logout()
        if (res) {
          // 登出成功
          this.$message({
            type: 'success',
            message: this.$t('login.logoutSuccessful'),
          })
          if (this.customer === 'volofin') {
            this.$router.replace({
              path: '/login',
              query: { refer: 'VOLOFIN' },
            })
          } else {
            this.$router.replace('/login')
          }
        }
        localStorage.clear()
      } catch (e) {
        var obj = JSON.parse(e.message)
        this.$message.error(obj.message)
      }
    },
    //重置密码
    resetPwd: function () {
      this.isResetPwd = true
      this.resetErrMsg = ''
      this.formReset = {
        confirmPassword: '',
        oldPassword: '',
        password: '',
      }
    },
    //关闭重置密码
    toClose: function () {
      this.isResetPwd = false
      this.$refs['formReset'].resetFields()
    },
    //确定重置密码
    toReset: function (formName) {
      let _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          _this.sureResetPwd()
          // this.cancleRe('ReSetruleForm')
        } else {
          return false
        }
      })
    },
    //重置密码
    async sureResetPwd() {
      let _this = this
      let oldPassword = this.formReset.oldPassword
      let password = this.formReset.password
      let confirmPassword = this.formReset.confirmPassword
      let loginName = JSON.parse(sessionStorage.getItem('objCurUserInfo')).email
      try {
        let params = {
          loginName: loginName,
          password: password, //新密码
          orcPassword: oldPassword,
          sysChannel: 'kj_my_pc',
        }
        let res = await this.$sso.resetPasswd(params)
        if (res.code == '200') {
          _this.isResetPwd = false
          // 重置成功，请重新登录
          _this.$alert(
            _this.$t('login.resetSuccessfullyAgain'),
            _this.$t('login.tips'),
            {
              confirmButtonText: _this.$t('login.confirm'),
              callback: (action) => {
                _this.logout()
              },
            }
          )
        } else {
          // 暂无响应，请联系管理员
            _this.$t('login.serverNotRsponding')
        }
      } catch (e) {
        _this.resetErrMsg =
          JSON.parse(e.message).message || _this.$t('login.serverNotRsponding')
      }
      // _this.$http.post(
      //     `/kj-web/account/resetPwd?confirmPassword=${confirmPassword}&oldPassword=${oldPassword}&password=${password}`,{},{handingResponseErrMsg:true}
      // ).then(function(data){
      //     var code = data.data.code;
      //     if(code == "200") {
      //         _this.$alert('重置成功，请重新登录', '提示', {
      //             confirmButtonText: '确定',
      //             callback: action => {
      //                 _this.logout()
      //             }
      //         });
      //
      //     } else {
      //         _this.resetErrMsg = data.data.msg || data.data.message || '暂无响应，请联系管理员'
      //     }
      // },function(response){
      //     _this.resetErrMsg = response || '暂无响应，请联系管理员'
      // })
    },
    //获取时间倒计时时间戳
    getTime() {
      var _this = this
      // _this.$router.push('/login');
      _this.$http.post('/kj-idd/marketSetting/getRemainingCloseTime').then(
        function (data) {
          var code = data.data.code
          if (code == '200') {
            // _this.$router.replace('/login');
            if (data.data.data) {
              _this.countDown(data.data.data)
            }
            // _this.countDown(50000)
          } else {
            // $.fn.mAlert('body',{tips:data.data.msg});
            _this.$alert(data.data.msg, _this.$t('login.tips'), {
              confirmButtonText: _this.$t('login.confirm'),
            })
          }
        },
        function (response) {
          // $.fn.mAlert('body',{tips:response});
          _this.$alert(response, _this.$t('login.tips'), {
            confirmButtonText: _this.$t('login.confirm'),
          })
        }
      )
    },
    //带天数的倒计时
    countDown: function (times) {
      // var timer = null;
      let _this = this
      times = times / 1000
      clearInterval(_this.timer)
      _this.timer = null
      _this.timer = setInterval(function () {
        var day = 0,
          hour = 0,
          minute = 0,
          second = 0 //时间默认值
        if (times > 0) {
          day = Math.floor(times / (60 * 60 * 24))
          hour = Math.floor(times / (60 * 60)) - day * 24
          minute = Math.floor(times / 60) - day * 24 * 60 - hour * 60
          second =
            Math.floor(times) -
            day * 24 * 60 * 60 -
            hour * 60 * 60 -
            minute * 60
        }
        if (day <= 9) day = '0' + day
        if (hour <= 9) hour = '0' + hour
        if (minute <= 9) minute = '0' + minute
        if (second <= 9) second = '0' + second
        //
        _this.timeObj = {
          hour,
          minute,
          second,
        }
        times--
      }, 1000)
      if (times <= 0) {
        clearInterval(_this.timer)
        _this.timer = null
      }
    },
    changeShop(item) {
      try {
        sessionStorage.removeItem('currentProductNavs')
      } catch (error) {
        console.log(error)
      }
      this.$refs.shopDropdownRef && this.$refs.shopDropdownRef.hide()
      this.$nextTick(() => {
        this.$refs.companyDialogRef.getCompList(item)
      })
    },
    // 显示隐藏三级菜单
    showSubPopper(id, shortMenu = false) {
      if (id === null) {
        document.querySelectorAll('.el-popover').forEach((el) => {
          el.__vue__.$parent.doClose()
        })
        return
      }
      if (this.$refs[`popoverRef${id}`] == undefined) return
      const refs = shortMenu
        ? this.$refs[`popoverRef${id}`][1]
        : this.$refs[`popoverRef${id}`][0]
      setTimeout(() => {
        refs.doShow()
      }, 0)
    },
    filterShopName(value) {
      if (!value) {
        this.shopNameList = this.userCompanyList
        return
      }
      this.shopNameList = this.userCompanyList
        .map((v) => {
          if (v.locationName.includes(value)) {
            return v
          }
          return v
        })
        .filter((v) => v)
    },
    //判断菜单的产品类型
    judgeProdType(menuProductType) {
      console.log(
        '🚀 ~ file: home.vue:2130 ~ judgeProdType ~ menuProductType:',
        menuProductType
      )
      // let menuProductType =  menu.menuProductType
      //初始化
      this.iddSupFlag = false
      this.iddTimeShow = false
      this.addCartShow = false // 如果不是market，不展示购物车入口
      switch (menuProductType) {
        case 'BIG_HOME':
          //大首页不需要处理保持现状
          break
        case 'MARKET': {
          //重置语言
          this.$setGlobalState({ childLocale: 'en' })
          //当存在二级资金方才展示购物车入口
          const marketFund = this.$store.state.user.listByEnterpriseId.filter(
            (item) =>
              item.enterpriseRole &&
              JSON.parse(item.enterpriseRole)?.dictParam == 'FUND' &&
              item.productType &&
              JSON.parse(item.productType)?.dictParam == 'MARKET'
          )
          if (marketFund.length > 0) {
            this.addCartShow = true
          }
          break
        }
        case 'PURCHASE_PAYMENT':
          //重置语言
          this.$setGlobalState({ childLocale: 'en' })
          break
        case 'DOC_EXCHANGE':
          //重置语言
          this.$setGlobalState({ childLocale: 'en' })
          break
        case 'GO_EARLY':
          //重置语言
          this.$setGlobalState({ childLocale: 'en' })
          break
        case 'ACCOUNT':
          //跳转页面
          break
        case 'IDD':
          //显示倒计时
          this.iddTimeShow = true
          this.getTime()
          //重置语言
          let iddNavs = this.navs.filter((item) => {
            return item.menuProductType == 'IDD'
          })
          console.log('iddNavs', iddNavs)
          if (iddNavs[0]?.children && iddNavs[0]?.children.length >= 3) {
            //买方
            this.iddSupFlag = false
            this.$setGlobalState({ childLocale: 'en' })
            localStorage.setItem('iddLocal', 'en')
          } else {
            this.iddSupFlag = true
          }
          break
        default:
          this.$setGlobalState({ childLocale: 'en' }) //默认中文
          this.iddSupFlag = false
          this.iddTimeShow = false
          break
      }
    },
    switchProduct(product) {
      this.showingProduct = product
      this.firstTimeRoute()
    },
    //监听页面刷新或者关闭
    // updateHandler() {

    //   let flashPath = this.$route.path
    //   let flashPathName = this.$route.name
    //   sessionStorage.setItem('flashPath',flashPath)
    //   sessionStorage.setItem('flashPathName',flashPathName)
    // },
    async getNoAuthAllWhiteLabel () {
      const { data } = await this.$API.system.getNoAuthAllWhiteLabel()
      const projectNameLogo = new Uint8Array(Buffer.from(data.data.projectNameLogo, 'base64'))
      const blob = new Blob([projectNameLogo], { type: 'image/svg+xml' });
      this.projectNameLogo = URL.createObjectURL(blob);
      this.projName = data.data.projectName
    },
  },
  watch: {
    //view层的全局监听
    $route: function (val, oldVal) {
      // console.log('当前URL当前URL当前URL当前URL========',val,oldVal);
      // this.getHightLightByPathNOFlash(val.path)
    },
    //企业菜单的产品变化
    // menuProductType:(val,oldVal)=>{
    //   if(val =='GO_EARLY' ){
    //     //电商融资
    //   }else if(val =='IDD' ){

    //   }
    // }
    menuIndex(nv) {
        sessionStorage.setItem('curMenuIndex', nv)
    },
  },
  created() {
    // this.getNoAuthAllWhiteLabel()
  },
  mounted() {
    this.initPage()
    this.setSessionStorage()
    // this.getEnterprise()
    window.onresize = () => {
      return (() => {
        // if(flag){
        //     flag =  false
        //     _this.setPageH()
        //     setTimeout(()=>{
        //         flag = true
        //     },1000)
        //     window.location.reload()
        // }
      })()
    }
    console.log('父应用Home')
    window.vm.$on('childRouteChange', (val) => {
      // console.log('子路由变化了',val);
      // let path = val.split('?')[0]
      // this.routeQuery = val.split('?')[1]
      // // this.getHightLightByPathNOFlash(path);
      // this.getHightLightByPath(path);
    })
    window.vm.$on('langChange', (val) => {
      // debugger
      if (this.$route.path.includes('login')) {
      } else {
        this.againSetSessionStorage()
        // this.getMenu();
      }
      // let path = val.split('?')[0]
      // this.routeQuery = val.split('?')[1]
      // // this.getHightLightByPathNOFlash(path);
      // this.getHightLightByPath(path);
    })
    window.vm.$on('childLangChange', (val) => {
      console.log('childLangChange')
      // debugger
      if (this.$route.path.includes('login')) {
      } else {
        this.againSetSessionStorageChild()
      }
    })
    window.vm.$on('changeEnterprise', (val) => {
      console.log('点击店铺跳转 ==============>', val)
      this.changeShop(val)
    })
    window.vm.$on('addCart', (val) => {
      console.log('购物车数量 ==============>', val)
      this.cartNum = val
    })
    // 给店铺下拉菜单添加className
    const shopDropdownById = document
      .querySelector('.shopDropdown')
      .childNodes[0].getAttribute('aria-controls')
    document
      .getElementById(`${shopDropdownById}`)
      .classList.add('shopDropdownList')
    //监听页面刷新或者关闭
    // window.addEventListener('beforeunload', this.updateHandler)
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>
