const api = {}
//表格轻操作只传ID-删除-更新类
api.tableLightHandle = async (obj,tips,sucfn,errfn)=>{
  let url = `${obj.url}?${obj.queParam}=${obj.id}`
  window.vm.$http.post(url).then(data=>{
    window.vm.$llsMessage({type: 'success',text:tips});
    sucfn()
  },err=>{
    errfn()
  })
}
//导出操作
api.tableExport = async (obj,sucfn,errfn)=>{
  let url = `${obj.url}`
  window.vm.$http.post(url,obj.queParam,{responseType :'blob'}).then(data=>{
    // window.vm.$llsMessage({type: 'success',text:tips});
    sucfn()
  },err=>{
    errfn()
  })
}
export default api