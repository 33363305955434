<!-- <template>
  <div class="global">
    <el-row :gutter="16">
      <el-col :span="8">
        <div class="card card_1">
          <span class="word1">数字金融</span>
          <span class="word2">创新的供应链融资方式</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="card card_2">
          <span class="word1">数字贸易</span>
          <span class="word2">人工智能+区块链，赋能国际贸易</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="card_3">
          <label>待办</label>
          <el-empty
            v-if="true"
            description=" "
            :image="require('@/static/img/emptyImg.png')"
            >
          </el-empty>
          <div v-else></div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="card_4">
          <span class="word1">联易融国际</span>
          <span class="word2">致力于为全球银行和企业提供一流的数字供应链融资服务</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template> -->
<template>
  <div class="global">
    <el-row :gutter="16">
      <el-col :span="8">
        <div class="card card_1">
          <span class="word1">{{ $t('bigHome.digitalFinance') }}</span>
          <span class="word2">{{ $t('bigHome.digitalFinanceContent') }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="card card_2">
          <span class="word1">{{ $t('bigHome.digitalTrade') }}</span>
          <span class="word2">{{ $t('bigHome.digitalTradeContent') }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="card_3">
          <label>{{ $t('bigHome.pendingTasks') }}</label>
          <el-empty
            v-if="true"
            description=" "
            :image="
              $i18n.locale == 'en'
                ? require('@/static/img/emptyImg_Eng.png')
                : require('@/static/img/emptyImg.png')
            "
          >
          </el-empty>
          <!-- <el-empty
            v-if="true"
            description=" "
            :image="require('@/static/img/emptyImg.png')"
            >
          </el-empty> -->
          <div v-else></div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="card_4">
          <span class="word1">{{ $t('bigHome.linklogisInternational') }}</span>
          <span class="word2">{{
            $t('bigHome.linklogisInternationalContent')
          }}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'global',
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.global {
  // font-family: PingFang SC, PingFang SC-Semibold;
  color: #fff;
  .el-empty {
    padding: 23px 0px 0px 0px;
  }
  .el-col {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col,
  .card {
    border-radius: 4px;
  }
  .card {
    padding: 40px;
    width: 100%;
    min-height: 328px;
    overflow: hidden;
    word-break: break-word;
    white-space: break-spaces;
  }
  .card_1 {
    display: flex;
    flex-direction: column;
    background: url('~@/static/img/homeCard1.png') no-repeat center/100% 100%;
  }
  .card_2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background: url('~@/static/img/homeCard2.png') no-repeat center/100% 100%;
    .word2 {
      margin-top: 20px;
    }
  }
  .card_3 {
    width: 100%;
    min-height: 320px;
    background-color: #fff;
    padding: 20px 24px;
    box-shadow: 2px 2px 10px 0px rgba(46, 90, 153, 0.1);
    label {
      font-size: 20px;
      font-weight: 600;
      color: #2e5a99;
    }
  }
  .card_4 {
    width: 100%;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;
    background: url('~@/static/img/homeCard3.png') no-repeat center/100% 100%;
  }
  .word1 {
    font-size: 32px;
    font-weight: 500;
  }
  .word2 {
    margin-top: 40px;
    line-height: 20px;
  }
}
</style>
