<template>
  <div class="switch-product">
    <el-dropdown trigger="click" placement="bottom-start" @command="handleCommand">
      <div class="el-dropdown-link">
        <svg-icon :iconClass="currentIcon" fill="#fff"></svg-icon>
        <span class="current-product">{{ currentProductName }}</span>
        <svg-icon iconClass="switch" fill="#fff"></svg-icon>
      </div>
      <el-dropdown-menu slot="dropdown" class="dropdown-menu">
        <el-dropdown-item class="more-product">
          <span>{{ $t('menu.moreProduct') }}</span>
        </el-dropdown-item>
        <div class="product-items">
          <template v-for="(item, index) in navs">
            <el-dropdown-item :key="index" :command="index">
              <svg-icon :iconClass="item.icon" fill="#2E5A99"></svg-icon>
              <span class="menu-title1 menu-item-wrap">{{
                $i18n.locale == 'en' ? item.nameEn : item.name
              }}</span>
            </el-dropdown-item>
          </template>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    navs: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      currentProductName: '',
      currentIcon: 'idd-index-icon',
      currentProduct: {},
    }
  },
  watch: {
    navs(newVal, oldVal) {
      this.currentProduct = newVal[0]
      if (this.currentProduct.menuProductType == 'ACCOUNT') {
        //账户系统不需要跳转路由
        this.$emit('gotoAcc')
        return
      }
      let sessionNavs = sessionStorage.getItem('currentProductNavs')
      let { name, nameEn, icon } = sessionNavs
        ? JSON.parse(sessionNavs)
        : newVal[0]
      this.currentProductName = this.$i18n.locale === 'en' ? nameEn : name
      this.currentIcon = icon
    },
    '$i18n.locale'(newVal) {
      const { name, nameEn } = this.currentProduct
      this.currentProductName = newVal === 'en' ? nameEn : name
    },
  },
  methods: {
    handleCommand(index) {
      this.currentProduct = this.navs[index]
      if (this.currentProduct.menuProductType == 'ACCOUNT') {
        //账户系统不需要跳转路由
        this.$emit('gotoAcc')
        return
      }
      this.currentProductName =
        this.$i18n.locale === 'en'
          ? this.navs[index].nameEn
          : this.navs[index].name
      this.currentIcon = this.navs[index].icon
      this.$emit('switchProduct', this.navs[index])
    },
  },
}
</script>

<style lang="scss" scoped>
.switch-product {
  margin-left: 60px;
  cursor: pointer;

  .el-dropdown-link {
    display: flex;
    align-items: center;

    .current-product {
      margin: 0 10px;
    }
  }

  .el-dropdown {
    color: #fff;
  }
}

::v-deep.dropdown-menu {
  .more-product {
    height: 28px;
    margin-top: 8px;
    line-height: 28px;
    color: #2e5a99;
    font-weight: 600;
  }

  .product-items {
    position: relative;
    display: flex;
    flex-direction: row;
    writing-mode: vertical-lr;
    flex-wrap: wrap;
    max-height: 264px;

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      // width: 148px;
      height: 40px;
      writing-mode: horizontal-tb;

      .menu-title1 {
        margin-left: 8px;
        white-space: nowrap;
      }
    }
  }
}

// 两列时 盒子中间竖线
// .dropdown-menu:not(:only-child)::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   width: 2px;
//   background-color: #ebf3ff;
//   left: 50%;
//   transform: translateX(-50%);
// }
</style>
