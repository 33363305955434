<style scoped>
  .agreement-title {
    margin-bottom: 20px;
      padding: 0px 25px;
  }
  .agreement-content {
      box-sizing: border-box;
    height: calc(100% - 100px);
    background-color: #f9fcff;
    /*padding: 44px;*/
      margin-left: 25px;
      margin-right: 25px;
    margin-bottom: 20px;
      /*overflow-y: auto;*/
  }
  .agreement-action{
      padding: 0 25px;
  }
  .agreement-action p {
    padding-left: 24px;
  }
  .agreement-btn {
    display: block;
    margin: auto;
    margin-top: 4px;
    /* cursor: pointer;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    width: 104px;
    height: 32px;
    flex-grow: 0;
    margin: 20px auto 0;
    padding: 7px 16px 9px;
    background-image: linear-gradient(to bottom, rgba(90, 156, 248, 0.5), rgba(90, 156, 248, 0.5)), linear-gradient(to top, #5a9cf8, #90befd); */
  }
    .agreement-dialog {
        width: 100%;
        height: 100%;
    }
    .title{
      margin: -10px 0 8px 22px;
      /* font-family: PingFang SC; */
      font-size: 20px;
      font-weight: 600;
      color: #2E5A99;
    }
    .agreement-tips{
        position: absolute;
        bottom: 170px;
        left: 50%;
        margin-left:-180px ;
        display: inline-block;
        font-size: 12px;
        color: #f76c5e;
        padding: 2px 10px;
        background-color: #fee9e7;
        border-radius: 4px;
    }
</style>
<template>
  <div class="agreement-dialog">
    <el-dialog :visible="show" @close="close" width="80%">
      <div class="title">Click-Through NDA and Disclaimer</div>
      <!-- <div class="agreement-title">
        {{$t('tips.agreementFirstPlatformServices')}}
      </div> -->
      <div class="agreement-content">
          <iframe :src="pdfUrl" frameborder="0" width='100%' height='100%'></iframe>
          <!-- <div class="agreement-tips">{{$t('tips.needAgreementSigning')}}</div> -->
      </div>
      <div class="agreement-action">
        <el-checkbox v-model="checked">I have read and accepted Click-Through NDA and Disclaimer</el-checkbox>
        <!-- <el-checkbox v-model="checked">{{$t('tips.clickingButtonConfirm')}}</el-checkbox> -->
        <!-- <p>{{$t('tips.agreedFollowingTerms')}}</p> -->
        <!-- <p>{{$t('tips.constitutesElectronicSignature')}}</p> -->
        <!-- <div class="agreement-btn" @click="saveStatus">{{$t('tips.confirmToSign')}}</div> -->
        <el-button class="agreement-btn" type="primary" @click="saveStatus" :disabled="!checked">Agree</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['show','pdfUrl','layerLoading'],
  data () {
    return {
      checked: false,
      showDialog: false,
    }
  },
  computed:{
      // showDialog(){
      //     return this.show
      // }
  },
  watch: {
    // show (val) {
    //   this.showDialog = val
    // }
    'show': {
      handler (val) {
        this.showDialog = val
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    
  },
  methods: {
    close () {
      this.$emit('close')
    },
    saveStatus () {
        if(this.layerLoading)return
        if(this.checked){
            this.$emit('sure')
        }else {
          // '请点击按钮，同意并确认'
            this.$message({
                type: 'error',
                showClose: true,
                message: this.$t('tips.clickAgreeConfirm')
            });
        }

    },

  },
}
</script>
