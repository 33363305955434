import Vue from 'vue'
const vm = new Vue()
let str=location.href;
let devURL = "lianyirong";//开发域名关键字
let uatURL = "qhhrly";//测试域名关键字
let prdURL = "llschain";//生产域名关键字
let demoURL = "hrlyit";//演示环境
let sysUrl = ''
//判断用户权限中心地址需要用到什么域名
if(str.indexOf(devURL) > -1){
    sysUrl = 'https://sys-web.lianyirong.com.cn' //开发环境

}else if(str.indexOf(uatURL) > -1){
    sysUrl = 'https://sys-web.qhhrly.cn'; //测试环境

}else if((str.indexOf(prdURL) > -1)){
    sysUrl = 'https://sys-web.llschain.com'; //生产环境

}else if((str.indexOf(demoURL) > -1)){
    sysUrl = 'https://sys-web.hrlyit.com'; //演示环境

}else{
    sysUrl = 'https://sys-web.lianyirong.com.cn'; //开发环境
    // sysUrl = 'https://sys-web.qhhrly.cn'; //测试环境
}
let systemCode = 'fms' //用户权限中心对应的系统编码
const system = {}
/**
 * 查询用户菜单列表
 *  @param { 系统编码 } systemCode
 */
system.getUserMenuList = function(that,id) {
    let url = ''
    if(that.$store.state.isSYS){
        url = `${sysUrl}/agw-web/sys/menu/listMenuResourceByUserId?systemCode=${systemCode}`
    }else {
        url = `/agw-web/sys/perm/getUserMenuPermList?userId=${id}`
    }

    return vm.$http.post(url)
}
/**
 * 查询所有枚举接口
 */
system.getSysDict = function() {
    let url = `/kj-web/dict/getAllDictList`
    return vm.$http.post(url)
}
system.getNoAuthAllWhiteLabel = function() {
    const url = `/kj-portfolio/white-label/no-auth/list-all-white-label`
    return vm.$http.post(url)
}
system.getAuthAllWhiteLabel = function(data) {
    const url = `/kj-portfolio/white-label/auth/list-all-white-label`
    return vm.$http.post(url, data)
}
system.dttDoLogin = function(data) {
    const url = `/sso-web/otp/doLogin`
    return vm.$http.post(url, data)
}

export default system
