import $ from 'jquery'
//弹窗
(function($,window,document){//封装一个自执行的函数
    var mAlert = function(el,opt){//字面量的方式构造一个函数，这个写在函数里面，用闭包的方式，防止变量污染
        this.ele=el;
        this.defaults={
            title:'提示',
            tips:'信息有误',
            btn:0, //0为一个按钮 1为两个
            type:'warn',//warn-警告，err-错误，suc-成功
            val:100, //这个作废

            callback:function(){

            }
        };
        this.options=$.extend({},this.defaults,opt)//合并参数，将自定义的参数以及传入的参数合并，这里opt如果传入的参数与defaults有相同的参数名，后者将会覆盖前者。如果没有传入opt 则默认default的值
    };
    mAlert.prototype={//一般是在原型中写方法，因为在本身添加会导致每次对象实例代码被复制，在原型上添加相当于对OO中基类添加成员，子类实例化可减少内存占用，有利于代码的复用
        init:function(){
            this.open();
            return this;
        },
        open:function(){
            var layer='';
            layer += '<div id="myAlertLayer">';
                layer += '<div class="dia-mask"></div>';
                layer += '<div class="dia-main">';
                    layer += '<div class="dia-head">'+this.options.title+'<div class="dia-close"></div></div>';
                    layer += '<div class="dia-body">';
                        layer += '<div class="dia-icon dia-icon-warn"></div>';
                        layer += '<div class="dia-icon dia-icon-err"></div>';
                        layer += '<div class="dia-icon dia-icon-suc"></div>';
                        layer += '<div class="dia-msg">'+this.options.tips+'</div>';
                    layer += '</div>';
                    layer += '<div class="dia-foot">';
                        layer += '<div class="dia-btn dia-btn-sec">取消</div>';
                        layer += '<div class="dia-btn dia-btn-fir" @click="goTo()">确定</div>';
                    layer += '</div>';
                layer += '</div>';
            layer += '</div>';
            $(this.ele).append(layer);
            //0为一个按钮 1为两个
            if(this.options.btn==0){
                $('#myAlertLayer .dia-btn-sec').hide();
            }else{
                $('#myAlertLayer .dia-btn-sec').show();
            }
            //弹框类型
            if(this.options.type=='suc'){
                $('#myAlertLayer .dia-icon-suc').show();
            }else if(this.options.type=='err'){
                $('#myAlertLayer .dia-icon-err').show();
            }else{
                $('#myAlertLayer .dia-icon-warn').show();
            }
            this.addEvent();
            return this;
        },
        close:function(){
            $('#myAlertLayer').remove();
            return this;
        },
        addEvent:function(){
            var self=this;
            //关闭按钮
            $('#myAlertLayer .dia-close').click(function(){
                self.close();
            });
            //取消
            $('#myAlertLayer .dia-btn-sec').click(function(){
                self.close();
            });
            //确定 
            $('#myAlertLayer .dia-btn-fir').click(function(){
                //0为一个按钮 1为两个
                if(self.options.btn==0){
                    self.close();
                } else {
                    self.close();
                    self.options.callback();//点击确定后执行的事件
                }
            });
        },
    };
    var mTips = function(el,opt){
        this.ele = el;
        this.timer = null;
        this.defaults = {
            aheadTips:'',
            tips:'',
            type:0,
            times:'',
            delay:2000,
            callback:function(){

            }
        }
        this.option = $.extend({},this.defaults,opt)
    }
    mTips.prototype = {
        domAppend:function(){
            var tipLayer = "<div class='minTips'><span>"+this.option.aheadTips+this.option.times+"</span>"+this.option.tips+"</div>";
            $(this.ele).append(tipLayer);
        },
        domRemove:function(){
            // $('.minTips').fadeOut();
            $('.minTips').addClass("hidetip");
            setTimeout(()=>{
                $('.minTips').remove();
            },500)
            this.option.callback()
        },
        timerCount:function(){
            if(this.option.times<1){
                // alert('没有时间')
            }else{
                this.timer = setInterval(()=>{
                    if(this.option.times<2){
                        clearInterval(this.timer)
                    }else{
                        this.option.times -=1;
                        $('.minTips span').text(this.option.aheadTips+this.option.times)
                    }
                },1000)
            }
        },
        init:function(){
            this.domAppend();
            // $('.minTips').fadeIn();
            $('.minTips').addClass("showtip");
            this.timerCount();
            setTimeout(()=>{
                this.domRemove();
            },this.option.delay)
        }

    }


//        window.maskShare=maskShare;//将这个构造函数赋值在window上，方便外部访问
    $.fn.extend({
        mAlert:function(el,opt){
            var layerModel=new mAlert(el,opt);
            layerModel.init();
        },
        mTips:function(el,opt){
            var tipModel = new mTips(el,opt);
            tipModel.init();
        }
    })

}($,window,document));
