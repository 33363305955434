//当前用户相关
export default {
  namespaced: true,
  state: {
    listByEnterpriseId: [],
  },
  mutations: {
    updateState(state, data) {
      state[data.key] = data.value
    },
  },
}
