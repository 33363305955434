import Vue from 'vue'
const vm = new Vue()

const api = {}
/**
 * 查询授权确认书
 *
 */
api.getAuthContract =  function(query) {
    let url = `/kj-contract/contractInfo/getAuthContract`
    return vm.$http.post(url,query)
}
/**
 * 创建合同文件
 *
 */
 api.createContractFile =  function(id) {
    let url = `/kj-contract/contractInfo/createContractFile?id=${id}`
    return vm.$http.post(url)
}
/**
 * 下载合同文件
 *
 */
 api.downloadContractFile =  function(id,query) {
    let url = `/kj-contract/contractInfo/downloadContractFile?id=${id}`
    return vm.$http.post(url,query,{responseType:'blob'})
}
/**
 * 上传文件
 *
 */
 api.uploadFile = function(id,queObj) {
    let url = `/kj-media/media/opera/do/uploadMediaFile?modelCode=MKJ03CONTRACT&catgId=CCONTRACT001&busiKey=${id}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
/**
 * 查询影像件
 *
 */
 api.showFileByCatgIdBusiKey = function(id,queObj) {
    let url = `/kj-media/media/opera/do/showFileByCatgIdBusiKey?modelCode=MKJ03CONTRACT&catgId=CCONTRACT001&busiKey=${id}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
/**
 * 删除影像件
 *
 */
 api.deleteFileByFileId = function(id,queObj) {
    let url = `/kj-media/media/opera/do/deleteFileByFileId?modelCode=MKJ03CONTRACT&fileId=${id}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
export default api