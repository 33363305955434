import Vue from 'vue'
const vm = new Vue()

const api = {}
/**
 * 查询受益人信息
 *
 */
api.listBeneficiaryByEnterpriseId =  function(id) {
    let url = `/kj-kyc/enterprise/listBeneficiaryByEnterpriseId?enterpriseId=${id}`
    return vm.$http.get(url)
}

export default api