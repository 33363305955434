<style lang="scss" scoped>
.enterprise{
    padding: 0 30px;
    height: 525px;
    overflow: auto;        
}
.content-right-title{
    
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #4e78b1;
    margin: 50px 0;
}
.content-right-basics{   
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-basics span{
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #99a3b1;
}
.content-right-upload{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-upload span{
    
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #99a3b1;
}
.content-right-line{
    width: 100%;
    height: 2px;
    background-color: #f3f8ff;
    margin-bottom: 20px;
}
.content-right-radio{
    margin-right: 40px;
}
.upload-icon{
    width: 23px;
    height:27px;
    background:url("~@/static/img/uploadIcon.svg") center center no-repeat;
    margin-right: 5px;
}
.beneficiary-list{
    display: flex;
    margin-bottom: 20px;
}
.beneficiary-list-li{
    width: 74px;
    height: 32px;
    border-radius: 20px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #a6cafb;
    background-color: #f4f9ff;
    margin-right: 15px;
}
.beneficiary-list-li span{
    
    font-size: 12px;
    font-weight: normal;
    color: #4a4a4a;
}
.beneficiary-list-add{
    font-size: 14px;
    font-weight: bold;
    color: #5a9cf8;
    height: 32px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    border: 1px dashed #d9e8fe;
    border-radius: 20px;
    margin-right: 10px;
    padding: 0 10px;
}
</style>

<template>
    <div class="enterprise" v-bLoading="loading">
        <!-- 董事 法人 -->
        <div class="content-right-title"><span>{{isLegal ? $t('enterprise.director') : $t('enterprise.legalRepresentative')}}</span>{{$t('enterprise.info')}}</div>
        <div v-for="(table,index) in legalList">
            <div class="beneficiary-list" v-if="isLegal"> 
                  <!-- 董事 -->
                <div class="beneficiary-list-li"><span>{{ $t('enterprise.director')}}<i>{{index+1}}</i></span></div>
                <!-- 删除董事 -->
                <div class="beneficiary-list-add" v-if="index != 0" @click="delDirector(index)"><span>- {{$t('enterprise.deleteDirector')}}</span></div>
                <!-- 添加董事 -->
                <div class="beneficiary-list-add" @click="addDirector()" v-if="index+1 == legalList.length"><span>+ {{$t('enterprise.addDirector')}}</span></div>                
            </div>
            <el-form :model="ruleForm" :inline="true" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <div style="display: flex;justify-content: space-between;">
                        <!-- 基本信息 -->
                        <div class="content-right-basics">{{$t('enterprise.companyInfo')}}</div>
                        <!-- 与授权人信息相同 -->
                        <div class="content-right-radio">
                            <el-checkbox v-model="table.equalsContact"  @change="changeInfo(table,index)">{{$t('enterprise.sameAuthorizedPerson')}}</el-checkbox>
                        </div>
                    </div>
                    <el-form-item :label="'*'+ $t('common.name')" prop="name">
                        <el-input v-model="table.name" width="300px" @input="oninput"></el-input>
                    </el-form-item>
                    <el-form-item :label="'*'+ $t('common.positionCompany')" prop="name">
                        <el-input v-model="table.job" width="300px" @input="oninput"></el-input>
                    </el-form-item>
                    <el-form-item :label="'*'+ $t('common.email')" prop="name">
                        <el-input v-model="table.email" width="300px" @input="oninput"></el-input>
                    </el-form-item>
                    <el-form-item :label="'*'+ $t('common.phoneNo')" prop="name">
                        <el-input v-model="table.phone" width="300px" @input="oninput"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="国籍" prop="region">
                        <el-select v-model="table.phone" filterable :inline="true" placeholder="请选择">
                            <el-option v-for="item in countryList" :label="item.countryName" :value="item.countryCode"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item :label="'*'+ $t('common.IDType')" prop="region">
                        <el-select v-model="table.identType" :inline="true" @change="changeSel()" :placeholder="$t('common.plSelect')">
                            <el-option  v-for="(item, index) in credentialsObj" :key="index" :label="item.dispalyName" :value="item.dictParam"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="'*'+ $t('common.IDNo')" prop="phone">
                        <el-input v-model="table.identNo" width="300px" @input="oninput"></el-input>
                    </el-form-item>
                    <!-- 上传文件 -->
                    <div class="content-right-basics">{{$t('common.documents')}} <span>{{$t('common.pleaseUploadClear')}}</span></div>
                    <!-- 法人或董事影像件 -->
                    <el-form-item prop="value6" :label="'*'+ $t('common.IDDocFront')">
                        <kycUpload v-if="table.mediaId" @toOcr="ocrEvent" :index="index" :type="table.identType" :mediaId="table.mediaId" modelCode="MKJ02CONTACT" catgId="CCONTACT001"></kycUpload>
                    </el-form-item>
                    <el-form-item prop="value6" :label="'*'+ $t('common.IDDocBack')">
                        <kycUpload v-if="table.mediaId" @toOcr="ocrEvent" :type="table.identType" :mediaId="table.mediaId" modelCode="MKJ02CONTACT" catgId="CCONTACT002"></kycUpload>
                    </el-form-item>
                    <div class="content-right-line"></div>
            </el-form>
        </div>
    </div>
</template>

<script>
import '@/styles/element.css'
import kycUpload from './kycUpload'
export default {
    components: {kycUpload},
    data(){
        return{
            enterpriseId:this.$route.query.id,
            countryList:[],//国家集合
            location:this.$store.state.location || '',//境内或境外
            fixColumn:'',
            radio:'0',
            ruleForm: {
                name: '',
                region: '',
                companyName:'',
            },
            rules: {
                name: [
                    // { required: true, message: '请输入活动名称', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                region: [
                    // { required: true, message: '请选择活动区域', trigger: 'change' }
                ],
            },
            legalList:[{
                name:null,
                job:null,
                email:null,
                phone:null,
                identType:null,
                identNo:null,
                equalsContact:false,
            }],
            isLegal:true,//显示法人或董事
            loading:false,
            credentialsObj:[],//证件类型
        }
    },
    computed:{
        // credentialsObj(){
        //     let arr =  this.getSysDictValue('证件类型em.ComStepStatus')
        //     let filterArr = arr.filter(item=>{
        //         return  item['dictParam'] != 'ALL'
        //     })
        //     return filterArr
        // },
    },
    methods:{
        // 给input框强制刷新
        oninput(){
            this.$forceUpdate();
        },
        changeSel(){
            this.$forceUpdate()
        },
        addDirector(){
            this.legalList.push({
                name:null,
                job:null,
                email:null,
                phone:null,
                identType:null,
                identNo:null,
            });
        },
        delDirector(index){
            this.legalList.splice(index,1);
        },
        // 根据id查询法人信息
        listLegalByEnterpriseId(){
            this.loading =true;
            this.$API.legalPerson.listLegalByEnterpriseId(this.enterpriseId).then((data)=>{
                let tl = data.data.data || [];
                tl = this.filterArrayDict(tl);
                if(tl.length == 0){
                    this.loading =false;
                }else{
                    let list = []
                    tl.map(item=>{
                        item.enterpriseId = this.enterpriseId;
                        if(item.mediaId){

                        }else{
                            item.mediaId = this.getUid();
                        }
                        let obj = {}
                        obj.name = item.name 
                        obj.job = item.job 
                        obj.email = item.email 
                        obj.phone = item.phone 
                        obj.identType = item.identType 
                        obj.identNo = item.identNo 
                        obj.enterpriseId = item.enterpriseId 
                        obj.mediaId = item.mediaId 
                        obj.equalsContact = item.equalsContact 
                        list.push(obj)
                        return item
                    })                     
                    this.legalList = list;
                }
                this.loading =false;
            },(response)=>{
                this.loading =false;
            })
        },
        // 查询国家表
        getCountry(){
            this.loading =true;
            this.$API.enterprise.getCountry({}).then((data)=>{
                let tl = data.data.data;
                this.countryList = tl;
                this.loading = false;
            },(response)=>{
                this.loading = false;
            })
        },
        // 保存法人或董事信息
        saveSon(){
            // this.$emit('toSave',this.legalList);
            this.loading =true;
            this.$API.kycFileCN.updateLegalInPortal(this.legalList).then((data)=>{
                this.loading = false;
                let tl = data.data.data;
                if(data.data.code == '200'){
                    this.$message({
                        message: this.$t('login.savedSuccessfully'),
                        type: 'success'
                    });
                }
            },(response)=>{
                this.loading = false;
            })            
        },
        nextSon(){
            // this.$emit('toNext',this.legalList);
            this.loading = true;          
            this.$API.kycFileCN.handleAfterUpdateLegalInPortal(this.legalList).then((data)=>{                
                if(data.data.code == '200'){
                    this.loading = false;
                    this.$emit('toNext');
                }else{
                   this.$message.error(data.data.message);
                   this.loading = false; 
                }
            },(response)=>{
                this.loading = false;
            })
            
        },
        // 根据id查询授权人
        getManagerByEnterpriseId(table,index){
            this.loading =true;
            this.$API.authorPerson.getManagerByEnterpriseId(this.enterpriseId).then((data)=>{
                let tl = data.data.data || {};
                tl = this.filterObjectDict(tl);
                table.name = tl.name
                table.job = tl.job
                table.email = tl.email
                table.phone = tl.phone
                table.identType = tl.identType
                table.identNo = tl.identNo
                table.mediaId = tl.mediaId
                this.loading =false;        
            },(response)=>{
                this.loading =false;
            })
        },
        // 与授权人信息相同
        changeInfo(table,index){
            // table.equalsContact = !table.equalsContact;
            if(table.equalsContact){
                this.getManagerByEnterpriseId(table,index);
            }
            else{
                
                table.name = null;
                table.job = null;
                table.email = null;
                table.phone = null;
                table.identType = null;
                table.identNo = null;
                table.mediaId = this.getUid();
            }
            
        },
        // 接收ocr返回的数据
        ocrEvent(obj){
            let index = obj.index;           
            if(obj.id_number){
                this.legalList[index]['identNo'] = obj.id_number || '';
            }          
        },
    },
    async mounted(){
        this.listLegalByEnterpriseId();
        this.getCountry();
        if(this.$store.state.location == 'TERRITORY'){
            this.isLegal = false;
        }else{
            this.isLegal = true;
        }
        // 获取证件类型枚举
        this.credentialsObj = JSON.parse(
            sessionStorage.getItem(
                "证件类型em.ComStepStatus"
            )
        );
    },
}
</script>