<style lang="scss" scoped>
.kycFileCN {
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: #f7fafd;
  // padding: 114px 160px;
  padding: 74px 120px;
}
.kycFileCN-logo {
  width: 48px;
  height: 45px;
  background: url('../../static/img/logo.png') center center no-repeat;
  background-size: 100%;
  margin-bottom: 20px;
}
.kycFile-content {
  display: flex;
  align-items: center;
}
.kycFile-content-left {
  width: 296px;
  min-width: 296px;
}
.kycFile-content-right {
  flex: 1;
}
.content-right-box {
  width: 825px;
  // height: 597px;
  height: calc(100vh - 263px);
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  border: 1px solid transparent;
  overflow: hidden;
}
.content-left-list {
  // margin-top: 155px;
}
.content-left-enter {
  display: flex;
  height: 45px;
  line-height: 45px;
}
// 更改步骤条文字、图标样式
.enter-now {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/enterNow.svg') center center no-repeat;
  margin-right: 20px;
}
.enter-next {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/enterNext.svg') center center no-repeat;
  margin-right: 20px;
}
.rela-before {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/relaBefore.svg') center center no-repeat;
  margin-right: 20px;
}
.rela-now {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/relaNow.svg') center center no-repeat;
  margin-right: 20px;
}
.rela-next {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/relaNext.svg') center center no-repeat;
  margin-right: 20px;
}
.account-before {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/accountBefore.svg') center center no-repeat;
  margin-right: 20px;
}
.account-now {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/accountNow.svg') center center no-repeat;
  margin-right: 20px;
}
.account-next {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/accountNext.svg') center center no-repeat;
  margin-right: 20px;
}
.sign-before {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/signBefore.svg') center center no-repeat;
  margin-right: 20px;
}
.sign-now {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/signNow.svg') center center no-repeat;
  margin-right: 20px;
}
.sign-next {
  width: 45px;
  height: 45px;
  background: url('~@/static/img/signNext.svg') center center no-repeat;
  margin-right: 20px;
}
.words-now {
  font-size: 18px;
  font-weight: bold;
  color: #5a9cf8;
}
.words-next {
  font-size: 18px;
  font-weight: normal;
  color: #4e7ab6;
}
.words-before {
  font-size: 18px;
  font-weight: normal;
  color: #ced4db;
}
.line-now {
  width: 1px;
  height: 25px;
  background-color: #5a9cf8;
  margin-left: 23px;
}
.line-before {
  width: 1px;
  height: 25px;
  background-color: #eef1f5;
  margin-left: 23px;
}
.line-next {
  width: 1px;
  height: 25px;
  background-color: #4e7ab6;
  margin-left: 23px;
}
// 底部按钮样式
.footer-btn {
  width: 100%;
  height: 68px;
  line-height: 68px;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 -5px 5px 0 rgba(87, 138, 209, 0.1);
  background-color: #fff;
  ::v-deep .el-button {
    padding: 0 !important;
  }
}
.next-btn {
  width: 114px;
  height: 32px;
  align-items: center;
  border-radius: 15px;
  text-align: center;
  background-image: linear-gradient(to right, #5a9cf8, #90befd);
}
.next-btn span {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}
.save-btn {
  width: 114px;
  height: 32px;
  align-items: center;
  border-radius: 15px;
  text-align: center;
  background-image: linear-gradient(to right, #5a9cf8, #90befd);
  margin-right: 30px;
}
.save-btn span {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}
.before-btn {
  width: 114px;
  height: 32px;
  align-items: center;
  border-radius: 15px;
  text-align: center;
  background-image: linear-gradient(to right, #4f78b1, #7797c3);
  margin-right: 30px;
}
.before-btn span {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}
.disabled-btn {
  width: 114px;
  height: 32px;
  align-items: center;
  border-radius: 15px;
  text-align: center;
  background-color: #ebedef;
  margin-right: 30px;
}
.disabled-btn span {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}
.kycFileCN-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #4a4a4a;
}
.el-dropdown-link:hover .el-icon-arrow-down {
  transform: rotate(180deg);
}
.el-icon-arrow-down {
  font-size: 12px;
  color: #4a4a4a;
  font-weight: bold;
  transition: all 0.3s ease;
}
.ml170 {
  margin-left: 170px;
}
.ml270 {
  margin-left: 270px;
}
.content-right-box-item {
  padding-bottom: 68px;
  height: 100%;
}
.content-right-box-item-7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
</style>

<template>
  <div class="kycFileCN" v-if="isShow">
    <div class="kycFileCN-title">
      <div class="kycFileCN-logo"></div>
      <div>
        <el-dropdown style="margin-right: 10px">
          <span class="el-dropdown-link">
            {{ langString | filterUndefinedNull
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changeLang('zh')"
              >简体中文</el-dropdown-item
            >
            <el-dropdown-item @click.native="changeLang('en')"
              >English</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ userName | filterUndefinedNull
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- 重置密码 -->
            <el-dropdown-item @click.native="resetPwd">{{
              $t('login.resetPassword')
            }}</el-dropdown-item>
            <!-- 退出登录 -->
            <el-dropdown-item @click.native="logout">{{
              $t('login.logout')
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="kycFile-content">
      <div class="kycFile-content-left">
        <div class="content-left-list">
          <div class="content-left-enter">
            <div
              :class="{ 'enter-now': stepNum == 1, 'enter-next': stepNum >= 2 }"
            ></div>
            <!-- 企业信息 -->
            <div
              :class="{ 'words-now': stepNum == 1, 'words-next': stepNum >= 2 }"
            >
              {{ $t('enterprise.companyInformation') }}
            </div>
          </div>
          <div
            :class="{
              'line-before': stepNum == 1,
              'line-now': stepNum >= 2 && stepNum <= 4,
              'line-next': stepNum >= 5,
            }"
          ></div>
          <div class="content-left-enter">
            <div
              :class="{
                'rela-before': stepNum == 1,
                'rela-now': stepNum >= 2 && stepNum <= 4,
                'rela-next': stepNum >= 5,
              }"
            ></div>
            <!-- 关联人信息 -->
            <div
              :class="{
                'words-before': stepNum == 1,
                'words-now': stepNum >= 2 && stepNum <= 4,
                'words-next': stepNum >= 5,
              }"
            >
              {{ $t('enterprise.personnalInformation') }}
            </div>
          </div>
          <div
            :class="{
              'line-before': stepNum <= 4,
              'line-now': stepNum == 5,
              'line-next': stepNum >= 6,
            }"
          ></div>
          <div class="content-left-enter">
            <div
              :class="{
                'account-before': stepNum <= 4,
                'account-now': stepNum == 5,
                'account-next': stepNum >= 6,
              }"
            ></div>
            <!-- 账号信息 -->
            <div
              :class="{
                'words-before': stepNum <= 4,
                'words-now': stepNum == 5,
                'words-next': stepNum >= 6,
              }"
            >
              {{ $t('enterprise.accountInformation') }}
            </div>
          </div>
          <div
            :class="{
              'line-before': stepNum <= 5,
              'line-now': stepNum == 6,
              'line-next': stepNum >= 7,
            }"
          ></div>
          <div class="content-left-enter">
            <div
              :class="{
                'sign-before': stepNum <= 5,
                'sign-now': stepNum == 6,
                'sign-next': stepNum >= 7,
              }"
            ></div>
            <!-- 签署及提交 -->
            <div
              :class="{
                'words-before': stepNum <= 5,
                'words-now': stepNum == 6,
                'words-next': stepNum >= 7,
              }"
            >
              {{ $t('enterprise.eSignatureSubmit') }}
            </div>
          </div>
        </div>
      </div>
      <div class="kycFile-content-right" v-loading="loading">
        <div class="content-right-box">
          <recordReturn v-if="stepNum == 0"></recordReturn>
          <enterprise
            ref="enterprise"
            @toStatus="getStatus"
            @toNext="nextEnterprise"
            v-if="stepNum == 1"
            class="content-right-box-item"
          ></enterprise>
          <authorPerson
            ref="authorPerson"
            @toNext="nextAuthorPerson"
            v-if="stepNum == 2"
            class="content-right-box-item"
          ></authorPerson>
          <legalPerson
            ref="legalPerson"
            @toNext="nextLegalPerson"
            v-if="stepNum == 3"
            class="content-right-box-item"
          ></legalPerson>
          <beneficiary
            ref="beneficiary"
            @toNext="nextBeneficiary"
            v-if="stepNum == 4"
            class="content-right-box-item"
          ></beneficiary>
          <accountInfo
            ref="accountInfo"
            @toNext="nextAccountInfo"
            v-if="stepNum == 5"
            class="content-right-box-item"
          ></accountInfo>
          <signSubmit
            ref="signSubmit"
            @toSub="submitEvent"
            @toDel="delEvent"
            v-if="stepNum == 6"
            class="content-right-box-item"
          ></signSubmit>
          <submitSuccess
            v-if="stepNum == 7"
            class="content-right-box-item-7"
          ></submitSuccess>
          <footer class="footer-btn" v-if="isButtonShow">
            <div
              :class="{
                ml170: 1 < stepNum <= 5,
                ml270: stepNum >= 6 || stepNum == 1,
              }"
            >
              <!-- 上一步 -->
              <el-button
                v-if="!(stepNum == 1)"
                :class="{
                  'disabled-btn': stepNum == 1,
                  'before-btn': stepNum >= 2,
                }"
                @click="before()"
                :disabled="stepNum == 1"
                ><span>{{ $t('enterprise.previous') }}</span></el-button
              >
              <!-- 保存 -->
              <el-button
                class="save-btn"
                @click="save()"
                v-if="stepNum < 6"
                :disabled="stepNum >= 6"
                ><span>{{ $t('enterprise.save') }}</span></el-button
              >
              <!-- 提交 -->
              <el-button v-if="isSubShow" class="next-btn" @click="submit()"
                ><span>{{ $t('enterprise.submit') }}</span></el-button
              >
              <!-- 下一步 -->
              <el-button
                v-else
                :class="{
                  'disabled-btn': stepNum == 6,
                  'next-btn': stepNum <= 5,
                }"
                @click="next()"
                :disabled="stepNum == 6"
                ><span>{{
                  stepNum == 6 ? $t('enterprise.submit') : $t('enterprise.next')
                }}</span></el-button
              >
            </div>
          </footer>
        </div>
      </div>
    </div>
    <el-dialog
      class="resetPwd-box"
      :title="$t('login.resetPassword')"
      :visible.sync="isResetPwd"
      @close="toClose"
      :close-on-click-modal="false"
    >
      <div class="err-text"></div>
      <el-form
        :model="formReset"
        :rules="formResetrules"
        ref="formReset"
        status-icon
        hide-required-asterisk
        :validate-on-rule-change="false"
      >
        <el-form-item
          prop="oldPassword"
          ref="oldPassword"
          :label="$t('login.oldPassword')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formReset.oldPassword"
            show-password
            autocomplete="off"
            @focus="clearMsgErr('oldPassword')"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          ref="password"
          :label="$t('login.newPassword')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formReset.password"
            show-password
            autocomplete="off"
            @focus="clearMsgErr('password')"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="confirmPassword"
          ref="confirmPassword"
          :label="$t('login.confirmNewPassword')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="formReset.confirmPassword"
            show-password
            autocomplete="off"
            @focus="clearMsgErr('confirmPassword')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="err-text" style="padding-left: 120px">{{ resetErrMsg }}</div>
      <div slot="footer" class="dialog-footer">
        <!-- 取 消 -->
        <el-button @click="toClose">{{ $t('login.cancel') }}</el-button>
        <!-- 确 定 -->
        <el-button type="primary" @click="toReset('formReset')">{{
          $t('login.confirm')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import enterprise from './components/enterprise'
import authorPerson from './components/authorPerson'
import legalPerson from './components/legalPerson'
import beneficiary from './components/beneficiary'
import accountInfo from './components/accountInfo'
import signSubmit from './components/signSubmit'
import submitSuccess from './components/submitSuccess'
export default {
  components: {
    enterprise,
    authorPerson,
    legalPerson,
    beneficiary,
    accountInfo,
    signSubmit,
    submitSuccess,
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.pleaseEnterNewPassword')))
      } else if (value !== this.formReset.password) {
        callback(new Error(this.$t('login.passwordIncorrectAgain')))
      } else {
        callback()
      }
    }
    return {
      stepNum: 1,
      loading: false,
      enterpriseId: this.$route.query.id,
      isSubShow: false,
      isShow: false,
      fixColumn: '',
      ruleForm: {
        name: '',
        region: '',
      },
      isButtonShow: true, //控制底部按钮显隐
      userName: '',
      isResetPwd: false, //是否显示重置密码弹框
      resetErrMsg: '', //重置密码的错误提示信息
      //重置密码
      formReset: {
        confirmPassword: '',
        oldPassword: '',
        password: '',
      },
      formLabelWidth: '165px',
      formResetrules: {
        oldPassword: [
          {
            required: true,
            message: this.$t('login.pleaseEnterOldPassword'),
            trigger: 'submit',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('login.pleaseEnterNewPassword'),
            trigger: 'submit',
          },
        ],
        confirmPassword: [{ validator: validatePass2, trigger: 'submit' }],
      },
      langString: this.$i18n.locale == 'zh' ? '简体中文' : 'English',
    }
  },
  methods: {
    //修改语言
    async changeLang(lang) {
      localStorage.setItem('locale', lang)
      this.$i18n.locale = lang
      if (lang == 'zh') {
        this.langString = '简体中文'
      } else {
        this.langString = 'English'
      }
      //通知子应用更新
      this.$setGlobalState({ langlocale: lang })
      // debugger
      await this.againSetSessionStorage()
    },
    // 得到企业组件的状态
    getStatus(obj) {
      if (obj.status == 'SUBMIT') {
        this.stepNum = 7
        this.isButtonShow = false
      }
    },
    before() {
      this.stepNum--
      if (this.stepNum <= 6) {
        this.isSubShow = false
      }
    },
    next() {
      if (this.stepNum == 1) {
        this.$refs.enterprise.nextSon()
      } else if (this.stepNum == 2) {
        this.$refs.authorPerson.nextSon()
      } else if (this.stepNum == 3) {
        this.$refs.legalPerson.nextSon()
      } else if (this.stepNum == 4) {
        this.$refs.beneficiary.nextSon()
      } else if (this.stepNum == 5) {
        this.$refs.accountInfo.nextSon()
      }
    },
    // 校验企业信息
    nextEnterprise() {
      this.stepNum++
    },
    // 校验授权人信息
    nextAuthorPerson() {
      this.stepNum++
    },
    // 校验法人或董事信息
    nextLegalPerson() {
      this.stepNum++
    },
    // 校验受益人信息
    nextBeneficiary() {
      this.stepNum++
    },
    // 校验银行账户信息
    nextAccountInfo() {
      this.stepNum++
    },
    save() {
      if (this.stepNum == 1) {
        this.$refs.enterprise.saveSon()
      } else if (this.stepNum == 2) {
        this.$refs.authorPerson.saveSon()
      } else if (this.stepNum == 3) {
        this.$refs.legalPerson.saveSon()
      } else if (this.stepNum == 4) {
        this.$refs.beneficiary.saveSon()
      } else if (this.stepNum == 5) {
        this.$refs.accountInfo.saveSon()
      }
    },
    // 上传成功触发提交
    submitEvent() {
      this.isSubShow = true
    },
    // 删除后触发
    delEvent() {
      this.isSubShow = false
    },
    // 确定提交
    submit() {
      this.loading = true
      this.$API.kycFileCN
        .claimAndCompleteTaskByBusId(this.enterpriseId, {})
        .then(
          data => {
            if (data.data.code == '200') {
              // 提交成功
              this.$message({
                message: this.$t('login.submittedSuccessfully'),
                type: 'success',
              })
              this.stepNum = 7
              if (this.stepNum == 7) {
                this.isButtonShow = false
              }
            }
            this.loading = false
          },
          response => {
            this.loading = false
          }
        )
    },
    //字典存储在session
    // setSessionStorage() {
    //     this.$store.dispatch('getSysDictFun');
    // },
    //获取用户的基础信息
    async getUserInfo() {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.$http.post(`/kj-web/sys/getCurSysUser`).then(
          function(data) {
            let userObj = data.data.data || {}
            sessionStorage.setItem(
              'objCurUserInfo',
              JSON.stringify(data.data.data)
            )
            _this.userName = userObj.name || '-'
            resolve()
          },
          function(response) {
            // _this.$llsAlert({text: response});
            // _this.$alert(response, _this.$t('login.tips'), {
            //     confirmButtonText: _this.$t('login.confirm'),
            // });
            reject()
          }
        )
      })
    },
    //退出登录SSO
    async logoutSSO() {
      let res = await this.$sso.logout()
      if (res.code === '200') {
        localStorage.clear()
        sessionStorage.clear()
        this.$router.replace('/login')
      }
    },
    //退出登录
    async logout() {
      var _this = this
      try {
        var res = await this.$sso.logout()
        if (res) {
          // 登出成功
          this.$message({
            type: 'success',
            message: this.$t('login.logoutSuccessful'),
          })
          this.$router.replace('/login')
        }
        localStorage.clear()
      } catch (e) {
        var obj = JSON.parse(e.message)
        this.$message.error(obj.message)
      }
    },
    clearMsgErr(formName) {
      this.resetErrMsg = ''
      if (typeof formName == 'string') {
        this.$refs[formName].clearValidate()
      }
    },
    //重置密码
    resetPwd: function() {
      this.isResetPwd = true
      this.resetErrMsg = ''
      this.formReset = {
        confirmPassword: '',
        oldPassword: '',
        password: '',
      }
    },
    //关闭重置密码
    toClose: function() {
      this.isResetPwd = false
      this.$refs['formReset'].resetFields()
    },
    //确定重置密码
    toReset: function(formName) {
      let _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          // alert('submit!');
          _this.sureResetPwd()
          // this.cancleRe('ReSetruleForm')
        } else {
          return false
        }
      })
    },
    //重置密码
    async sureResetPwd() {
      let _this = this
      let oldPassword = this.formReset.oldPassword
      let password = this.formReset.password
      let confirmPassword = this.formReset.confirmPassword
      let loginName = JSON.parse(sessionStorage.getItem('objCurUserInfo')).email
      try {
        let params = {
          loginName: loginName,
          password: password, //新密码
          orcPassword: oldPassword,
          sysChannel: 'kj_my_pc',
        }
        let res = await this.$sso.resetPasswd(params)
        if (res.code == '200') {
          // 重置成功，请重新登录
          _this.$alert(
            _this.$t('login.resetSuccessfullyAgain'),
            _this.$t('login.tips'),
            {
              confirmButtonText: _this.$t('login.confirm'),
              callback: action => {
                _this.logout()
              },
            }
          )
        } else {
          // 暂无响应，请联系管理员
            _this.$t('login.serverNotRsponding')
        }
      } catch (e) {
        _this.resetErrMsg =
          JSON.parse(e.message).message || _this.$t('login.serverNotRsponding')
      }
    },
    //字典存储在session
    setSessionStorage() {
      let _this = this
      var objUser = JSON.parse(sessionStorage.getItem('objUser'))
      // this.userName = objUser.name;
      this.$http.post('/kj-web/dict/getAllDictList').then(
        function(data) {
          let DictList = data.data.data || []
          for (let i = 0; i < DictList.length; i++) {
            sessionStorage.setItem(
              DictList[i].dispalyName + '' + DictList[i].dictCode,
              JSON.stringify(DictList[i].subDictList)
            )
          }
          // _this.$setGlobalState({eventName:'allDictChange'});
        },
        function(response) {
        }
      )
    },
    // 切换中英文调用枚举接口
    againSetSessionStorage() {
      let _this = this
      var objUser = JSON.parse(sessionStorage.getItem('objUser'))
      // this.userName = objUser.name;
      this.$http.post('/kj-web/dict/getAllDictList').then(
        function(data) {
          let DictList = data.data.data || []
          for (let i = 0; i < DictList.length; i++) {
            sessionStorage.setItem(
              DictList[i].dispalyName + '' + DictList[i].dictCode,
              JSON.stringify(DictList[i].subDictList)
            )
          }
          if (_this.stepNum == 1) {
            _this.$refs.enterprise.getCountry()
            _this.$refs.enterprise.langChangeEvent()
          } else if (_this.stepNum == 2) {
            _this.$refs.authorPerson.credentialsObj = JSON.parse(
              sessionStorage.getItem('证件类型em.ComStepStatus')
            )
          } else if (_this.stepNum == 3) {
            _this.$refs.legalPerson.credentialsObj = JSON.parse(
              sessionStorage.getItem('证件类型em.ComStepStatus')
            )
          } else if (_this.stepNum == 4) {
            _this.$refs.beneficiary.credentialsObj = JSON.parse(
              sessionStorage.getItem('证件类型em.ComStepStatus')
            )
          } else if (_this.stepNum == 5) {
            _this.$refs.accountInfo.langChangeEvent()
          }
        },
        function(response) {
        }
      )
    },
  },
  async mounted() {
    await this.setSessionStorage()
    await this.getUserInfo()
    this.isShow = true
    // 建档状态为审核中，直接跳到最后一页
    if (this.$route.query.status == 'SUBMIT') {
      this.stepNum = 7
      this.isButtonShow = false
    }
  },
}
</script>