import Vue from 'vue'
const vm = new Vue()

const api = {}
/**
 * 保存企业信息
 *
 */
api.updateInPortal =  function(query) {
    let url = `/kj-kyc/enterprise/updateInPortal`
    return vm.$http.post(url,query)
}
/**
 * 校验企业信息
 *
 */
 api.handleAfterUpdateInPortal =  function(query) {
    let url = `/kj-kyc/enterprise/handleAfterUpdateInPortal`
    return vm.$http.post(url,query)
}
/**
 * 保存授权人信息
 *
 */
 api.updateManagerInPortal =  function(query) {
    let url = `/kj-kyc/enterprise/updateManagerInPortal`
    return vm.$http.post(url,query)
}
/**
 * 校验授权人信息
 *
 */
 api.handleAfterUpdateManagerInPortal =  function(query) {
    let url = `/kj-kyc/enterprise/handleAfterUpdateManagerInPortal`
    return vm.$http.post(url,query)
}
/**
 * 保存法人或董事信息
 *
 */
 api.updateLegalInPortal =  function(query) {
    let url = `/kj-kyc/enterprise/updateLegalInPortal`
    return vm.$http.post(url,query)
}
/**
 * 校验法人或董事信息
 *
 */
 api.handleAfterUpdateLegalInPortal =  function(query) {
    let url = `/kj-kyc/enterprise/handleAfterUpdateLegalInPortal`
    return vm.$http.post(url,query)
}
/**
 * 保存受益人信息
 *
 */
 api.updateBeneficiary =  function(query) {
    let url = `/kj-kyc/enterprise/updateBeneficiaryInPortal`
    return vm.$http.post(url,query)
}
/**
 * 校验受益人信息
 *
 */
 api.handleAfterUpdateBeneficiaryInPortal =  function(query) {
    let url = `/kj-kyc/enterprise/handleAfterUpdateBeneficiaryInPortal`
    return vm.$http.post(url,query)
}
/**
 * 保存银行账户信息
 *
 */
 api.updateBankAccount =  function(query) {
    let url = `/kj-kyc/enterprise/updateBankAccountInPortal`
    return vm.$http.post(url,query)
}
/**
 * 校验银行账户信息
 *
 */
 api.handleAfterUpdateBank =  function(query) {
    let url = `/kj-kyc/enterprise/handleAfterUpdateBankAccountInPortal`
    return vm.$http.post(url,query)
}
/**
 * 提交前调用
 *
 */
 api.claimAndCompleteTaskByBusId =  function(id,query) {
    let url = `/kj-wkfl/wkfl/claimAndCompleteTaskByBusId?auditState=pass&businessId=${id}&processDefKey=kj_kyc_audit&taskDefKey=usr-kjKycFstCheck`
    return vm.$http.post(url,query)
}

export default api