import Vue from 'vue'
import { JSEncrypt } from 'jsencrypt'
import {isArrayFn,filterDictParam,isDate} from './util.js'

Vue.prototype.getSessionStorage=function( key ){
    let val = sessionStorage.getItem(key);
    return JSON.parse(val);
}
//公共函数
Vue.prototype.urlQueryJoin=function(jsonObj){
    if(jsonObj){
        var finArr = '?';
        for(var i in jsonObj){
            if(typeof jsonObj[i] == 'string'){
                if(jsonObj[i].indexOf('{')>-1){//将json字符串取出需要的值
                    jsonObj[i] = JSON.parse(jsonObj[i]).dictParam
                }
                // else if(/\-\:/.test(jsonObj[i])>-1){//日期统一只取年月日
                //     jsonObj[i] = jsonObj[i].substring(0,10);
                // }
            }
            finArr+=i+'='+jsonObj[i]+'&'
        }
        return finArr.substring(0,finArr.length-1);
    }
}
//公共函数-获取屏幕高度
Vue.prototype.getSrcollHeight=function(){
    var browser = {
        versions: function() {
            var u = navigator.userAgent,
                app = navigator.appVersion;
            return { //移动终端浏览器版本信息
                trident: u.indexOf('Trident') > -1, //IE内核
                presto: u.indexOf('Presto') > -1, //opera内核
                webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
                iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, //是否iPad
                webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
            };
        }()
    }
    //获取网页正文全文高
    var srcollH = "";
    //IE，Firefox等用documentElement获取网页正文高度，Chrome用body
    var bodyH = document.body.clientHeight;
    var domH = document.documentElement.clientHeight
    if(browser.versions.webKit) {
        srcollH = (domH > bodyH ? domH : bodyH);
    } else {
        srcollH = document.documentElement.clientHeight;
    }
    return Number(srcollH);
}
/*
 * 公共函数-取新数组在旧数组基础上新增的元素
 * arrOld-旧数组； arrNew-新数组
 * [1,3,5] [2,4,5,6]
 */
Vue.prototype.getAddList=function(arrOld,arrNew){
    var addList = [];
    for(var k = 0;k<arrNew.length;k++) {
        var thisIsNew = true;
        for(var i = 0;i<arrOld.length;i++) {
            if(arrNew[k] == arrOld[i]) {
                thisIsNew = false;
                break;
            }
        }
        if(thisIsNew) {
            addList.push(arrNew[k]);
        }
    }
    return addList;
}
/*
 * 公共函数-取新数组在旧数组基础上减少的元素
 * arrOld-旧数组； arrNew-新数组
 * [1,3,5] [2,4,5,6]
 */
Vue.prototype.getRemoveList=function(arrOld,arrNew){
   var removeList = [];
    for(var k = 0;k<arrOld.length;k++) {
        var thisIsRemove = true;
        for(var i = 0;i<arrNew.length;i++) {
            if(arrOld[k] == arrNew[i]) {
                thisIsRemove = false;
                break;
            }
        }
        if(thisIsRemove) {
            removeList.push(arrOld[k]);
        }       
    }
    return removeList;
}
/*
 * 公共函数-浏览器记录埋点
 * 记录点：时间，userAgent，系统类型，内核类型（IE内核-Trident/opera内核-Presto/谷歌内核-AppleWebKit/火狐内核-Gecko），媒体终端类型（IOS/Android/iPad/PC），浏览器类型
 */
// Vue.prototype.borwser=function(){
//     var u = navigator.userAgent;
//     var borwserType = "";//浏览器类型
//     var coreType = "";//内核类型
//     var mediaType = "";//媒体终端类型
//     //浏览器类型
//     if (u.indexOf("Firefox") > -1) {
//         borwserType = "Firefox";
//     } else if (u.indexOf("QQBrowser") > -1) {
//         borwserType = "QQ";
//     } else if (u.indexOf("LBBROWSER") > -1) {
//         borwserType = "LB";
//     } else if (u.indexOf("BIDUBrowser") > -1) {
//         borwserType = "BD";
//     } else if (u.indexOf("TencentTraveler") > -1) {
//         borwserType = "TT";
//     } else if (u.indexOf("SE 2.X MetaSr 1.0") > -1) {
//         borwserType = "Sogou";
//     } else if (u.indexOf("Edge") > -1) {
//         borwserType = "Edge";
//     } else if (u.indexOf("Opera") > -1 || u.indexOf("OPR") > -1) {
//         borwserType = "Opera";
//     } else if (u.indexOf("Chrome") > -1) {
//         borwserType = "Chrome";
//     } else if (u.indexOf("Safari") > -1) {
//         borwserType = "Safari";
//     } else if (u.indexOf("MSIE") > -1 || u.indexOf("Trident") > -1) {
//         borwserType = "IE";
//     }
//     //内核类型
//     if(u.indexOf('Trident') > -1) {
//         coreType = "Trident";
//     } else if(u.indexOf('Presto') > -1) {
//         coreType = "Presto";
//     } else if(u.indexOf('AppleWebKit') > -1) {
//         coreType = "AppleWebKit";
//     } else if(u.indexOf('Gecko') > -1) {
//         coreType = "Gecko";
//     }
//     //媒体终端类型
//     if(!!u.match(/AppleWebKit.*Mobile.*/)) {
//         if(!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
//             mediaType = "IOS";
//         } else {
//             mediaType = "Android";
//         }
//     } else if(u.indexOf('iPad') > -1) {
//         mediaType = "iPad";
//     } else {
//         mediaType = "PC";
//     }
//     var myDate = new Date();
//     var now = myDate.toLocaleString();        //获取日期与时间
//     now = now.replace("上午","").replace("下午","").replace("年","/").replace("月","/").replace("日","/");
//     //调用接口保存
// }
//公共函数-数据格式校验
Vue.prototype.checkRule= (() =>{
    const dataCheckRule = {
        money:{//金额 后端支持的金额是12位整数 1位小数点 2位小数
            regExp:/^[0-9]{1,12}(.[0-9]{1,2})?$/,
        },
        number:{
            regExp:/^\d{1,}$/,//至少一位数字
        },
        zipcode:{
            regExp:/^\d{6}$/,//只能是6位数字
        },
        invonum:{
            regExp:/^\d{8}$/,//只能是8位数字
        },
        tenpayAcco:{
            regExp:/^([0-9]){10,10}$/,//只能是10位数字
        },
        socialUnifiedCode:{
            regExp:/^[a-zA-Z\d]{18}$/,//18位数字字母
        },
        telphone:{
            regExp:/^(((13[0-9])|(14[579])|(15([0-3]|[5-9]))|(16[6])|(17[0135678])|(18[0-9])|(19[89]))\d{8})$/,//与后端相同的手机号码正则
        },
        idcode:{
            regExp:/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            //15-18位身份证号码校验
        },
        passport:{
            regExp:/^.{1,30}$/, //证件号码最多支持30位
        },
        gangaoCard:{
            regExp:/^.{1,30}$/,
        },
        taiwanCard:{
            regExp:/^.{1,30}$/,
        },
        acctcode:{
            regExp:/^([0-9]|-){1,30}$/,//1位到30位银行账户校验，可以带-（日本银行。。。）
        },
        email:{
            regExp:/^([0-9A-Za-z\-_\.]+)@([0-9A-Za-z\-_]+\.[a-z]{2,3}(\.[a-z]{2})?)$/,
            //开始必须是一个或者多个单词字符或者是-，加上@，然后又是一个或者多个单词字符或者是-。然后是点“.”和单词字符和-的组合，可以有一个或者多个组合。
            JSRule(checkVal){
                if(String.length <= 60){
                    return true
                }else{
                    return false
                }
            }
        },
        rate:{
            regExp:/^[0-9]+(.[0-9]{1,3})?$/,
        },//有1~3位小数的正实数
        time:{
            regExp:/^(\d{4})\-(\d{2})\-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/,//yyyy-mm-dd hh:mm:ss格式的时间
        },
        date:{
            regExp:/^(\d{4})\-(\d{2})\-(\d{2})$/,//yyyy-mm-dd格式的日期
        },
        busiLicenseNo:{//营业执照
            regExp:/^[0-9a-zA-Z]{15}$/,
        },
        orgCode:{//组织机构代码
            regExp:/^[a-zA-Z0-9]{8}-[a-zA-Z0-9]$/,
        },
        cashRate:{//融资费率
            JSRule(checkVal){
                if(Number(checkVal) > 0 && Number(checkVal) < 50) {
                    return true;
                }
                return false
            }
        },
        fileName:{ //后台最长支持200位,10位预留给后缀名
            regExp:/^[_a-z0-9\u4E00-\u9FFF]{1,190}$/i,
        }
    }
    return  (checkType,checkVal)=>{
        let passFlag = true;
        if(dataCheckRule[checkType].regExp && !dataCheckRule[checkType].regExp.test(checkVal)){
            passFlag = false
        }

        if(dataCheckRule[checkType].JSRule && !dataCheckRule[checkType].JSRule(checkVal)){
            passFlag = false
        }
        return passFlag;
    }
})();
//RSA密码加密
// 加密
Vue.prototype.encryptedData = function(data,publicKey) {
    //私钥 和后端沟通写死了
    // 新建JSEncrypt对象
    let encryptor = new JSEncrypt();
    // 设置公钥
    encryptor.setPublicKey(publicKey);
    // 加密数据
    return encryptor.encrypt(data);
}
//枚举获取方法
Vue.prototype.getSysDictValue = (name,isNoAll)=>{
    let arr = window.vm.$store.state.allSysDict.filter((item)=>{
        return item['name'] == name
    })
    if(arr.length){
        if(arr.length == 0){
            //不存在，先去session取
            if(sessionStorage.getItem(name)){
                arr = [{'value':JSON.parse(sessionStorage.getItem(name))}]
            }else {
                arr = [{'value':[]}]
            }

        }
    }else {
        arr = [{'value':[]}]
    }
    if(isNoAll){
        arr[0]['value'] = arr[0]['value'].filter(item=>{
            return item['dictParam'] != 'ALL'
        })
    }

    return arr[0]['value']
}
//JSON字符串获取dictParam-对象
Vue.prototype.filterObjectDict = function(obj, enumKey){
    if (obj instanceof Object) {
        Object.keys(obj).forEach(key => {
            obj[key] = filterDictParam(obj[key], enumKey)
        })
    } else {
        return obj;
    }
    return obj;
}
//JSON字符串获取dictParam-数组
Vue.prototype.filterArrayDict = function filterArray(arr, enumKey) {
    if (arr instanceof Array) {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = window.vm.filterObjectDict(arr[i], enumKey)
        }
        return arr
    } else {
        return arr;
    }
}
//生成UUID
Vue.prototype.getUid=function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
//JSON数据判断
Vue.prototype.filterJSONString = function (JSONString,key) {
    if(key){
        return JSONString ? JSON.parse(JSONString)[key] : ''
    }else {
        return JSONString ? JSON.parse(JSONString).dictParam : ''
    }

}