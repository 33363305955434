<template>
  <div id="cbfUserPortal" class="micor-app-box"></div>
</template>

<script>
const getActiveRule = hash => location => location.hash.startsWith(hash)
import { loadMicroApp } from 'qiankun'
export default {
  name: 'cbfUserPortal',
  data() {
    return {
      microApp: null,
    }
  },
  watch: {
    $route: function(val, oldVal) {
      this.microApp.update({ routeChange: { to: val, from: oldVal } })
    },
  },
  methods: {},
  mounted() {
    let currentRoute = this.$route.fullPath
    this.microApp = loadMicroApp(
      {
        name: 'cbfUserPortal', //应用名字
        entry:
          process.env.NODE_ENV === 'production'
            ? '/cbfUserPortal/'
            : '//localhost:9999', //默认会加载这个HTML 动态加载资源 （必须支持跨域）
        // fetch
        container: '#cbfUserPortal', //容器名
        activeRule: getActiveRule('#/childUserPt'), //激活路径
        props: {
          fatherRouter: this.$router,
          currentRoute,
        },
      },
      {
        singular: false,
      }
    )
  },
  beforeDestroy() {
    this.microApp.unmount()
  },
}
</script>
