<!-- 上传文件的组件 -->
<style lang="scss" scoped>
.upload-icon {
  width: 23px;
  height: 27px;
  background: url('~@/static/img/uploadIcon.svg') center center no-repeat;
  margin-right: 5px;
}
.kyc-upload {
  width: 340px;
  height: 180px;
  border-radius: 8px;
  overflow: auto;
}
.kyc-upload-active {
  background-color: #f9fcff;
}
.kyc-img-pdf {
  display: inline-block;
  width: 98px !important;
  height: 98px !important;
  background: url('~@/static/img/pdf.png') center center no-repeat;
}
::v-deep .el-dialog__body {
  height: 500px;
  overflow: auto;
}
</style>

<template>
  <div
    class="kyc-upload"
    :class="isShow ? 'kyc-upload-active' : ''"
    v-loading="loading"
  >
    <el-upload
      v-if="isShow"
      class="upload-demo"
      list-type="picture-card"
      :file-list="fileList"
      ref="businessUploadFile"
      :http-request="businessUploadFile"
      action=""
      multiple
      :class="{ hide: hideUpload }"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img
          v-if="
            file.fileTypeDic != 'PDF' &&
            file.fileTypeDic != 'ZIP' &&
            file.fileTypeDic != 'XLSX' &&
            file.fileTypeDic != 'LSX'
          "
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          alt=""
        />
        <span
          class="el-upload-list__item-thumbnail"
          :class="{ 'kyc-img-pdf': file.fileTypeDic == 'PDF' }"
        ></span>
        <span
          class="el-upload-list__item-actions"
          :class="{ 'kyc-img-pdf': file.fileTypeDic == 'PDF' }"
        >
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-upload
      v-else
      class="upload-demo"
      drag
      ref="businessUploadFile"
      :http-request="businessUploadFile"
      action=""
      multiple
    >
      <div class="el-upload__text" v-if="isDigipo">
        <div class="upload-icon"></div>
        {{ $t('enterprise.dragFilesHere') }}&nbsp;<em>
          {{ $t('enterprise.browse') }}</em
        ><br />
        {{ $t('enterprise.supportFormat') }}
      </div>

      <div class="el-upload__text" v-else>
        <div class="upload-icon"></div>
        {{ $t('enterprise.dragFilesHere') }}&nbsp;<em>{{
          $t('enterprise.browse')
        }}</em>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <iframe
        :src="dialogImageUrl"
        width="100%"
        height="100%"
        v-if="pdfShow && dialogImageUrl"
        style="margin-top: 10px"
      ></iframe>
      <img
        width="100%"
        :src="dialogImageUrl"
        alt=""
        style="margin-top: 10px"
        v-if="!pdfShow && dialogImageUrl"
      />
    </el-dialog>
  </div>
</template>

<script>
import '@/styles/element.css'

export default {
  props: [
    'mediaId',
    'modelCode',
    'catgId',
    'type',
    'index',
    'limit',
    'isDigipo',
  ],
  data() {
    return {
      isCityShow: false, //控制注册省市显隐
      loading: false,
      isFileShow: 1, //控制影像件显隐
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      isShow: false,
      fileList: [],
      hideUpload: false,
      limitCount: 1,
      pdfShow: false,
    }
  },
  watch: {
    mediaId: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.showFileByCatgIdBusiKey()
        }
      },
    },
  },
  methods: {
    handleRemove(file) {
      let _this = this
      this.loading = true
      this.$API.kycUpload
        .deleteFileByCatgId(this.mediaId, this.modelCode, this.catgId, {})
        .then(
          (data) => {
            if (data.data.code == '200') {
              // 删除成功
              _this.$message({
                message: _this.$t('enterprise.deleteSuccessfully'),
                type: 'success',
              })
            }
            this.loading = false
          },
          (response) => {
            this.loading = false
          }
        )
      let uploadFiles = this.$refs.businessUploadFile.uploadFiles
      for (var i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i]['url'] == file.url) {
          uploadFiles.splice(i, 1)
        }
      }
      this.fileList = uploadFiles
      if (this.fileList.length == 0) {
        this.isShow = false
        this.$emit('change', 0)
      } else {
        this.isShow = true
      }
      if (this.limit) {
        this.limitCount = this.limit
      }
      this.hideUpload = this.fileList.length >= this.limitCount
    },
    handlePictureCardPreview(file) {
      let fileName = file.url.substring(file.url.lastIndexOf('.'))
      if (fileName == '.pdf') {
        this.pdfShow = true
      } else {
        this.pdfShow = false
      }
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传影像件
    businessUploadFile(item) {
      var _this = this
      this.loading = true
      const form = new FormData()
      form.append('file', item.file)
      // 营业执照、身份证正反面调ocr的接口 || this.catgId == 'CCONTACT001' || this.catgId == 'CCONTACT002'
      if (this.catgId == 'CENT005') {
        this.$API.kycUpload
          .uploadFileOcr(this.mediaId, this.modelCode, this.catgId, form)
          .then(
            (data) => {
              if (data.data.code == '200') {
                let tl = data.data.data || {}
                tl.mediaFile.url = tl.mediaFile.path
                tl.mediaFile.fileTypeDic = this.filterJSONString(
                  tl.mediaFile.fileType
                )
                this.fileList.push(tl.mediaFile)
                if (this.fileList.length == 0) {
                  this.isShow = false
                } else {
                  this.isShow = true
                }
                if (this.limit) {
                  this.limitCount = this.limit
                }
                this.hideUpload = this.fileList.length >= this.limitCount
                let ocrObj = tl.ocrResult.data || {}
                // console.log(ocrObj,'======ocr========');
                _this.$emit('toOcr', ocrObj)
                // 上传文件成功
                _this.$message({
                  message: _this.$t('enterprise.fileUploadedSuccessfully'),
                  type: 'success',
                })
              }
              this.loading = false
            },
            (response) => {
              let uid = item.file.uid
              let idx = this.$refs.uploadFile.uploadFiles.findIndex(
                (item) => item.uid === uid
              )
              this.$refs.uploadFile.uploadFiles.splice(idx, 1)
              this.loading = false
            }
          )
      } else if (this.type == 'CREDENTIALS_ID') {
        let index = this.index
        this.$API.kycUpload
          .uploadFileOcr(this.mediaId, this.modelCode, this.catgId, form)
          .then(
            (data) => {
              if (data.data.code == '200') {
                let tl = data.data.data || {}
                tl.mediaFile.url = tl.mediaFile.path
                tl.mediaFile.fileTypeDic = this.filterJSONString(
                  tl.mediaFile.fileType
                )
                this.fileList.push(tl.mediaFile)
                if (this.fileList.length == 0) {
                  this.isShow = false
                } else {
                  this.isShow = true
                }
                if (this.limit) {
                  this.limitCount = this.limit
                }
                this.hideUpload = this.fileList.length >= this.limitCount
                let ocrObj = tl.ocrResult.data || {}
                // console.log(ocrObj,'======ocr========');
                ocrObj.index = index
                _this.$emit('toOcr', ocrObj)
                _this.$message({
                  message: _this.$t('enterprise.fileUploadedSuccessfully'),
                  type: 'success',
                })
              }
              this.loading = false
            },
            (response) => {
              let uid = item.file.uid
              let idx = this.$refs.uploadFile.uploadFiles.findIndex(
                (item) => item.uid === uid
              )
              this.$refs.uploadFile.uploadFiles.splice(idx, 1)
              this.loading = false
            }
          )
      } else {
        this.$API.kycUpload
          .uploadFile(this.mediaId, this.modelCode, this.catgId, form)
          .then(
            (data) => {
              if (data.data.code == '200') {
                let tl = data.data.data || {}
                tl.url = tl.path
                tl.fileTypeDic = this.filterJSONString(tl.fileType)
                this.fileList.push(tl)
                var num = this.fileList.length
                this.$emit('change', num)
                if (this.fileList.length == 0) {
                  this.isShow = false
                } else {
                  this.isShow = true
                }
                _this.$message({
                  message: _this.$t('enterprise.fileUploadedSuccessfully'),
                  type: 'success',
                })
                if (this.limit) {
                  this.limitCount = this.limit
                }
                this.hideUpload = this.fileList.length >= this.limitCount
              }
              this.loading = false
            },
            (response) => {
              // let uid = item.file.uid
              // let idx = this.$refs.uploadFile?.uploadFiles?.findIndex(
              //   (item) => item.uid === uid
              // )
              // this.$refs.uploadFile?.uploadFiles?.splice(idx, 1)
              this.loading = false
            }
          )
      }
    },
    // 查询显示影像
    showFileByCatgIdBusiKey() {
      this.loading = true
      this.$API.kycUpload
        .showFileByCatgIdBusiKey(this.mediaId, this.modelCode, this.catgId, {})
        .then(
          (data) => {
            if (data.data.code == '200') {
              let tl = data.data.data || []
              if (tl.length == 0) {
                this.isShow = false
              } else {
                this.isShow = true
              }
              tl.map((item) => {
                item.url = item.path
                item.fileTypeDic = this.filterJSONString(item.fileType)
                return item
              })
              this.fileList = tl
              if (this.limit) {
                this.limitCount = this.limit
              }
              this.hideUpload = this.fileList.length >= this.limitCount
            }
            this.loading = false
          },
          (response) => {
            this.loading = false
          }
        )
    },
  },
  mounted() {
    this.showFileByCatgIdBusiKey()
  },
}
</script>