<template>
  <svg
    class="icon-class"
    :style="{ fill }"
    aria-hidden="true"
  >
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
      require: true
    },
    fill: {
      type: String
    },
  },
  computed: {
    iconName() {
      return `#menu-${this.iconClass}`
    }
  }
}
</script>

<style scoped lang="scss">
.icon-class {
  width: 16px;
  height: 16px;
}
</style>
