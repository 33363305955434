//引入一些公共方法
import Vue from 'vue'
let vm = new Vue()
//判断是否为数组
export function isArrayFn(value){
    if (typeof Array.isArray === "function") {
        return Array.isArray(value);
    }else{
        return Object.prototype.toString.call(value) === "[object Array]";
    }
}
//判断是否为日期
export function isDate(data){
    if(isNaN(data)&&!isNaN(Date.parse(data))){
        return true
    }else {
        return false
    }
}
//判断是否为数字
export function isANumber(val){
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if(val.toString().indexOf(',') == -1){
        //不存在,
    }else {
        //存在,
        val = vm.rmFormatMoney(val)
    }
    if(regPos.test(val) || regNeg.test(val)){
        return true;
    }else{
        return false;
    }
}
/**
 * 解析枚举数据
 * @param {string} val "{dictParam: somestr, dictKey: somestr2}"
 * @param {string} key 需要取出的值对应的键, default-dictParam
 */
export function filterDictParam(val, key) {
    let str = ""
    if (!val) return val;
    if (typeof val != "string") return val;
    if (val.indexOf('dictParam' > -1) && val.indexOf('dictKey') > -1) {
        if (key) {
            str = JSON.parse(val)[key];
        } else {
            str = JSON.parse(val).dictParam
        }
    } else {
        str = val
    }
    return str;
}

/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accAdd(arg1, arg2) {
    //去除金额格式化
    if(arg1 === undefined){
        arg1 = 0
    }
    if(arg2 === undefined){
        arg2 = 0
    }
    arg1 = vm.rmFormatMoney(arg1)
    arg2 = vm.rmFormatMoney(arg2)
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
}
/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accSub(arg1, arg2) {
    //去除金额格式化
    if(arg1 === undefined){
        arg1 = 0
    }
    if(arg2 === undefined){
        arg2 = 0
    }
    arg1 = vm.rmFormatMoney(arg1)
    arg2 = vm.rmFormatMoney(arg2)
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
export function accMul(arg1, arg2) {
    //去除金额格式化
    if(arg1 === undefined){
        arg1 = 0
    }
    if(arg2 === undefined){
        arg2 = 0
    }
    arg1 = vm.rmFormatMoney(arg1)
    arg2 = vm.rmFormatMoney(arg2)
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    }
    catch (e) {
    }
    try {
        m += s2.split(".")[1].length;
    }
    catch (e) {
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
/**
 ** 除法函数，用来得到精确的除法结果
 ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 ** 调用：accDiv(arg1,arg2)
 ** 返回值：arg1除以arg2的精确结果
 **/
export function accDiv(arg1, arg2) {
    //去除金额格式化
    if(arg1 === undefined){
        arg1 = 0
    }
    if(arg2 === undefined){
        arg2 = 0
    }
    arg1 = vm.rmFormatMoney(arg1)
    arg2 = vm.rmFormatMoney(arg2)
    var t1 = 0, t2 = 0, r1, r2;

    try {
        t1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
    }
    if(arg2 === undefined){
        arg2 = 0
    }
    try {
        t2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
    }
    // with (Math) {
    //
    // }
    try{
        r1 = Number(arg1.toString().replace(".", ""));
        r2 = Number(arg2.toString().replace(".", ""));
    }catch (e) {
    }

    return (r1 / r2) * Math.pow(10, t2 - t1);
}
//判断两个对象是否相等
export function isObjectValueEqual(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}
/**
 * 获取当前日期 yyyy-dd-mm
 */
export function getToday() {
    let time = new Date();
    let year = time.getFullYear();
    let month = time.getMonth();
    let day = time.getDate();

    month = month < 9 ? '0' + (month + 1) : month + 1;
    day = day < 10 ? '0' + day : day;
    return `${year}-${month}-${day}`;
}
//随机生成一个颜色代码
export function getColorCode(){
    var color = '#'+ Math.random().toString(16).substr(-6);
    return color;
}
//列表某个值的合计值
export function sumInTableList(tl,key) {
    let totalMoenyArr = []
    tl.map(item=>{
        totalMoenyArr.push(item[key]||0)
        return item
    })
    return totalMoenyArr.reduce((pre,cur)=>pre + cur,0)
}
//根据[id,....]匹配[{key:id,....}]返回符合要求的[{},{}]
export function idMatchInTableList(recordIdList,tableList) {
    let checkArr = []
    for(let li of recordIdList){
        checkArr.push(tableList.filter(item=>{
            return li == item.id
        })[0])
    }
    return checkArr
}