//中文
export default {
  login: {
    account: '账号',
    password: '密码',
    verificationCode: '验证码',
    login: '登录',
    email: '邮箱',
    forgetPwd: '忘记密码',
    resetPassword: '重置密码',
    logout: '退出登录',
    oldPassword: '旧密码',
    newPassword: '新密码',
    confirmNewPassword: '确认新密码',
    cancel: '取消',
    confirm: '确定',
    passwordIncorrectAgain: '输入密码不一致，请重新输入',
    pleaseEnterNewPassword: '请输入新密码',
    pleaseEnterOldPassword: '请输入旧密码',
    tips: 'Tips',
    savedSuccessfully: '保存成功',
    submittedSuccessfully: '提交成功',
    logoutSuccessful: '登出成功',
    resetSuccessfullyAgain: '重置成功，请重新登录',
    serverNotRsponding: '暂无响应，请联系管理员',
    return: '返回',
    send: '发送',
    sendCode: '发送验证码',
    PleaseEnterUsername: '请输入账号',
    pleaseEnterPassword: '请输入密码',
    pleaseEnterVerification: '请输入验证码',
    pleaseEnterEmail: '请输入邮箱',
    pleaseReenterPassword: '请再次输入新密码',
    passwordResetLogin: '密码重置成功，请重新登录',
    pleaseResetPassword: '新用户请初始化密码',
    verificationSentInbox: '验证码已发送，请查收',
    noCompanyFoundContact: '您暂无企业，请联系运营人员协助处理，谢谢',
    onboardingStatusAbnormal: '建档状态异常，请联系管理员',
    loginAgain: 'Sign in Again',
    noPermission: '暂无权限，请联系管理员',
    pswValidatePopover: {
      title: '密码必须满足：',
      rule1: '长度为 8-15 个字符',
      rule2: '1个大写字母',
      rule3: '1个小写字母',
      rule4: '1个数字和特殊字符',
    },
    pswNotSame: '确认新密码与新密码不一致',
    //请输入6位数字验证码
    pleaseEnterSixDigitVerificationCode: '请输入6位数字验证码',
    verificationCodeError: '验证码错误',
  },
  common: {
    documents: '上传文件',
    pleaseUploadClear:
      '(请上传清晰、完整、无遮挡物的图片，支持 .pdf, .jpeg, .jpg, .png 文档)',
    name: '姓名',
    email: '电子邮箱',
    phoneNo: '手机号码',
    positionCompany: '公司职位',
    IDType: '证件类型',
    IDNo: '证件编号',
    plSelect: '请选择',
    IDDocFront: '证件正面照',
    IDDocBack: '证件反面照',
    countdownMarketClosure: '闭市截止时间',
    hour: '小时',
    minute: '分钟',
    second: '秒',
    signedSuccessfully: '签署成功',
  },
  tips: {
    agreedFollowingTerms: '1、你同意本平台服务协议的以下条款；',
    constitutesElectronicSignature:
      '2、为《电子交易条例》（香港法律第553章）之目的，该行为构成你对本平台服务协议的电子签署。',
    clickingButtonConfirm: '通过点击此按钮，你同意并确认:',
    clickAgreeConfirm: '请点击按钮，同意并确认',
    needAgreementSigning: '您需要阅读完成整个协议再签署，谢谢配合。',
    confirmToSign: '确定签署',
    selectCompanyLogin: '请选择登陆企业',
    agreementFirstPlatformServices:
      '您好，您尚未签署平台服务协议，为更好地给您提供完整的平台服务，您需要先签署该协议',
  },
  enterprise: {
    tellYourCompany: '企业信息',
    companyInfo: '基本信息',
    companyNameChinese: '企业中文名称',
    companyNameEnglish: '企业英文名称',
    companyDocsType: '企业证件类型',
    companyDocsNo: '企业证件编号',
    countryIncorporation: '注册国家/地区',
    registeredCity: '注册省市',
    registeredCityElse: '注册城市',
    registeredAddress: '注册详细地址',
    certificateIncorporation: '公司注册证书正面照',
    companyDocsArticlesOrganization: '公司章程、股权架构等其他资料',
    businessLicence: '营业执照正面照',
    businessRegistration: '商业登记证正面照',
    incorporationForm: '法团成立表格正面照',
    tellAboutYourself: '授权人信息',
    sameAuthorizedPerson: '与授权人信息相同',
    whoBeneficialOwner: '受益人信息',
    BONaturalPerson:
      '受益人是：对企业的资产拥有25%或以上直接或间接股权关系的自然人，或 持有在股东大会上25%或以上表决权的自然人。',
    addBeneficialOwner: '添加受益人',
    deleteBeneficiaryOwner: '删除受益人',
    beneficialOwner: '受益人',
    tellYourBankAccount: '账户信息',
    account: '账户',
    deleteAccount: '删除账户',
    addAccount: '添加账户',
    currency: '账户币种',
    bankAccountType: '银行账户类型',
    accountName: '账户名',
    bankAccountNo: '银行账号',
    bankName: '开户银行',
    bankCountry: '开户国家',
    bankCity: '开户城市',
    CNAPSCode: '联行行号',
    branchName: '所在支行',
    bankSWIFTBIC: '开户行swift code',
    intermediaryBankSWIFTBIC: '中转行swift code',
    eSignatureSubmit: '签署及提交',
    pleaseDownloadDocument: '请下载并签署文件',
    pleaseUploadDocument: '请将已签署的文件上传至下方',
    download: '下载',
    companyInformation: '企业信息',
    personnalInformation: '关联人信息',
    accountInformation: '账号信息',
    previous: '上一步',
    save: '保存',
    next: '下一步',
    submit: '提交',
    fileUploadedSuccessfully: '上传文件成功',
    deleteSuccessfully: '删除成功',
    dragFilesHere: '将文件拖到此处，或',
    browse: '点击上传',
    supportFormat: '(每个文件 < 20MB,支持JPG,PDF 和 PNG格式)',
    authenticationFailed: '认证失败',
    retry: '重试',
    legalRepresentative: '法人',
    director: '董事',
    info: '信息',
    deleteDirector: '删除董事',
    addDirector: '添加董事',
    success: '提交成功',
    ourTeamReviewInformation: '我们的团队将会审查您的信息，',
    sendConfirmationEmail: '您将在提交后24小时内收到确认电邮。',
  },
  bigHome: {
    digitalFinance: '数字金融',
    digitalFinanceContent: '创新的供应链融资方式',
    digitalTrade: '数字贸易',
    digitalTradeContent: '人工智能+区块链，赋能国际贸易',
    pendingTasks: '待办',
    linklogisInternational: '联易融国际',
    linklogisInternationalContent:
      '致力于为全球银行和企业提供一流的数字供应链融资服务',
    downloadManagement: '下载管理',
    No: '序号',
    fileName: '文件名',
    status: '导出状态',
    createTime: '创建时间',
    action: '操作',
    download: '下载',
  },
  menu: {
    moreProduct: '更多产品',
  },
  contract: {
    title: '电子债权凭证产品和服务协议',
    download: '下载模板',
    submit: '提交',
    downloadAndSignfiles: '下载并签署文件',
    uploadTheSignedDocument: '请将已签署的文件上传',
    cfcaDigital: '《CFCA数字证书服务协议》',
    checkThisBox: '通过勾选此框及点击“提交”，您同意签署',
    pleaseCheck: 'Please check the "CFCA Digital Certificate Service"',
  },
  downloadMgm: {
    fileName: '文件名',
    status: '打包状态',
  },
}
