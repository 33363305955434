// import { reject, resolve } from 'core-js/fn/promise';
import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
const vm = new Vue()
Vue.use(Vuex)
import createPersistedState from 'vuex-persistedstate'
const store = new Vuex.Store({
  state: {
    count: 1,
    screenWidth: document.documentElement.clientWidth, //屏幕宽度
    screenHeight: document.documentElement.clientHeight, //屏幕高度
    timer: false,
    timerH: false,
    isBlock: true,
    oldPassword: '', //登录成功后存贮用户旧密码
    allSysDict: [], //系统所有枚举
    userInfo: '', //业务用户信息
    sysResourceDOList: [], //元素权限列表
    pageresourceObj: [], //当前页面的资源权限
    currentCompany: '', //选择的企业
    location: '', //选择的企业为境内或境外
    projectFinishFlag: false, //项目初始化完毕
    currentProduct: '', //当前选择的产品
    whiteLabelList: null, // 内置贴牌
    // kycStatus: '',
  },
  getters: {
    getStateScreenWidth: (state) => {
      return state.screenWidth
    },
    getStateScreenHeight: (state) => {
      return state.screenHeight
    },
  },
  mutations: {
    updateProduct(state, data) {
      state.currentProduct = data
    },
    screenWidthChange(state) {
      if (!state.timer) {
        state.timer = true
        state.screenWidth = document.documentElement.clientWidth
        setTimeout(() => {
          state.timer = false
        }, 500)
      }
    },
    screenHeightChange(state) {
      if (!state.timerH) {
        state.timerH = true
        state.screenHeight = document.documentElement.clientHeight
        setTimeout(() => {
          state.timerH = false
        }, 500)
      }
    },
    //判断当前页面是否为区块链
    isBlockChain(state) {
      // var curURL = window.location.hostname
      // if(curURL.indexOf('abs-test') > -1 || curURL.indexOf('abs.llschain') > -1 ){
      //     //非区块链域名地址
      //     state.isBlock = false
      // }
      // if (state.isBlock) {
      //     document.title = '联易融数科 | ABS区块链';
      // } else {
      //     document.title = '联易融数科 | ABS云';
      // }
    },
    //获取用户的登录密码
    updateOldPassword(state, val) {
      state.oldPassword = val
    },
    //缓存相关页面
    clearRouteAliveList(state, item) {
      let name = ''
      if (!item.path) {
        name = item.menuUrl.split('/')[1]
      } else {
        name = item.path.split('/')[1]
      }

      if (state.routeAliveList.indexOf(name) > -1) {
        //存在-即证明重复点击同一个菜单
      } else {
        state.routeAliveList = []
        state.routeAliveCount = 0
      }
    },
    //缓存相关页面
    addRouteAliveList(state, name) {
      state.routeAliveList.push(name)
      state.routeAliveCount = 0
    },
    //缓存相关页面
    calcRouteAliveCount(state) {
      state.routeAliveCount += 1
    },
    //获取系统枚举
    getSysDict(state) {
      return new Promise((resolve, reject) => {
        vm.$API.system.getSysDict().then(
          (data) => {
            let DictList = data.data.data || []
            for (let i = 0; i < DictList.length; i++) {
              DictList[i].subDictList.push({
                dictCode: '',
                dictKey: '',
                dictParam: 'ALL',
                dispalyName: '全部',
              })

              let obj = {}
              obj.name =
                (DictList[i].dispalyName || '') + '' + DictList[i].dictCode
              obj.value = DictList[i].subDictList
              state.allSysDict.push(obj)
            }
            let otherDict = {
              name: '是否类型fms.YN',
              value: [
                {
                  dictCode: '',
                  dictKey: '',
                  dictParam: 'Y',
                  dispalyName: '是',
                },
                {
                  dictCode: '',
                  dictKey: '',
                  dictParam: 'N',
                  dispalyName: '否',
                },
                {
                  dictCode: '',
                  dictKey: '',
                  dictParam: 'ALL',
                  dispalyName: '全部',
                },
              ],
            }
            state.allSysDict.push(otherDict)
            let otherDict1 = {
              name: '白名单检验类型fms.isWhitePass',
              value: [
                {
                  dictCode: '',
                  dictKey: '',
                  dictParam: 'Y',
                  dispalyName: '通过',
                },
                {
                  dictCode: '',
                  dictKey: '',
                  dictParam: 'N',
                  dispalyName: '不通过',
                },
                {
                  dictCode: '',
                  dictKey: '',
                  dictParam: 'ALL',
                  dispalyName: '全部',
                },
              ],
            }
            state.allSysDict.push(otherDict1)
            //本地session再存一份
            for (let i = 0; i < state.allSysDict.length; i++) {
              sessionStorage.setItem(
                state.allSysDict[i].name,
                JSON.stringify(state.allSysDict[i].value)
              )
            }
            resolve()
          },
          (err) => {
            reject()
          }
        )
      })
    },
    //储存用户信息
    saveUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    //储存元素权限列表
    saveSysResourceDOList(state, sysResourceDOList) {
      state.sysResourceDOList = sysResourceDOList
    },
    saveWhiteLabel(state, value) {
      state.whiteLabelList = value
    }
    // setKycStatus(state, kycStatus) {
    //   state.kycStatus = kycStatus
    // },
  },
  actions: {
    screenWidthFun(context) {
      context.commit('screenWidthChange')
    },
    screenHeightFun(context) {
      context.commit('screenHeightChange')
    },
    isBlockChainFun(context) {
      context.commit('isBlockChain')
    },
    updateOldPasswordFun(context, val) {
      context.commit('updateOldPassword', val)
    },
    clearrouteAliveListFun(context, item) {
      context.commit('clearRouteAliveList', item)
    },
    addRouteAliveListFun(context, name) {
      context.commit('addRouteAliveList', name)
    },
    calcRouteAliveCountFun(context) {
      context.commit('calcRouteAliveCount')
    },
    //获取系统所有枚举
    getSysDictFun(context) {
      context.commit('getSysDict')
    },
    //储存用户信息
    saveUserInfoFun(context, userInfo) {
      context.commit('saveUserInfo', userInfo)
    },
    saveSysResourceDOListFun(context, sysResourceDOList) {
      context.commit('saveSysResourceDOList', sysResourceDOList)
    },
  },
  modules: {
    user,
  },
  plugins: [
    createPersistedState({
      // 本地存储数据的名字
      key: 'kj-root-store',
      // 指定需要存储的模块
      storage: window.sessionStorage,
    }),
  ],
})
export default store
