import Vue from 'vue'
const vm = new Vue()

const api = {}
/**
 * 查询授权人信息
 *
 */
api.getManagerByEnterpriseId =  function(id) {
    let url = `/kj-kyc/enterprise/getManagerByEnterpriseId?enterpriseId=${id}`
    return vm.$http.get(url)
}
/**
 * 上传文件
 *
 */
 api.uploadFile = function(mediaId,catgId,queObj) {
    let url = `/kj-media/media/opera/do/uploadMediaFile?modelCode=MKJ02CONTACT&catgId=${catgId}&busiKey=${mediaId}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
export default api