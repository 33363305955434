import Vue from 'vue'
const vm = new Vue()

const api = {}
/**
 * 上传文件
 *
 */
 api.uploadFile = function(mediaId,modelCode,catgId,queObj) {
    let url = `/kj-media/media/opera/do/uploadMediaFile?modelCode=${modelCode}&catgId=${catgId}&busiKey=${mediaId}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
/**
 * 删除已上传文件
 *
 */
 api.deleteFileByCatgId = function(mediaId,modelCode,catgId,queObj) {
    let url = `/kj-media/media/opera/do/deleteFileByCatgId?modelCode=${modelCode}&catgId=${catgId}&busiKey=${mediaId}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
/**
 * 查询影像件
 *
 */
 api.showFileByCatgIdBusiKey = function(mediaId,modelCode,catgId,queObj) {
    let url = `/kj-media/media/opera/do/showFileByCatgIdBusiKey?modelCode=${modelCode}&catgId=${catgId}&busiKey=${mediaId}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
/**
 * 上传文件到ocr
 *
 */
 api.uploadFileOcr = function(mediaId,modelCode,catgId,queObj) {
    let url = `/kj-kyc/enterprise/uploadMediaFile?modelCode=${modelCode}&catgId=${catgId}&busiKey=${mediaId}&sysCode=KJ_SYSTEM`
    return vm.$http.post(url,queObj)

}
export default api