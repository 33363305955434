<style lang="scss" scoped>
.enterprise{
    padding: 0 30px;
    height: 525px;
    overflow: auto;        
}
.content-right-title{
    
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #4e78b1;
    margin: 50px 0;
}
.content-right-basics{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-basics span{
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #99a3b1;
}
.content-right-line{
    width: 100%;
    height: 2px;
    background-color: #f3f8ff;
    margin-bottom: 20px;
}
.upload-icon{
    width: 23px;
    height:27px;
    background:url("~@/static/img/uploadIcon.svg") center center no-repeat;
    margin-right: 5px;
}
</style>

<template>
    <div class="enterprise" v-bLoading="loading">
        <!-- 授权人信息 -->
        <div class="content-right-title">{{$t('enterprise.tellAboutYourself')}}</div>
        <el-form :model="ruleForm" :inline="true" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <!-- 基本信息 -->
                <div class="content-right-basics">{{$t('enterprise.companyInfo')}}</div>
                <el-form-item :label="'*'+ $t('common.name')" prop="name">
                    <el-input v-model="authorPersonList.name" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="'*'+ $t('common.positionCompany')" prop="name">
                    <el-input v-model="authorPersonList.job" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="'*'+ $t('common.email')" prop="email">
                    <el-input :disabled="true" v-model="authorPersonList.email" width="300px"></el-input>
                </el-form-item>
                <el-form-item :label="'*'+ $t('common.phoneNo')" prop="phone">
                    <el-input v-model="authorPersonList.phone" width="300px"></el-input>
                </el-form-item>
                <!-- <el-form-item label="国籍" prop="region">
                    <el-select v-model="authorPersonList.phone" filterable :inline="true" placeholder="请选择">
                        <el-option v-for="item in countryList" :label="item.countryName" :value="item.countryCode"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item :label="'*'+ $t('common.IDType')" prop="region">
                    <el-select v-model="authorPersonList.identType" :inline="true" :placeholder="$t('common.plSelect')">
                        <el-option  v-for="(item, index) in credentialsObj" :key="index" :label="item.dispalyName" :value="item.dictParam"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="'*'+ $t('common.IDNo')" prop="phone">
                    <el-input v-model="authorPersonList.identNo" width="300px" @input="oninput"></el-input>
                </el-form-item>
                <div class="content-right-line"></div>
                <!-- 上传文件 -->
                <div class="content-right-basics">{{$t('common.documents')}} <span>{{$t('common.pleaseUploadClear')}}</span></div>
                <!-- 授权人影像件 -->
                <el-form-item prop="value6" :label="'*'+ $t('common.IDDocFront')">
                    <kycUpload v-if="authorPersonList.mediaId" @toOcr="ocrEvent" :type="authorPersonList.identType" :mediaId="authorPersonList.mediaId" modelCode="MKJ02CONTACT" catgId="CCONTACT001"></kycUpload>
                </el-form-item>
                <el-form-item prop="value6" :label="'*'+ $t('common.IDDocBack')">
                    <kycUpload v-if="authorPersonList.mediaId" @toOcr="ocrEvent" :type="authorPersonList.identType" :mediaId="authorPersonList.mediaId" modelCode="MKJ02CONTACT" catgId="CCONTACT002"></kycUpload>
                </el-form-item>
        </el-form>
    </div>
</template>

<script>
import '@/styles/element.css'
import kycUpload from './kycUpload'
export default {
    components: {kycUpload},
    data(){
        return{
            enterpriseId:this.$route.query.id,
            countryList:[],//国家集合
            location:this.$store.state.location || '',//境内或境外
            isOcrShow:false,//上传影像到ocr显影
            authorPersonList:{
                name:null,
                job:null,
                email:null,
                phone:null,
                identType:null,
                identNo:null,
            },
            fixColumn:'',
            ruleForm: {
                name: '',
                region: '',
                companyName:'',
            },
            rules: {
                name: [
                    // { required: true, message: '请输入活动名称', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                region: [
                    // { required: true, message: '请选择活动区域', trigger: 'change' }
                ],
            },
            loading:false,
            credentialsObj:[],//证件类型
        }
    },
    watch:{
        authorPersonList:{
            deep:true,
            handler(newVal,oldVal){
                this.authorPersonList = newVal;
            }
        }
    },
    computed:{
        // credentialsObj(){
        //     let arr =  this.getSysDictValue('证件类型em.ComStepStatus')
        //     let filterArr = arr.filter(item=>{
        //         return  item['dictParam'] != 'ALL'
        //     })
        //     return filterArr
        // },
    },
    methods:{
        // 给input框强制刷新
        oninput(){
            this.$forceUpdate();
        },
        // 根据id查询授权人
        getManagerByEnterpriseId(){
            this.$API.authorPerson.getManagerByEnterpriseId(this.enterpriseId).then((data)=>{
                let tl = data.data.data || {};
                tl = this.filterObjectDict(tl);
                if(tl.mediaId){
                    
                }else{
                    tl.mediaId = this.getUid();
                } 
                if(tl.legalPerson){

                }else{
                    tl.legalPerson = 'N';
                }               
                this.authorPersonList = tl;                
            },(response)=>{})
        },
        // 查询国家表
        getCountry(){
            this.$API.enterprise.getCountry({}).then((data)=>{
                let tl = data.data.data;
                this.countryList = tl;
                this.loading = false;
            },(response)=>{
                this.loading = false;
            })
        },
        // 保存授权人信息
        saveSon(){
            // this.$emit('toSave',this.authorPersonList);
            this.loading = true;
            this.$API.kycFileCN.updateManagerInPortal(this.authorPersonList).then((data)=>{
                this.loading = false;
                let tl = data.data.data;
                if(data.data.code == '200'){
                    this.$message({
                        message: this.$t('login.savedSuccessfully'),
                        type: 'success'
                    });
                }
            },(response)=>{
                this.loading = false;
            })
        },
        nextSon(){
            // this.$emit('toNext',this.authorPersonList);
            this.loading = true;          
            this.$API.kycFileCN.handleAfterUpdateManagerInPortal(this.authorPersonList).then((data)=>{                
                if(data.data.code == '200'){
                    this.loading = false;
                    this.$emit('toNext');
                }else{
                   this.$message.error(data.data.message); 
                   this.loading = false;
                }
            },(response)=>{
                this.loading = false;
            })
        },
        // 接收ocr返回的数据
        ocrEvent(obj){
            if(obj.id_number){
                this.$set(this.authorPersonList,'identNo',obj.id_number);
            }
           
        },
    },
    mounted(){
        this.getManagerByEnterpriseId();
        this.getCountry();
        // 获取证件类型枚举
        this.credentialsObj = JSON.parse(
            sessionStorage.getItem(
                "证件类型em.ComStepStatus"
            )
        );
    },
}
</script>