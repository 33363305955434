<style lang="scss" scoped>
.signSubmit{
    padding: 0 30px;
    height: 525px;
    overflow: auto;        
}
.signSubmit-title{
    
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #4e78b1;
    margin: 50px 0;
}
.signSubmit-left{
    flex: 1;
}
.signSubmit-right{
    flex: 1;
}
.content-right-num{
    width: 19px;
    height: 19px;
    line-height: 19px;
    text-align: center;
    border-radius: 50%;
    background-color: #4e7ab6;
    margin-bottom: 5px;
    margin-right: 5px;
}
.content-right-num span{
    
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
}
.content-right-words{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;   
}
.content-right-download{
    width: 114px;
    height: 32px;
    line-height: 32px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    background-image: linear-gradient(to right, #5a9cf8, #90befd);
    margin: 10px 0;
    position: absolute;
    top: 230px;
    left: 50%;
    transform: translateX(-50%);
}
.content-right-download span{
    
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
}
.signSubmit-upload{
    margin-top: 10px;
}
.upload-icon{
    width: 23px;
    height:27px;
    background:url("~@/static/img/uploadIcon.svg") center center no-repeat;
    margin-right: 5px;
}
.see-upload-file{
    margin-top: 10px;
    margin-right: 10px;
}
.see-upload-top{
    width: 100%;
    height: 32px;
    border-radius: 12px 12px 0 0;
    background-color: #a3acb9;
    display: flex;
    justify-content: flex-end;
}
.upload-top-icon{
    width: 16px;
    height: 16px;
    background:url("~@/static/img/seeIcon.svg") center center no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-top: 8px;
    margin-right: 16px;
}
.upload-top-del{
    width: 16px;
    height: 16px;
    background:url("~@/static/img/delIcon.svg") center center no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-top: 8px;
    margin-right: 16px;
}
.signSubmit-iframe{
    width: calc(100% - 10px);
    height: 300px;
    /* background-color: gold; */
    border-radius: 0 0 12px 12px;
    /* border: 1px solid #4e78b1; */
    position: relative;
}
</style>

<template>
    <div class="signSubmit">
        <!-- 签署及提交 -->
        <div class="signSubmit-title">{{$t('enterprise.eSignatureSubmit')}}</div>
        <div style="display:flex;">
            <div class="signSubmit-left">
                <div style="display: flex;">
                    <div class="content-right-num"><span>1</span></div>
                    <!-- 请下载并签署文件 -->
                    <div class="content-right-words">{{$t('enterprise.pleaseDownloadDocument')}}</div>
                </div>
                <div class="see-upload-file">
                    <div class="see-upload-top">
                        <div class="upload-top-icon" @click="downPreviewFile()"></div>
                        <!-- <div class="upload-top-del"></div> -->
                    </div>
                </div>
                <div class="signSubmit-iframe">
                    <iframe width="100%" height="100%" :src="downloadFile" frameborder="0"></iframe>
                    <div class="content-right-download" @click="download()"><span>{{$t('enterprise.download')}}</span></div>
                </div>
            </div>
            <div class="signSubmit-right">
                <div style="display: flex;">
                    <div class="content-right-num"><span>2</span></div>
                    <!-- 请将已签署的文件上传至下方 -->
                    <div class="content-right-words">{{$t('enterprise.pleaseUploadDocument')}}</div>
                </div>
                <div class="signSubmit-upload" v-if="!isUploadShow">
                    <el-upload
                        class="upload-demo"
                        drag
                        ref="uploadFile"
                        :http-request="uploadFile"
                        action=""
                        multiple>
                        <div class="el-upload__text"><div class="upload-icon"></div>{{$t('enterprise.dragFilesHere')}}&nbsp;<em>{{$t('enterprise.browse')}}</em></div>
                    </el-upload>
                </div>
                <div v-if="isUploadShow">
                    <div class="see-upload-file">
                        <div class="see-upload-top">
                            <div class="upload-top-icon" @click="uploadPreviewFile()"></div>
                            <div class="upload-top-del" @click="delPreviewFile()"></div>
                        </div>
                    </div>
                    <div class="signSubmit-iframe">
                        <iframe width="100%" height="100%" :src="uploadFilePath"></iframe>
                    </div>
                </div>
            </div>
            <!-- 预览文件弹框 -->
            <div class="preview-dialog">
                <el-dialog
                title=""
                :visible.sync="showPreviewDialog"
                width="800px">
                    <iframe width="100%" height="450px" scrolling="auto" :src="enlargePreview"></iframe>
                </el-dialog>
            </div>
        </div>       
    </div>
</template>

<script>
import '@/styles/element.css'
export default {
    data(){
        return{
            enterpriseId:this.$route.query.id,
            showPreviewDialog:false,
            contractId:'',
            downloadFile:'',//下载文件预览路径
            uploadFilePath:'',//上传文件预览路径
            isUploadShow:false,//控制上传或预览
            enlargePreview:'',//大图预览
            fileId:'',//上传文件的fileId
        }
    },
    methods:{
        // 下载合同文件
        download(){
            this.$API.signSubmit.downloadContractFile(this.contractId,{}).then((data)=>{
                // let tl = data.data.data || {};
                
            },(response)=>{})
        },
        // 下载文件的放大预览
        downPreviewFile(){
            this.enlargePreview = '';
            this.showPreviewDialog = true;
            this.enlargePreview = this.downloadFile;
        },
        // 上传文件的放大预览
        uploadPreviewFile(){
            this.enlargePreview = '';
            this.showPreviewDialog = true;
            this.enlargePreview = this.uploadFilePath;
        },
        // 查询授权书
        async getAuthContract(){
            await this.$API.signSubmit.getAuthContract({}).then((data)=>{
                let tl = data.data.data || {};
                this.contractId = tl.id;
                this.createContractFile();
            },(response)=>{})
        },
        // 创建合同文件
        createContractFile(){
            this.$API.signSubmit.createContractFile(this.contractId).then((data)=>{
                let tl = data.data.data || {};
                // this.bankCodeDict = tl;
                this.downloadFile = '/' + tl.path;
            },(response)=>{})
        },
        // 上传文件
        uploadFile(item){
            var _this =this;
            const form = new FormData();
            form.append('file', item.file);
            this.$API.signSubmit.uploadFile(this.enterpriseId,form).then((data)=>{
                if(data.data.code == '200'){
                    // 上传文件成功
                    _this.$message({
                        message: _this.$t('enterprise.fileUploadedSuccessfully'),
                        type: 'success'
                    });
                    let tl = data.data.data || {};
                    this.fileId = tl.id;
                    this.uploadFilePath = '/' + tl.path;
                    this.isUploadShow = true;
                    if(this.uploadFilePath != ''){
                        this.$emit('toSub')
                    }
                }
            },(response)=>{
                let uid = item.file.uid;
                let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid);
                this.$refs.uploadFile.uploadFiles.splice(idx, 1);
            })
        },
        // 进入页面查看是否已经上传了文件
        async showFileByCatgIdBusiKey(){
            await this.$API.signSubmit.showFileByCatgIdBusiKey(this.enterpriseId,{}).then((data)=>{
                if(data.data.code == '200'){
                    let tl = data.data.data || [];
                    if(tl.length == 0){
                        this.isUploadShow = false;
                    }else{
                        this.isUploadShow = true;
                        this.uploadFilePath = '/' + tl[0].path;
                        this.fileId = tl[0].id;
                    }                   
                    if(this.uploadFilePath != ''){
                        this.$emit('toSub')
                    }
                }
            },(response)=>{})
        },
        delPreviewFile(){
            this.$API.signSubmit.deleteFileByFileId(this.fileId,{}).then((data)=>{
                if(data.data.code == '200'){
                    this.isUploadShow = false;
                    this.$emit('toDel')
                    // let tl = data.data.data || [];
                    // if(tl.length == 0){
                    //     this.isUploadShow = false;
                    // }else{
                    //     this.isUploadShow = true;
                    // }
                    // this.uploadFilePath = '/' + tl[0].path;
                    // if(this.uploadFilePath != ''){
                    //     this.$emit('toSub')
                    // }
                }
            },(response)=>{})
        },
    },
    async mounted(){
        await this.getAuthContract();
        await this.showFileByCatgIdBusiKey();
    },
}
</script>