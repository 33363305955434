<style lang="scss" scoped>
.enterprise{
    padding: 0 30px;
    height: 525px;
    overflow: auto;        
}
.content-right-title{
    
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #4e78b1;
    margin: 50px 0;
}
.content-right-basics{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-upload{
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.content-right-upload span{    
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #99a3b1;
}
.content-right-line{
    width: 100%;
    height: 2px;
    background-color: #f3f8ff;
    margin-bottom: 20px;
}
.content-right-radio{
    margin-right: 40px;
}
.beneficiary-list{
    display: flex;
    margin-bottom: 20px;
}
.beneficiary-list-li{
    font-size: 12px;
    color: #4a4a4a;
    height: 32px;
    border-radius: 20px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #a6cafb;
    background-color: #f4f9ff;
    margin-right: 15px;
    padding: 0 10px;
}
.beneficiary-list-add{
    font-size: 14px;
    font-weight: bold;
    color: #5a9cf8;
    height: 32px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    border: 1px dashed #d9e8fe;
    border-radius: 20px;
    margin-right: 10px;
    padding: 0 10px;
}
</style>

<template>
    <div class="enterprise" v-bLoading="loading">
        <!-- 账号信息 -->
        <div class="content-right-title">{{$t('enterprise.tellYourBankAccount')}}</div>
        <div v-for="(table,index) in bankInfoList" :key="index">
            <div class="beneficiary-list">
                <!-- 账户 -->
                <div class="beneficiary-list-li"><span>{{$t('enterprise.account')}}<i>{{index+1}}</i></span></div>
                <!-- 删除账号 -->
                <div class="beneficiary-list-add" v-if="index != 0" @click="delBankInfo(index)"><span>- {{$t('enterprise.deleteAccount')}}</span></div>
                <!-- 添加账号 -->
                <div class="beneficiary-list-add" @click="addBankInfo()" v-if="index+1 == bankInfoList.length"><span>+ {{$t('enterprise.addAccount')}}</span></div>                
            </div>
            <el-form :model="ruleForm" :inline="true" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <!-- 基本信息 -->
                <div class="content-right-basics">{{$t('enterprise.companyInfo')}}</div>
                <!-- 账户币种 -->
                <el-form-item :label="'*'+ $t('enterprise.currency')" prop="region">
                    <el-select v-model="table.currency" :inline="true" :placeholder="$t('common.plSelect')" @change="changeCurrency(table)">
                        <el-option  v-for="(item, index) in currencyObj" :key="index" :label="item.dispalyName" :value="item.dictParam"></el-option>
                    </el-select>
                </el-form-item>
                <!-- 账户名 -->
                <el-form-item :label="'*'+ $t('enterprise.accountName')" prop="name">
                    <el-input v-model="table.accountName" width="300px"></el-input>
                </el-form-item>
                <!-- 银行账号 -->
                <el-form-item :label="'*'+ $t('enterprise.bankAccountNo')" prop="region">
                    <el-input v-model="table.accountNo" width="300px"></el-input>
                </el-form-item>
                <!--<el-form-item label="开户国家" prop="region" v-if="table.changeCny">-->
                    <!--<el-select v-model="table.bankCountryCode" filterable :inline="true" @change="changeCountry(table)" placeholder="请选择">-->
                        <!--<el-option v-for="item in countryList" :label="item.countryName" :value="item.countryCode"></el-option>-->
                    <!--</el-select>-->
                <!--</el-form-item>-->
                <!-- 开户城市 -->
                <el-form-item :label="'*'+ $t('enterprise.bankCity')" prop="name" v-if="table.changeCny">
                    <el-cascader
                    size="large"
                    :options="options"
                    ref="cascader"
                    v-model="table.bankCityAll"
                    @change="handleChange(table,index)">
                    </el-cascader>
                </el-form-item>
                <!-- 开户银行 -->
                <el-form-item :label="'*'+ $t('enterprise.bankName')" prop="region" v-if="table.changeCny">
                    <el-select v-model="table.bankCode" :inline="true" :placeholder="$t('common.plSelect')" @change="changeBank(table)">
                        <el-option v-for="(item, index) in bankCodeDict" :key="index" :label="item.dispalyName" :value="item.dictKey"></el-option>
                    </el-select>
                </el-form-item>
                <!-- 所在支行 -->
                <el-form-item :label="$t('enterprise.branchName')" prop="region" v-if="table.changeCny">
                    <el-select v-model="table.bankBranchCode" @change="changeBranch(table)" :inline="true" :placeholder="$t('common.plSelect')">
                        <el-option v-for="(item, index) in table.bankBranchList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <!-- 联行行号 -->
                <el-form-item :label="$t('enterprise.CNAPSCode')" prop="name" v-if="table.changeCny">
                    <el-input v-model="table.bankRelateNo" width="300px"></el-input>
                </el-form-item>
                <!-- 开户行swift code -->
                <el-form-item :label="'*'+ $t('enterprise.bankSWIFTBIC')" prop="name" v-if="!table.changeCny">
                    <el-input v-model="table.bankSwiftCode" @change="changeSwiftCode(table)" width="300px"></el-input>
                </el-form-item>
                <!-- 开户城市 -->
                <el-form-item :label="$t('enterprise.bankCity')" prop="name" v-if="!table.changeCny">
                    <el-input :disabled="true" v-model="table.bankCityName" width="300px"></el-input>
                </el-form-item>
                <!-- 开户银行 -->
                <el-form-item :label="$t('enterprise.bankName')" prop="region" v-if="!table.changeCny">
                    <el-input :disabled="true" v-model="table.bankName" width="300px"></el-input>
                </el-form-item>
                <!-- 所在支行 -->
                <el-form-item :label="$t('enterprise.branchName')" prop="region" v-if="!table.changeCny">
                    <el-input :disabled="true" v-model="table.bankBranchName" width="300px"></el-input>
                </el-form-item>
                <!-- 中转行swift code -->
                <el-form-item :label="$t('enterprise.intermediaryBankSWIFTBIC')" prop="name" v-if="!table.changeCny">
                    <el-input v-model="table.intermediaryBankSwiftCode" width="300px"></el-input>
                </el-form-item>
                <div class="content-right-line"></div>
            </el-form>
        </div>
    </div>
</template>

<script>
import '@/styles/element.css'
import { provinceAndCityData } from 'element-china-area-data'
export default {
    data(){
        return{
            enterpriseId:this.$route.query.id,
            options: [],
            bankCodeDict:[],//银行列表集合
            countryList:[],//国家集合
            loading:false,
            addShow:false,
            beneficiaryName:'',
            bankInfoList:[{
                currency:null,
                accountName:null,
                accountNo:null,
                bankCountryCode:null,
                bankCountryName:null,
                bankCityAll:null,
                bankCityCode:null,
                bankCityName:null,
                bankProvinceCode:null,
                bankProvinceName:null,
                bankCode:null,
                bankName:null,
                bankBranchList:[],
                bankBranchName:null,
                bankRelateNo:null,
                bankSwiftCode:null,
                intermediaryBankSwiftCode:null,
                changeCny:true,//是否选择人民币
            }],
            fixColumn:'',
            radio:'0',
            ruleForm: {
                name: '',
                region: '',
                companyName:'',
            },
            rules: {
                name: [
                    // { required: true, message: '请输入活动名称', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                region: [
                    // { required: true, message: '请选择活动区域', trigger: 'change' }
                ],
            },
            currencyObj:[],//币种枚举
        }
    },
    computed:{
        // currencyObj(){
        //     let arr =  this.getSysDictValue('币种kj.common.enums.CurrencyEnum')
        //     let filterArr = arr.filter(item=>{
        //         return  item['dictParam'] != 'ALL'
        //     })
        //     return filterArr
        // },
    },
    methods:{
        // 改变所在支行
        changeBranch(table){
            table.bankBranchList.map(item=>{
                if(item.id == table.bankBranchCode){
                    table.bankBranchName = item.name
                    table.bankRelateNo = item.unitedBankNumber
                }
                return item
            })
        },
        // 选择开户国家
        changeCountry(table){
            let country = this.countryList.filter(item=>{
                if(item.countryCode == table.bankCountryCode){
                    return item
                }
                return false
            })
            if(country.length>0){
                table.bankCountryName = country[0].countryName
            }
        },
        addBankInfo(){
            this.bankInfoList.push({
                currency:'',
                accountName:'',
                accountNo:'',
                bankCountryName:'',
                bankCityName:'',
                bankName:'',
                bankBranchList:[],
                bankBranchName:'',
                bankRelateNo:'',
                bankSwiftCode:'',
                intermediaryBankSwiftCode:'',
                changeCny:true,
            });
        },
        delBankInfo(index){
            this.bankInfoList.splice(index,1);
        },
        // 查询国家表
        async getCountry(){
            // this.loading =true;
            await this.$API.enterprise.getCountry({}).then((data)=>{
                let tl = data.data.data;
                this.countryList = tl;
                this.loading = false;
            },(response)=>{
                this.loading = false;
            })
        },
        // 根据id查询账户信息
        async listBankAccountByEnterpriseId(){
            this.loading =true;
            await this.$API.accountInfo.listBankAccountByEnterpriseId(this.enterpriseId).then((data)=>{
                let tl = data.data.data || []; 
                tl = this.filterArrayDict(tl);
                if(tl.length==0){
                    this.loading =false;
                }else{
                    let PromiseArr = []
                    tl.map(async item=>{
                        this.changeCurrency(item);
                        item.bankCityAll = item.bankCityCode
                        if(item.currency == 'CNY'){
                            PromiseArr.push(this.changeBank(item,'first'));
                        }
                        if(item.bankSwiftCode){
                            PromiseArr.push(this.changeSwiftCode(item));
                        }

                    })
                    // 所有异步处理完才赋值
                    Promise.all(PromiseArr).then((values)=>{
                        this.bankInfoList = tl
                    })
                    this.loading =false;
                }
            },(response)=>{
                this.loading =false;
            })
        },
        // 选择币种
        changeCurrency(table){
            if(table.currency == 'CNY'){
                table.changeCny = true;
                table.bankCountryCode = 'CNY';
                table.bankCountryName = '中国';
            }else{
                table.changeCny = false;
                table.bankBranchName = '';
                table.bankName = '';
                table.bankCityName = '';
                table.bankBranchCode = '';
            }
        },
        // 查询银行列表
        async queryBankCodeDict(){
            await this.$API.accountInfo.queryBankCodeDict({}).then((data)=>{
                let tl = data.data.data;
                this.bankCodeDict = tl;
            },(response)=>{})
        },
        handleChange (table,index) {          
            let thsAreaCode = this.$refs['cascader'][index].getCheckedNodes()[0].pathLabels;
            table.bankProvinceName = thsAreaCode[0];
            table.bankCityName = thsAreaCode[1];
            table.bankProvinceCode = table.bankCityAll[0].slice(0,2);
            table.bankCityCode = table.bankCityAll[1];
        },
        // 选择开户银行
        async changeBank(table,type){

            let arr = this.bankCodeDict.filter(item=>{
                return item.dictKey == table.bankCode
            })
            if(arr && arr.length > 0){
                table.bankName = arr[0].dispalyName
            }else {
                table.bankName = null
            }
            if(table.bankCityName == null){
                this.$message.error('请选择完整开户城市');
                
            }else{
                let bankCode = table.bankCode || '';
                let cityCode = table.bankCityCode || '';                   
                await this.$API.accountInfo.queryByRelative(bankCode,cityCode).then((data)=>{
                    if(type == 'first'){
                       
                    }else{
                        table.bankBranchName = '';
                        table.bankBranchCode = '';
                    }                   
                    let tl = data.data.data;
                    // let arr =tl.filter(item=>{
                    //     return item.id == table.bankBranchName
                    // })
                    table.bankBranchList = tl;
                    
                    
                },(response)=>{})
            }
        },
        // 输入swift
        changeSwiftCode(table){
            let query ={
                swiftcode:table.bankSwiftCode
            }
            this.$API.accountInfo.bankInfo(query).then((data)=>{
                let tl = data.data.data || [];
                if(tl.length == 0){
                    table.bankCityName = null;
                    table.bankName = null;
                    table.bankBranchName = null;
                    table.bankCountryName = null;
                    table.bankCountryCode = null;
                }else{
                    table.bankCityName = tl[0].country + '/' + tl[0].city;
                    table.bankName = tl[0].bankName;
                    table.bankBranchName = tl[0].branchName;
                    table.bankCountryName = tl[0].country;
                    table.bankCountryCode = tl[0].countryCode;
                }
                
            },(response)=>{})
        },
        // 保存银行账户信息
        saveSon(){
            // this.$emit('toSave',this.bankInfoList);
            this.loading = true;
            this.$API.kycFileCN.updateBankAccount(this.bankInfoList).then((data)=>{
                this.loading = false;
                let tl = data.data.data;
                if(data.data.code == '200'){
                    this.$message({
                        message: this.$t('login.savedSuccessfully'),
                        type: 'success'
                    });
                }
            },(response)=>{
                this.loading = false;
            })
        },
        nextSon(){
            this.bankInfoList.map(item=>{
                item.enterpriseId = this.enterpriseId;
                return item
            })
            // this.$emit('toNext',this.bankInfoList);
            this.loading = true;          
            this.$API.kycFileCN.handleAfterUpdateBank(this.bankInfoList).then((data)=>{                
                if(data.data.code == '200'){
                    this.loading = false;
                    this.$emit('toNext');
                }else{
                   this.$message.error(data.data.message);
                   this.loading = false; 
                }
            },(response)=>{
                this.loading = false;
            })
        },
        // 语言切换后获取枚举值事件
        langChangeEvent(){
            let arr =  JSON.parse(
                sessionStorage.getItem(
                    "币种kj.common.enums.CurrencyEnum"
                )
            );
            let filterArr = arr.filter(item=>{
                return  item['dictParam'] != 'ALL'
            })
            this.currencyObj = filterArr; 
        },
    },
    async mounted(){
        await this.getCountry();
        await this.queryBankCodeDict();
        this.listBankAccountByEnterpriseId();
        // 获取币种枚举
        let arr =  JSON.parse(
            sessionStorage.getItem(
                "币种kj.common.enums.CurrencyEnum"
            )
        );
        let filterArr = arr.filter(item=>{
            return  item['dictParam'] != 'ALL'
        })
        this.currencyObj = filterArr;        
    },
    created(){
        let tl = provinceAndCityData;
        tl.map(item=>{            
            if(item.value == "110000" && item.label == "北京市"){
                item.children[0].value = "110000"
                item.value = "110001"
            }else if(item.value == "120000" && item.label == "天津市"){
                item.children[0].value = "120000"
                item.value = "120001"
            }else if(item.value == "310000" && item.label == "上海市"){
                item.children[0].value = "310000"
                item.value = "310001"
            }else if(item.value == "500000" && item.label == "重庆市"){
                item.children[0].value = "500000"
                item.value = "500001"
            }
            return item
        })
        this.options = tl;
    }
}
</script>