import axios from 'axios'
export const getErrorMessage = async () => {
  try {
    const { data } = await axios.post('/dtt/error-message/cache-load', {})
    if (!data) {
      throw new Error(`${data.code}: ${data.message}`)
    }
    sessionStorage.setItem('errorMessageMap', JSON.stringify(data.data))
    return data.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}
