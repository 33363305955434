import { render, staticRenderFns } from "./dttTerms.vue?vue&type=template&id=39c054c1&scoped=true"
import script from "./dttTerms.vue?vue&type=script&lang=js"
export * from "./dttTerms.vue?vue&type=script&lang=js"
import style0 from "./dttTerms.vue?vue&type=style&index=0&id=39c054c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c054c1",
  null
  
)

export default component.exports