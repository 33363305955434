import axios from 'axios'
import router from '../routes'
import Vue from 'vue'
const vm = new Vue()

//lls-sso服务
import newsso from 'lls-sso'
import '@babel/polyfill'
import { getErrorMessage } from '@/api/projAPI/errorMessage.js'

//blob文件下载
const blodFileDown = response => {
  /**
   * @description: blob转文本，处理异常
   * @param {*} blob
   */
  function blobToText(blob) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsText(blob)
      fileReader.onload = function() {
        try {
          const result = JSON.parse(this.result)
          resolve(result)
        } catch (e) {
          //TODO handle the exception
          reject(e)
        }
      }
    })
  }
  //处理
  if (response.status == 200 && response.data.type != 'application/json') {
    //application/octet-stream 才是文件流
    const blob = new Blob([response.data])
    //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
    //IE10以上支持blob但是依然不支持download
    let realFileName = ''
    if (response['headers']['map']) {
      realFileName = response['headers']['map']['content-disposition'][0].split(
        'filename='
      )[1]
    } else {
      realFileName = response['headers']['content-disposition'].split(
        'filename='
      )[1]
    }
    //去除前后两个"并且中文解析
    const fileName = decodeURIComponent(
      realFileName.replace('"', '').replace('"', '')
    )
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //ie
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      //guge
      //支持a标签download的浏览器
      const link = document.createElement('a') //创建a标签
      link.download = fileName //a标签添加属性
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      document.body.appendChild(link)
      link.click() //执行下载
      URL.revokeObjectURL(link.href) //释放url
      document.body.removeChild(link) //释放标签
    }

    return
  } else {
    // 抓取后端异常报错
    blobToText(response.data).then(res => {
      // vm.$llsMessage({text: `${res.message}`,type:'info'});
      Vue.prototype.$message.error(res.message)
    })
    // vm.$llsMessage({type: 'error',text: "网络请求失败，请联系管理员"});
    return
  }
}
// let str = location.href;
// let sso = "";
// let devURL = "lianyirong";//开发域名关键字
// let uatURL = "qhhrly";//测试域名关键字
// let prdURL = "llschain";//生产域名关键字
// let demoURL = "hrlyit";//演示环境
let ssoUrl = ''
// //判断sso需要用到什么域名
// if(str.indexOf(devURL) > -1){
//     ssoUrl = 'https://sso-web.lianyirong.com.cn' //开发环境
//     // sso = Sso('https://lls-sso.lianyirong.com.cn');//开发环境
//
// }else if(str.indexOf(uatURL) > -1){
//     ssoUrl = 'https://sso-web.qhhrly.cn'; //测试环境
//     // sso = Sso('https://lls-sso.qhhrly.cn');//测试环境
//
// }else if((str.indexOf(prdURL) > -1)){
//     ssoUrl = 'https://sso-web.llschain.com'; //生产环境
//     // sso = Sso('https://lls-sso.llschain.com');//生产环境
//
// }else if((str.indexOf(demoURL) > -1)){
//     ssoUrl = 'https://sso-web.hrlyit.com'; //演示环境
//     // sso = Sso('https://lls-sso.hrlyit.com');//演示环境
//
// }else{
//     ssoUrl = 'https://sso-web.qhhrly.cn'; //测试环境
//     // ssoUrl = 'https://sso-web.lianyirong.com.cn'; //开发环境
//     // sso = Sso('https://lls-sso.qhhrly.cn');//测试环境
// }
let errorMessageMap = {}
getErrorMessage().then(res => {
  errorMessageMap = res
})
const dealZhMsg = ({ code, message }) => {
  const zhReg = new RegExp('[\\u4E00-\\u9FFF]+', 'g')
  if (zhReg.test(message)) {
    return (
      errorMessageMap?.errorCodeMessageMap[code] ||
      errorMessageMap?.defaultErrorMessage ||
      'Something went wrong. Please try again later'
    )
  } else {
    return (
      errorMessageMap?.errorCodeMessageMap[code] ||
      message ||
      errorMessageMap?.defaultErrorMessage ||
      'Something went wrong. Please try again later'
    )
  }
}
async function httpsAxios() {
  var ssoNew
  let alert
  let count = 0
  //http request 拦截器
  ssoNew = await newsso(ssoUrl)
  await Vue.use(ssoNew)
  axios.interceptors.request.use(ssoNew.getAxiosReqInterceptor())
  //添加自动化测试平台埋点
  axios.interceptors.request.use(
    (config) => {
      //国际化语言设置请求头
      const mapLanguage = {
        zh: 'zh-CN',
        en: 'en-US',
      }

      config['headers']['Accept-Language'] = mapLanguage['en']
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  //http response 拦截器
  axios.interceptors.response.use(
    ssoNew.getAxiosRespInterceptor(
      // eslint-disable-next-line complexity
      (tag, response) => {
        //lls-sso处理
        if (tag == 'success') {
          let res = {}
          res.body = response.data || {}
          res.data = response.data || {}
          //自己处理返回参数
          if (response.config.handingResponse) return res
          //下载文件流处理（报错信息返回的）
          if (response.config.responseType == 'blob') {
            blodFileDown(response)
            return
          }
          //一些统一code的返回处理
          let resCode = res.data.code || res.data.httpCode
          // 暂无响应，请联系管理员
          let errMsg =
            res.data.message ||
            res.data.msg ||
            window.vm.$t('login.serverNotRsponding')
          // 拿到整个response 返回出去
          if (resCode === '200' && response.config?.getResponse) {
            return { response, data: response.data }
          }
          if (resCode == '200') {
            //请求成功直接返回
            return res
          } else if (resCode == '01100' || resCode == '10900') {
            if (count == 0) {
              // 提示 重新登录
              alert = Vue.prototype.$alert(errMsg, window.vm.$t('login.tips'), {
                confirmButtonText: window.vm.$t('login.loginAgain'),
                callback: () => {
                  count = 0
                  router.replace('/login')
                },
              })
              count++
            }
            return Promise.reject(errMsg)
          } else {
            if (response.config.handingResponseErrMsg) {
              // 错误信息单独处理
              return Promise.reject(res.data)
            } else {
              Vue.prototype.$message.error(
                dealZhMsg({ code: resCode, message: errMsg })
              )
              return Promise.reject(dealZhMsg({ code: resCode, error: errMsg }))
            }
          }
        }
        //刷新页面
        if (tag == 'refresh') {
          return response
        }
        //把上一次暂停的请求继续下去
        if (tag == 'request') {
          return axios.request(response.config)
        }
        //重定向回login
        if (tag == 'redirect') {
          // 暂无响应，请联系管理员
          let errMsg =
            response.data.message ||
            response.data.msg ||
            window.vm.$t('login.serverNotRsponding')
          if (count == 0) {
            // 提示 重新登录
            alert = Vue.prototype.$alert(errMsg, window.vm.$t('login.tips'), {
              confirmButtonText: window.vm.$t('login.loginAgain'),
              callback: (action) => {
                const redirect = router.currentRoute.path
                const query = router.currentRoute.query
                if (
                  redirect === '/childDocExchange/pendingTask' ||
                  redirect === '/childDocExchange/transactionDetails'
                ) {
                  router.replace({
                    path: '/login',
                    query: { redirect, ...query },
                  })
                } else {
                  router.replace('/login')
                }
                count = 0
              },
            })
            count++
          }
          return Promise.reject(errMsg)
        }
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  )
  Vue.prototype.$http = axios
  Vue.http = axios
}

export default httpsAxios
