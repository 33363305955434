import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import zh from './config/zh'
import en from './config/en'
//兼容ele
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en',
  // locale: 'en',
  messages: {
    zh: { ...zh, ...zhLocale },
    en: { ...en, ...enLocale },
  },
})

export default i18n
