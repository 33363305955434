import Vue from 'vue'
const vm = new Vue()

const api = {}
/**
 * 查询授权人信息
 *
 */
// api.getManagerByEnterpriseId =  function(id) {
//     let url = `/kj-kyc/enterprise/getManagerByEnterpriseId?enterpriseId=${id}`
//     return vm.$http.get(url)
// }
// /**
//  * 上传文件
//  *
//  */
//  api.uploadFile = function(mediaId,catgId,queObj) {
//     let url = `/kj-media/media/opera/do/uploadMediaFile?modelCode=MKJ02CONTACT&catgId=${catgId}&busiKey=${mediaId}&sysCode=KJ_SYSTEM`
//     return vm.$http.post(url,queObj)

// }

/**
 * 查询是否需要签署CFCA
 *
 */
// api.getCfcaContractClient = function (data) {
//     let url = `/kj-contract/contractInfo/getCfcaContractClient`
//     return vm.$http.post(url, data)
// }

// 提交
api.signDigipoContract = function (data) {
    let url = `/kj-contract/contractInfo/signDigipoContract`
    return vm.$http.post(url, data)
}

/**查询是否需要签署CFCA */
api.getCfcaContractClient = function (data) {
    let url = `/kj-contract/contractInfo/getCfcaContractClient`
    return vm.$http.post(url, data)

}

/**查询是否需要签署电子债务凭证 */
api.getDigipoServiceContractClient = function (data) {
    let url = `/kj-contract/contractInfo/getDigipoServiceContractClient`
    return vm.$http.post(url, data)

}

export default api