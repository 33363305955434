<template>
    
</template>

<script>
    export default {
        name: "logoutPage",
        async created(){
            let _this =this
            try {
                var res = await this.$sso.logout()
                if(res){
                    this.$message({type: 'success',message: "登出成功"});
                    this.$router.replace('/login')
                }
                localStorage.clear()
            }catch (e) {
                var obj = JSON.parse(e.message)
                this.$message.error( obj.message);
            }
        },
    }
</script>
