import Vue from 'vue'
const vm = new Vue()

const api = {}
/**
 * 查询银行账户信息
 *
 */
api.listBankAccountByEnterpriseId =  function(id) {
    let url = `/kj-kyc/enterprise/listBankAccountByEnterpriseId?enterpriseId=${id}`
    return vm.$http.get(url)
}
/**
 * 查询中国银行
 *
 */
 api.queryBankCodeDict =  function(queObj) {
    let url = `/kj-kyc/bankDict/queryBankCodeDict`
    return vm.$http.post(url,queObj)
}
/**
 * 根据城市代码和银行名称查询
 *
 */
 api.queryByRelative =  function(bankCode,cityCode) {
    let url = `/kj-kyc/bankInfoCn/queryByRelativeCodeAndCityCode?bankCode=${bankCode}&cityCode=${cityCode}`
    return vm.$http.post(url)
}
/**
 * 查询国外银行
 *
 */
 api.bankInfo =  function(queObj) {
    let url = `/kj-kyc/bankInfo/list`
    return vm.$http.post(url,queObj)
}
export default api