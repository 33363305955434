//英文
export default {
  login: {
    account: 'Account',
    password: 'Password',
    verificationCode: 'Verification Code',
    login: 'Sign In',
    email: 'Email',
    forgetPwd: 'Forget password',
    resetPassword: 'Reset Password',
    logout: 'Logout',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    cancel: 'Cancel',
    confirm: 'Confirm',
    passwordIncorrectAgain:
      'The password you entered is incorrect. Please try again.',
    pleaseEnterNewPassword: 'Please enter your new password.',
    pleaseEnterOldPassword: 'Please enter your old password.',
    tips: 'Tips',
    savedSuccessfully: 'Saved Successfully.',
    submittedSuccessfully: 'Submitted Successfully.',
    logoutSuccessful: 'Logout Successful.',
    resetSuccessfullyAgain: 'Reset Successfully. Please sign again.',
    serverNotRsponding:
      'The server is not responding. Please contact your administrator. ',
    return: 'Return',
    send: 'Send',
    sendCode: 'Send Code',
    PleaseEnterUsername: 'Please enter your username.',
    pleaseEnterPassword: 'Please enter your password.',
    pleaseEnterVerification: 'Please enter the verification code.',
    pleaseEnterEmail: 'Please enter your email.',
    pleaseReenterPassword: 'Please re-enter your new password.',
    passwordResetLogin:
      'Password has been reset successfully. Please sign in  again.',
    pleaseResetPassword: 'Please reset your password.',
    verificationSentInbox:
      'Verification code has been sent. Please check your inbox.',
    noCompanyFoundContact:
      'No Company Found. Please contact your administrator for help.',
    onboardingStatusAbnormal:
      'Onboarding Status Abnormal. Please contact your administrator.',
    loginAgain: 'Sign In Again',
    noPermission: 'No permission. Please contact your administrator.',
    pswValidatePopover: {
      title: 'Your password must have:',
      rule1: '8-15 characters',
      rule2: 'Include Uppercase character',
      rule3: 'Include Lowercase character',
      rule4: 'Include number and symbol',
    },
    pswNotSame: 'Confirm New Password is inconsistent with the New Password',
    //请输入6位数字验证码
    pleaseEnterSixDigitVerificationCode: 'Please enter a 6-digit verification code',
    verificationCodeError:"Verification code error",
  },
  common: {
    documents: 'Documents',
    pleaseUploadClear:
      '(Please upload clear images，we support .pdf, .jpeg, .jpg, .png files)',
    name: 'Name',
    email: 'Email',
    phoneNo: 'Phone No.',
    positionCompany: 'Position at Company',
    IDType: 'ID Type',
    IDNo: 'ID No.',
    plSelect: 'Select',
    IDDocFront: 'ID Doc (Front)',
    IDDocBack: 'ID Doc (Back)',
    countdownMarketClosure: 'Countdown to Market Closure',
    hour: 'h',
    minute: 'm',
    second: 's',
    signedSuccessfully: 'Signed Successfully.',
  },
  tips: {
    agreedFollowingTerms:
      '1 you agreed to the following terms of this Platform Service Agreement;',
    constitutesElectronicSignature:
      '2 it constitutes your electronic signature to this Platform Service Agreement for the purposes of the Electronic Transaction Ordinance (Cap. 553 of the laws of Hong Kong).',
    clickingButtonConfirm:
      'By clicking this button, you agree and confirm that:',
    clickAgreeConfirm: 'Please click the button to agree and confirm',
    needAgreementSigning:
      'You need to read the entire agreement before signing.',
    confirmToSign: 'Confirm to sign',
    selectCompanyLogin: 'Please select company to sign in',
    agreementFirstPlatformServices:
      'You  need to sign the agreement first in order to better provide you with complete platform services.',
    noData: 'No Data',
  },
  enterprise: {
    tellYourCompany: 'Tell Us About Your Company',
    companyInfo: 'Basic Information',
    companyNameChinese: 'Company Name (Chinese)',
    companyNameEnglish: 'Company Name (English)',
    companyDocsType: 'Company Docs Type',
    companyDocsNo: 'Company Docs No.',
    countryIncorporation: 'Country of Incorporation',
    registeredCity: 'Registered City',
    registeredCityElse: 'Registered City',
    registeredAddress: 'Registered Address',
    certificateIncorporation: 'Certificate of Incorporation',
    companyDocsArticlesOrganization:
      'Company Docs (Articles of Association, Organization Chart etc.)',
    businessLicence: 'Business Licence',
    businessRegistration: 'Business Registration Certificate',
    incorporationForm: 'Incorporation Form',
    tellAboutYourself: 'Tell Us About Yourself',
    sameAuthorizedPerson: 'Same as Authorized Person',
    whoBeneficialOwner: 'Who is/are the Beneficial Owner(s) of the company ?',
    BONaturalPerson:
      'BO is any natural person with more than or equal to 25% of stake of the company',
    addBeneficialOwner: 'Add Beneficial Owner',
    deleteBeneficiaryOwner: 'Delete Beneficiary Owner',
    beneficialOwner: 'Beneficial Owner',
    tellYourBankAccount: 'Tell Us About Your Bank Account(s)',
    account: 'Account',
    deleteAccount: 'Delete Account',
    addAccount: 'Add Account',
    currency: 'Currency',
    bankAccountType: 'Bank Account Type',
    accountName: 'Account Name',
    bankAccountNo: 'Bank Account No.',
    bankName: 'Bank Name',
    bankCountry: 'Bank Country',
    bankCity: 'Bank City',
    CNAPSCode: 'CNAPS Code',
    branchName: 'Branch Name',
    bankSWIFTBIC: 'Bank SWIFT BIC',
    intermediaryBankSWIFTBIC: 'Intermediary Bank SWIFT BIC',
    eSignatureSubmit: 'e-Signature and Submit',
    pleaseDownloadDocument: 'Please download and sign the document',
    pleaseUploadDocument: 'Please upload the signed document here',
    download: 'Download',
    companyInformation: 'Company Information',
    personnalInformation: 'Personnal Information',
    accountInformation: 'Account Information',
    previous: 'Previous',
    save: 'Save',
    next: 'Next',
    submit: 'Submit',
    fileUploadedSuccessfully: 'File Uploaded Successfully',
    deleteSuccessfully: 'Delete Successfully',
    dragFilesHere: 'Drag files here or',
    browse: 'Browse',
    supportFormat: '(Each file < 20MB, JPG, PDF and PNG supported)',
    authenticationFailed: 'Authentication Failed',
    retry: 'Retry',
    legalRepresentative: 'Legal Representative',
    director: 'Director',
    info: ' Info',
    deleteDirector: 'Delete Director',
    addDirector: 'Add Director',
    success: 'Success',
    ourTeamReviewInformation: 'Our team will review the information',
    sendConfirmationEmail: 'and send you a confirmation email within 24 hours.',
  },
  bigHome: {
    digitalFinance: 'Digital Finance',
    digitalFinanceContent: 'Innovative Supply Chain Financing Approaches',
    digitalTrade: 'Digital Trade',
    digitalTradeContent:
      'Empowering International Trade with Artificial intelligence & Blockchain Technology',
    pendingTasks: 'Pending Tasks',
    linklogisInternational: 'Linklogis International',
    linklogisInternationalContent:
      'Committed to provide leading digital supply chain financing services to banks, financial institutions and corporates worldwide',
    downloadManagement: 'Download Management',
    No: 'No.',
    fileName: 'File Name',
    status: 'Status',
    createTime: 'Create Time',
    action: 'Action',
    download: 'Download',
  },
  menu: {
    moreProduct: 'More Product'
  },
  contract: {
    title: "Digipo Service Schedule",
    download: "Download",
    submit: "Submit",
    downloadAndSignfiles: "Please download and sign the document",
    uploadTheSignedDocument: "Please upload the signed document here",
    cfcaDigital: "'CFCA Digital Certificate Service'.",
    checkThisBox: "Select the checkbox and submit to sign the",
    pleaseCheck: 'Please check the "CFCA Digital Certificate Service"'
  },
  downloadMgm: {
    fileName: "File Name",
    status: "Batch Status",
  },
}
