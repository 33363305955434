<template>
  <div id="digipoPortal" class="micor-app-box"></div>
</template>

<script>
const getActiveRule = hash => location => location.hash.startsWith(hash)
import { loadMicroApp } from 'qiankun'

export default {
  name: 'digipoPortal',
  data() {
    return {
      microApp: null,
    }
  },
  watch: {
    $route: function(val, oldVal) {
      this.microApp.update({ routeChange: { to: val, from: oldVal } })
    },
  },
  mounted() {
    let currentRoute = this.$route.fullPath
    console.log(currentRoute, '加载应用前的路由地址')
    this.microApp = loadMicroApp(
      {
        name: 'digipoPortal', //应用名字
        entry:
          process.env.NODE_ENV === 'production'
            ? '/kj-digipoPortal/'
            : '//localhost:3010/', //默认会加载这个HTML 动态加载资源 （必须支持跨域）
        // fetch
        container: '#digipoPortal', //容器名
        activeRule: getActiveRule('#/child-digipoPortal'), //激活路径hash模式
        props: {
          fatherRouter: this.$router,
          currentRoute,
        },
      },
      {
        singular: false,
      }
    )
  },
  beforeDestroy() {
    this.microApp.unmount()
  },
}
</script>
