
//批量动态引入文件并且赋值
const req = require.context('./projAPI',false,/\.js$/)
const api = {}
req.keys().map(item=>{
  let fileName = item.replace(/\.\//,'').replace(/\.js$/,'')
  fileName = fileName.trim()
  api[fileName] = req(item).default
  return { [fileName]: req(item).default }
})
export default api;