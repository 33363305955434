<template>
  <el-popover style="margin-right: 10px;" ref="popover" v-model="showDownloadMgm" placement="bottom" @show="handlePopShow"
    :title="$t('bigHome.downloadManagement')" width="600" trigger="click" @hide="handleHide">
    <el-table :data="gridData" :loading="downloadTableLoading" height="300px">
      <el-table-column type="index" width="50" :label="$t('bigHome.No')">
      </el-table-column>
      <el-table-column width="180" :show-overflow-tooltip="true" property="fileName"
        :label="$t('downloadMgm.fileName')"></el-table-column>
      <el-table-column width="200" :label="$t('downloadMgm.status')">
        <template slot-scope="{row}">
          <span>{{ filterDictParam(row.status, 'displayName') }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" :label="$t('bigHome.action')" fixed="right">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleDownload(row)" size="small"
            :disabled="filterDictParam(row.status, 'dictParam') !== 'PACKED'">
            <i class="el-icon-download"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button slot="reference" class="download-mgm" v-popover:popover type="text"><img
        src="@/static/projImg/download.svg" alt="" /></el-button>
  </el-popover>
</template>

<script>
import { filterDictParam } from "@/common/util"
export default {
  data() {
    return {
      downloadTableLoading: false,
      timer: null,
      gridData: [],
      filterDictParam,
      showDownloadMgm: false
    }
  },
  methods: {
    handlePopShow() {
      this.getCurrentUserDownLoadTasks()
      this.timer = setInterval(() => {
        this.getCurrentUserDownLoadTasks()
      }, 3000)
    },
    handleDownload($row) {
      this.$http.request({
        url: "/tools-web/mediaTreePakage/mediaTreeDownload",
        method: "get",
        params: {
          id: $row.id
        },
        responseType: 'blob'
      })
    },
    handleHide() {
      this.timer && clearInterval(this.timer)
      console.log('handleHide')
    },
    getCurrentUserDownLoadTasks() {
      // this.downloadTableLoading = true
      this.$http
        .post('/tools-web/mediaTreePakage/list', {})
        .then(({ data: { data } }) => {
          this.gridData = data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          // this.downloadTableLoading = false
        })
    },

  },

  beforeMount() {
    window.vm.$on("digipo_showDownloadMgm", () => {
      setTimeout(() => {
        this.showDownloadMgm = true
      }, 0)

    })
  },
  beforeDestroy() {
    window.vm.$off("digipo_showDownloadMgm")
  }
}
</script>
