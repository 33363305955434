<style lang="scss" scoped>
$headerHeight: 56px;
.login {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  .login-header {
    height: $headerHeight;
    line-height: $headerHeight;
    padding-left: 48px;
    border-top: 2px solid #3e8cfa;
    background: #fff;
    &-wrap {
      display: flex;
      align-items: center;
      transform: translateY(50%);
      // width: 370px;
      // padding: 0 32px;
    }
    &-logo {
      margin-right: 8px;
    }
    &-title {
      font-size: 32px;
      font-weight: 600;
      color: #003c82;
    }
  }
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - #{$headerHeight});
    background: url('~@/assets/dttLogin/login-bg.png') 50% / cover no-repeat;
    .login-container-main {
      background-color: #fff;
      min-width: 370px;
      position: relative;
      padding: 32px;
      .login-container-form {
        margin-bottom: 80px;
      }

      .form-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
      }
      .lls-form {
        ::v-deep .lls-input__inner {
          height: 40px;
        }
        .lls-form-item {
          margin-bottom: 32px;
        }
        .appendSend {
          height: 40px;
          width: 100px;
        }
      }
      .register-box {
        .lls-form {
          margin-top: 10px;
        }
      }
      .login-container-power {
        color: #89929e;
        font-size: 12px;
        position: absolute;
        width: 100%;
        bottom: 40px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &-suffix {
          margin-right: 4px;
        }
        &-img {
          width: 104px;
          height: 30px;
        }
      }
    }
    .login-protocol {
      display: flex;
      align-items: center;
      .lls-checkbox {
        margin-right: 4px;
      }
    }
    ::v-deep .lls-tabs {
      .lls-tabs__item.is-active {
        color: #2864ff;
      }
      .lls-tabs__active-bar {
        background-color: #2864ff;
        height: 2px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .login {
    .login-header {
      padding: 0;
      &-wrap {
        margin: 0 auto;
      }
      // justify-content: center;
    }
    .login-container {
      background: #fff;
      align-items: flex-start;
    }
  }
}
::v-deep .lls-input-group__append {
  background-color: #c2c6cc;
  color: #fff;
  .lls-button {
    border-radius: 0;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 767px) {
  .lls-message {
    width: calc(100% - 80px);
    .lls-message__icon-warpper {
      min-width: 44px;
    }
  }
}
.messageStyle {
  .lls-message-box__header {
    padding: 24px 24px 0 20px;
  }
  .lls-message-box__title {
    display: flex;
    align-items: center;
    .lls-message-box__status {
      height: 30px;
      margin: 0;
    }
    .lls-message-box__status.lls-icon-info + span {
      font-size: 20px;
      color: black;
    }
  }
  .lls-message-box__content {
    text-align: left;
    margin-left: 40px;
    padding: 20px;
  }
}
</style>
<template>
  <!-- 
  TODO
  login 和 signUp 应该分开来
 -->
  <div class="login">
    <div class="login-header">
      <div class="login-header-wrap">
        <img class="login-header-logo" src="~@/assets/dttLogin/GLDB.svg" />
      </div>
    </div>
    <div class="login-container">
      <div class="login-container-main">
        <div class="login-container-form">
          <div class="form-title">Welcome</div>
          <lls-tabs v-model="activeName" @tab-click="handleTabClick">
            <lls-tab-pane label="Sign In" name="login">
              <lls-form :model="loginForm" :rules="rules" ref="loginForm">
                <lls-form-item prop="email">
                  <lls-input
                    v-model="loginForm.email"
                    maxlength="255"
                    placeholder="Email"
                  />
                </lls-form-item>
                <lls-form-item prop="pass">
                  <lls-input
                    show-password
                    v-model.trim="loginForm.pass"
                    autocomplete="off"
                    :placeholder="$t('login.password')"
                  ></lls-input>
                </lls-form-item>
                <lls-form-item prop="otp">
                  <lls-input
                    v-model="loginForm.otp"
                    maxlength="6"
                    placeholder="Email OTP"
                  >
                    <lls-button
                      slot="append"
                      type="primary"
                      @click="getLoginOTP"
                      :disabled="!!timerSend || loginSend"
                      :loading="sendloading"
                      class="appendSend"
                    >
                      {{ sendText }}
                    </lls-button>
                  </lls-input>
                </lls-form-item>
              </lls-form>
              <div class="login-protocol">
                <lls-checkbox v-model="isReadTermsLogin">
                  I agree to the
                </lls-checkbox>
                <lls-link
                  type="text"
                  @click="handleReadTerms"
                  style="color: #3E8CFA;"
                >
                  Terms and Conditions
                </lls-link>
              </div>
            </lls-tab-pane>
            <lls-tab-pane label="Sign Up" name="signUp">
              <lls-form :model="signUpForm" :rules="rules" ref="signUpForm">
                <lls-form-item prop="contactPersonName">
                  <lls-input
                    v-model="signUpForm.contactPersonName"
                    maxlength="50"
                    placeholder="Contact Person Name"
                  />
                </lls-form-item>
                <lls-form-item prop="email">
                  <lls-input
                    v-model="signUpForm.email"
                    maxlength="255"
                    placeholder="Email"
                  />
                </lls-form-item>
                <lls-form-item prop="newPass">
                  <pswValidatePopover ref="validPwd">
                    <lls-input
                      show-password
                      v-model.trim="signUpForm.newPass"
                      auto-complete="new-password"
                      placeholder="New Password"
                    ></lls-input>
                  </pswValidatePopover>
                </lls-form-item>
                <lls-form-item prop="confirmPass">
                  <!-- <pswValidatePopover> -->
                  <lls-input
                    show-password
                    v-model.trim="signUpForm.confirmPass"
                    auto-complete="new-password"
                    placeholder="Confirm Password"
                  ></lls-input>
                  <!-- </pswValidatePopover> -->
                </lls-form-item>
                <lls-form-item prop="otp">
                  <lls-input
                    v-model="signUpForm.otp"
                    maxlength="6"
                    placeholder="Email OTP"
                  >
                    <lls-button
                      slot="append"
                      type="primary"
                      class="appendSend"
                      :disabled="!!timerRegis || signUpSend"
                      :loading="sendloading"
                      @click="getRegisterOTP"
                    >
                      {{ regisText }}
                    </lls-button>
                  </lls-input>
                </lls-form-item>
              </lls-form>
              <div class="login-protocol">
                <lls-checkbox v-model="isReadTermsSignUp">
                  I agree to the
                </lls-checkbox>
                <lls-link @click.stop="handleReadTerms" style="color: #3E8CFA;">
                  Terms and Conditions
                </lls-link>
              </div>
            </lls-tab-pane>
          </lls-tabs>
          <lls-button
            type="primary"
            style="height: 40px; margin-top: 16px; width: 100%;"
            :disabled="loginBtnDisabled"
            :loading="loading"
            @click="handleConfirm"
          >
            {{ loginBtnName }}
          </lls-button>
        </div>
        <div class="login-container-power">
          <div class="login-container-power-suffix">
            Powered by
          </div>
          <img
            class="login-container-power-img"
            src="~@/assets/dttLogin/linklogis.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt'
import pswValidatePopover from '@/components/pswValidatePopover/index.vue'
import { getErrorMessage } from '@/api/projAPI/errorMessage.js'
const productType = [
  'ASSET_BACKED_TOKENISATION',
  'DIGITAL_TRADE_TOKEN',
  'IDD',
  'DOC_EXCHANGE',
  'PURCHASE_PAYMENT',
  'E_FINANCE',
  'MARKET',
  'TRADE_FINANCE',
  'DIGIPO',
]
export default {
  name: 'loginSSO',
  components: {
    pswValidatePopover,
  },
  data() {
    return {
      loading: false,
      sendloading: false,
      activeName: 'login',
      loginForm: {
        email: '',
        pass: '',
        otp: '',
      },
      signUpForm: {
        contactPersonName: '',
        newPass: '',
        confirmPass: '',
        email: '',
        otp: '',
      },
      signContractId: '',
      pdfUrl: '',
      isReadTermsSignUp: false,
      isReadTermsLogin: false,
      langString: this.$i18n.locale == 'zh' ? '简体中文' : 'English',
      sysChannel: 'kj_my_pc',
      currentComp: {}, //选择的企业
      sendText: 'Send',
      timerSend: null,
      regisText: 'Send',
      timerRegis: null,
      countdownTime: 60,
      loginClockDownTime: -1,
      registerClockDownTime: -1,
    }
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: 'Email cannot be empty.',
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: 'Email format is incorrect. Please re-enter your email',
            trigger: ['blur', 'change'],
          },
        ],
        pass: [
          {
            required: true,
            message: 'Password cannot be empty.',
            trigger: ['blur', 'change'],
          },
        ],
        otp: [
          {
            required: true,
            message: 'OTP cannot be empty.',
            trigger: ['blur', 'change'],
          },
          {
            validator: (rule, value, callback) => {
              if (!/^\d+$/.test(value) || value.length !== 6) {
                callback(new Error('OTP is incorrect'))
              } else {
                callback()
              }
            },
          },
        ],
        contactPersonName: [
          {
            required: true,
            message: 'Contact Person Name cannot be empty.',
            trigger: ['blur', 'change'],
          },
        ],
        newPass: [
          {
            required: true,
            message: 'New Password cannot be empty.',
            trigger: ['blur', 'change'],
          },
          {
            validator: (rule, value, callback) => {
              if (!Object.values(this.$refs.validPwd.validate).every(v => v)) {
                callback(
                  new Error('Your password does not met all requirements')
                )
              } else {
                callback()
              }
            },
          },
        ],
        confirmPass: [
          {
            required: true,
            message: 'Confirm Password cannot be empty.',
            trigger: ['blur', 'change'],
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.signUpForm.newPass) {
                callback(
                  new Error('Your password and confirm password do not match')
                )
              } else {
                callback()
              }
            },
          },
        ],
      }
    },
    loginBtnName() {
      return {
        login: 'Sign In',
        signUp: 'Sign up',
      }[this.activeName]
    },
    loginBtnDisabled() {
      let isCheck = false
      if (this.activeName === 'login') {
        isCheck =
          Object.values(this.loginForm).some(v => v === '') ||
          !this.isReadTermsLogin
      } else if (this.activeName === 'signUp') {
        isCheck =
          Object.values(this.signUpForm).some(v => v === '') ||
          !this.isReadTermsSignUp
      }
      return isCheck
    },
    regisDisabled() {
      // 判断是否有空值
      const hasNullVal = Object.values(this.signUpForm).some(v => {
        return !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@_$%^&*-]).{8,15}$/.test(
          v
        )
      })
      return hasNullVal
    },
    loginSend() {
      return !this.loginForm.email
    },
    signUpSend() {
      return !this.signUpForm.email
    },
  },
  watch: {
    $route: {
      handler(newRouter) {
        this.activeName = newRouter.name
      },
      immediate: true,
    },
  },
  async mounted() {
    sessionStorage.clear()
    // 贴牌
    // this.changeWhiteLabel()
    getErrorMessage()
    //判断参数国际化参数
    let urlLang = this.$route.query.lang || this.$i18n.locale
    if (urlLang == 'EN' || urlLang == 'En' || urlLang == 'en') {
      //英文环境
      this.changeLang('en')
    } else {
      //默认英文环境
      this.changeLang('en')
    }

    window.addEventListener('keydown', e => this.handleKeyPress(e))
  },
  destroyed() {
    window.removeEventListener('keydown', e => this.handleKeyPress(e))
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        if (this.activeName === 'login') {
          !!this.timerSend || this.loginSend ? this.loginSubmit() : ''
        } else if (this.activeName === 'signUp') {
          !!this.timerRegis || this.signUpSend ? this.register() : ''
        }
      }
    },
    handleTabClick(tab) {
      this.$router.push({ name: tab.name })
    },
    handleConfirm() {
      const submit = {
        login: this.loginSubmit,
        signUp: this.register,
      }
      submit[this.activeName]()
    },
    getRegisClockDown(isStart) {
      if (isStart) {
        this.registerClockDownTime = this.countdownTime
      }
      if (this.registerClockDownTime < 0) return
      this.timerRegis = setTimeout(() => {
        this.registerClockDownTime -= 1
        this.regisText = `${this.registerClockDownTime}s`
        if (this.registerClockDownTime <= 0) {
          this.regisText = 'Send'
          clearInterval(this.timerRegis)
          this.timerRegis = null
        } else {
          this.getRegisClockDown()
        }
      }, 1000)
    },
    // 注册验证码
    getRegisterOTP() {
      this.$refs.signUpForm.validateField(['email'], valid => {
        if (!valid) {
          this.sendloading = true
          this.$http
            .post(`/kj-kyc/userEnroll/dtt-sendVerificationCode`, {
              email: this.signUpForm.email,
            })
            .then(({ data }) => {
              if (data.code === '200') {
                this.$message.success('Verification code sent to your email')
                this.getRegisClockDown(true)
              }
            })
            .finally(() => {
              this.sendloading = false
            })
        }
      })
    },
    // 注册
    async register() {
      try {
        const validForm = await this.$refs.signUpForm.validate()
        if (!validForm) return
        if (this.signUpForm.newPass !== this.signUpForm.confirmPass) {
          this.$message.warning(
            'Your password and confirm password do not match'
          )
          return
        }
        this.loading = true
        // const { newPassword } = await this.getEncodePwd(this.signUpForm.newPass)

        const { data: getPublicKey } = await this.$http.post(
          `/kj-web/public/getPublicKey?channel=${this.sysChannel}`
        )
        const password = await this.encryptedData(
          this.signUpForm.newPass,
          getPublicKey.data.publicKey
        )
        const { data } = await this.$http
          .post(
            '/kj-kyc/userEnroll/dtt-enroll',
            {
              name: this.signUpForm.contactPersonName,
              password,
              serialNo: getPublicKey.data.serialNo,
              verificationCode: this.signUpForm.otp,
              email: this.signUpForm.email,
            },
            {
              handingResponseErrMsg: true,
            }
          )
          .catch(err => {
            this.loading = false
            if (err.code === '71011') {
              this.showHasRegistered()
            } else {
              this.$message.error(err.message)
            }
          })
        if (data.code === '71011') {
          return this.showHasRegistered()
        }
        if (data.code !== '200') return
        this.$refs.loginForm.resetFields()
        this.loginForm.email = this.signUpForm.email
        this.$refs.signUpForm.resetFields()
        this.$message.success(
          'Registration successful. Please sign in to continue'
        )
        this.$router.push({ name: 'login' })
        this.loading = false
      } catch (err) {
        this.loading = false
        // already sign up
        if (err.code === '71011') {
          this.showHasRegistered()
        }
      }
    },
    // 已注册提示
    showHasRegistered() {
      this.$confirm(
        'The email address already exists. Kindly proceed to sign in or update your email address',
        'Attention',
        {
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Sign in',
          type: 'info',
          customClass: 'messageStyle',
        }
      ).then(() => {
        this.$refs.signUpForm.resetFields()
        this.loginForm.email = this.signUpForm.email
        this.$router.push({ name: 'login' })
      })
    },
    // 登录验证码
    getLoginOTP() {
      this.$refs.loginForm.validateField(['email'], valid => {
        if (!valid) {
          this.sendloading = true
          this.$http
            .post(`/sso-web/otp/doSendOtp`, this.loginForm.email, {
              headers: {
                'Content-Type': 'text/plain',
              },
            })
            .then(({ data }) => {
              if (data.code === '200') {
                this.$message.success('Verification code sent to your email')
                this.getLoginClockDown(true)
              }
            })
            .finally(() => {
              this.sendloading = false
            })
        }
      })
    },
    getLoginClockDown(startClock) {
      if (startClock) {
        this.loginClockDownTime = this.countdownTime
      }
      if (this.loginClockDownTime < 0) return
      this.timerSend = setTimeout(() => {
        this.loginClockDownTime -= 1
        this.sendText = `${this.loginClockDownTime}s`
        if (this.loginClockDownTime <= 0) {
          this.sendText = 'Send'
          clearInterval(this.timerSend)
          this.timerSend = null
        } else {
          this.getLoginClockDown()
        }
      }, 1000)
    },
    // 登录提交
    async loginSubmit() {
      const validForm = await this.$refs.loginForm.validate()
      if (!validForm) return
      this.loading = true
      try {
        const { newPassword, publicKeySerialNo } = await this.getEncodePwd(
          this.loginForm.pass
        )
        const { data } = await this.$API.system.dttDoLogin({
          ifRemember: false, //是否记住
          loginName: this.loginForm.email, //用户账号*
          password: newPassword, //用户密码*
          imgCode: this.loginForm.otp, // 验证码
          encrypt: true, //ATTENTION 是否加密，暂时可以不加密，过段时间宽哥会强制加密
          sysChannel: this.sysChannel, //所属系统*
          publicKeySerialNo,
        })
        if (data.code !== '200') {
          this.loading = false
          return
        }
        if (data.switchCode && data.switchCode == '30900') {
          //TODO 新用户请初始化密码 ;
          this.$message.success(this.$t('login.pleaseResetPassword'))
          this.loading = false
          return
        }

        // 获取企业
        const {
          data: enterpriseData,
        } = await this.$http.post('/kj-sys/kjEnterprise/getEnterpriseByType', {
          productType,
        })
        // 没有企业，无法进入
        if (enterpriseData.code !== '200' || !enterpriseData.data.length) {
          this.$alert(
            this.$t('login.noCompanyFoundContact'),
            this.$t('login.tips'),
            {
              confirmButtonText: this.$t('login.confirm'),
            }
          )
          this.loading = false
          return
        }
        // 2024年5月27日 暂不支持多企业，默认进入第0个
        const curEnterprise = enterpriseData.data[0]
        // set org id
        await this.$http.get(`/kj-web/sys/setOrgId?orgId=${curEnterprise.id}`)
        // check ip whitelist
        await this.$http.post('/dtt/user/customer/dtt-ip-whitelisting/match')
        sessionStorage.setItem('enterpriseId', curEnterprise.id)
        sessionStorage.setItem('enterpriseRole', curEnterprise.enterpriseRole)
        const { data: menuData } = await this.$http.post('/kj-sys/menu/getEnterpriseMenuList', {
          sysChannel: 'kj_my',
          enterpriseId: curEnterprise.id,
          loginName: this.loginForm.email,
        })
        if(menuData.code !== '200' || !menuData.data.length) {
          this.loading = false
          return
        }
        const menus = menuData.data[0]
        this.$router.push({ path: menus.children[0].url })
      } catch (err) {
        this.loading = false
        console.error('err', err)
      }
    },
    // 加密密码
    async getEncodePwd(pwd) {
      try {
        let { data, response } = await this.$http.post(
          `/sso-web/code/getLoginPublicKey`,
          {
            loginName: this.loginForm.email || '',
            sysChannel: this.sysChannel || '', //系统标识
          },
          {
            getResponse: true,
          }
        )
        if (data && data.code == 200) {
          let jsencrypt = new JSEncrypt()
          jsencrypt.setPublicKey(data.data)
          return {
            newPassword: jsencrypt.encrypt(pwd),
            publicKeySerialNo: response.headers.publickeyserialno,
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    //修改语言
    changeLang(lang) {
      localStorage.setItem('locale', lang)
      this.$i18n.locale = lang // dyy 通过切换locale的值来实现语言切换
      if (lang == 'zh') {
        this.langString = '简体中文'
      } else {
        this.langString = 'English'
      }
      //通知子应用更新
      this.$setGlobalState({ langlocale: lang })
    },
    handleReadTerms() {
      // const res = `${window.location.protocol}//${window.location.host}/#/dttTerms`
      const res = `https://glcoin.io/terms-and-conditions/`
      window.open(res, '_blank')
    },
    clearMsgErr(formName) {
      if (typeof formName == 'string') {
        this.$refs[formName].clearValidate()
      }
    },
    // async changeWhiteLabel () {
    //   const { data } = await this.$API.system.getNoAuthAllWhiteLabel()
    //   const whiteLabelList = data.data
    //   this.$store.commit('saveWhiteLabel', whiteLabelList)

    //   this.projectName = whiteLabelList?.loginTitle || 'Project Guardian'
    //   document.title = whiteLabelList?.documentTitle || 'Project Guardian'
    //   // 登录页图标
    //   const loginFavicon = whiteLabelList?.loginFavicon ?(Buffer.from(whiteLabelList.loginFavicon, 'base64')).toString('binary') : ''
    //   // document.querySelector('.logo-box').innerHTML = loginFavicon

    //   // 改变标签页的图标
    //   // 先移除现有的
    //   const documentFavicon = whiteLabelList?.documentFavicon ? Buffer.from(whiteLabelList.documentFavicon, 'base64') : ''
    //   // 拼装图标用于标签页的图标
    //   if (documentFavicon) {
    //     document.querySelector('link[rel="icon"]').remove()
    //     const blob = new Blob([documentFavicon]);
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('link');
    //     link.rel = 'icon';
    //     link.type = 'image/svg+xml';
    //     document.head.appendChild(link);
    //     link.href = url;
    //   }
    // },
  },
}
</script>
