<template>
  <div class="aContract">
    <table></table>
    <div class="aContractWhite">
      <div class="title">{{ $t('contract.title') }}</div>
      <!-- 步骤条 -->
      <div class="aStep">
        <div class="aRound">1</div>
        <div class="aText mLeft">{{ $t('contract.downloadAndSignfiles') }}</div>
        <div class="aLine mLeft"></div>
        <div class="aRound mLeft">2</div>
        <div class="aText mLeft">
          {{ $t('contract.uploadTheSignedDocument') }}
        </div>
      </div>
      <!-- 预览电子债权凭证产品和服务协议 -->
      <div style="display: flex; justify-content: center">
        <div v-for="(o, index) in list" :label="o.name" :name="`${index + 1}`" :key="index" :lazy="true" :noCache="true">
          <div class="i18Pdfbox">
            <lls-i18n-pdf-viewer :delele-btn="false" :src="o.src" :file-name="o.name">
            </lls-i18n-pdf-viewer>
          </div>
        </div>
        <div style="margin-left: 15px; display: flex">
          <!-- <div style="color: red; margin-right: 5px">*</div> -->
          <kycUpload :mediaId="mediaId" modelCode="MKJ03CONTRACT" catgId="CCONTRACT012" @change="handleChange"
            :isDigipo="true"></kycUpload>
        </div>
      </div>
      <!-- 协议 -->
      <div class="cfcaClass" v-if="isCfCA">
        <!-- `checked` 为 true 或 false -->
        <el-checkbox v-model="checked">{{ $t('contract.checkThisBox') }}
          <a href="https://www.cfca.com.cn/20150811/101230094.html">{{
            $t('contract.cfcaDigital')
          }}</a></el-checkbox>
      </div>
      <!-- 按钮 -->
      <div class="aBtn mTop15">
        <el-button type="primary" plain @click="handleDownload">{{
          $t('contract.download')
        }}</el-button>
        <el-button :type="isType" @click="handleSubmit" :disabled="isDisabled">{{ $t('contract.submit') }}</el-button>
        <!-- <el-button v-else type="info" disabled>
        {{ $t('contract.submit') }}</el-button
      > -->
      </div>
    </div>
  </div>
</template>

<script>
import kycUpload from '../kycFile/components/kycUpload'
export default {
  data() {
    return {
      num: 0,
      contractTypeCFCA: null, // CFCA
      contractTypeDz: null, // 电子
      isCfCA: false, // 是否需要签署 CFCA
      checked: false, // 是否勾选 CFCA
      isType: 'primary', // 按钮类型
      isDisabled: true, // 按钮是否禁用 为true禁用
      list: [
        {
          type: 'pdf',
          name: '流转单.pdf',
          src: '/uploadFiles/ignoreLogin/agreement/Service%20Sch%20-%20Digipo.pdf',
          fileTypeEnum: 'transApply',
        },
      ],
    }
  },

  methods: {
    handleChange(val) {
      this.num = val
    },

    // 下载模板
    handleDownload() {
      let iframe = document.createElement('iframe')
      iframe.src =
        'https://cbf-my.qhhrly.cn/uploadFiles/ignoreLogin/agreement/Service%20Sch%20-%20Digipo.docx'
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
      setTimeout(() => {
        document.body.removeChild(iframe)
      }, 1000)
    },

    // 提交  contractType：
    handleSubmit() {
      if (this.num === 0) {
        this.$message({
          message: this.$t('contract.uploadTheSignedDocument'),
          type: 'error',
        })
        return
      }

      if (this.isCfCA == true) {
        if (this.checked == true) {
          this.$API.contract
            .signDigipoContract({ contractType: this.contractTypeCFCA })
            .then((res) => {
              if (res.data.code == 200) {
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error',
                })
              }
            })
          this.$API.contract
            .signDigipoContract({ contractType: this.contractTypeDz })
            .then((res) => {
              if (res.data.code == 200) {
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error',
                })
              }
            })
          this.$router.push({ path: '/child-digipoPortal/home' })
        } else {
          this.$message({
            message: this.$t('contract.pleaseCheck'),
            type: 'error',
          })
        }
      } else {
        this.$API.contract
          .signDigipoContract({ contractType: this.contractTypeDz })
          .then((res) => {
            if (res.data.code == 200) {
            } else {
              this.$message({
                message: res.data.message,
                type: 'error',
              })
            }
          })
        this.$router.push({ path: '/child-digipoPortal/home' })
      }
    },
  },

  components: {
    kycUpload,
  },

  created() {
    this.mediaId = this.$route.query.mediaId
    this.contractTypeDz = this.$route.query.contractTypeDz
    this.$API.contract.getCfcaContractClient({}).then((data) => {
      if (data.data?.code == 200) {
        if (!data.data.data) {
          //如果没有返回时也就是不用签署CFCA
          this.isCfCA = false
        } else {
          this.isCfCA = true
          this.contractTypeCFCA = this.filterJSONString(
            data.data.data.contractType
          )
        }
      }
    })

    // this.$API.contract.getDigipoServiceContractClient({}).then((res) => {
    //   if (res.data.data?.code == 200) {
    //     if (!data.data.data) {
    //     } else {
    //       this.contractTypeDz = res.data.data.data.contractType
    //     }
    //   }
    // })

    if (this.isCfCA == true) {
      this.isType = 'info'
      this.isDisabled = true
      // this.isXy = false
    } else {
      this.isType = 'primary'
      this.isDisabled = false

      // this.isXy = true
    }
  },

  watch: {
    checked(val) {
      if (val == true) {
        this.isType = 'primary'
        this.isDisabled = false
      } else {
        this.isType = 'info'
        this.isDisabled = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.aContract {
  background: rgb(247, 250, 255);
  // padding-bottom: 3vh;
  height: 100vh;
}

.aContractWhite {
  background: rgb(255, 255, 255);
  width: 97%;
  // height: 95vh;
  margin: 0 auto;
  margin-top: 15px;
  padding-bottom: 2.5vh;
}

.title {
  font-size: 20px;
  color: rgb(46 90 153);
  font-weight: 600;
  text-align: center;
  padding-top: 2vh;
}

.aStep {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5vh;
  margin-bottom: 2vh;
}

.aRound {
  border-radius: 50%;
  background: rgb(62, 140, 249);
  color: #fff;
  width: 35px;
  height: 27px;
  text-align: center;
  padding-top: 7px;
}

.aText {
  font-size: 18px;
  font-weight: 500;
}

.aLine {
  width: 200px;
  height: 1.5px;
  background: rgb(62, 140, 249);
}

.mLeft {
  margin-left: 15px;
}

.mTop15 {
  margin-top: 1.5vh;
}

.aBtn {
  text-align: center;

  .el-button {
    line-height: 0.5;
  }

  //   .el-button--primary {
  //     background: linear-gradient(to right, rgb(76 149 251), rgb(146 192 254));
  //   }
}

::v-deep .kyc-upload {
  width: 40vw !important;
  height: 100%;
}

::v-deep .el-upload-dragger {
  width: 40vw !important;
  height: 75vh;
}

::v-deep .el-upload-dragger .el-upload__text {
  margin-top: 240px;
}

@media screen and (max-width: 1920px) {
  ::v-deep .upload-icon {
    position: absolute;
    top: 234px;
    left: 13.5vw;
  }
}

@media screen and (max-width: 1700px) {
  ::v-deep .upload-icon {
    position: absolute;
    top: 234px;
    left: 12vw;
  }
}

::v-deep .el-upload-list__item-name {
  display: none;
}

.cfcaClass {
  margin-left: 7.7vw;
  margin-top: 0.5vh;
}

.i18Pdfbox {
  width: 40vw;
  height: 75vh;
  border: 1px dashed rgb(225, 225, 225);
  border-radius: 5px;
}
</style>
