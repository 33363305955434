<template>
	<div id="appRoot" v-if="$store.state.projectFinishFlag">
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'app',
	components: {}
}
</script>

<style lang="scss">
body {
	margin: 0px;
	padding: 0px;
	/*background: url(assets/bg1.jpg) center !impo rtant;
		background-size: cover;*/
	// background: #1F2D3D;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
}

html,
body,
#appRoot {
	height: 100%;
}

#appRoot {
	overflow: hidden;
}

#app {
	/*position: absolute;*/
	/*top: 0px;*/
	/*bottom: 0px;*/
	/*width: 100%;*/
}

.el-submenu [class^=fa] {
	vertical-align: baseline;
	margin-right: 10px;
}

.el-menu-item [class^=fa] {
	vertical-align: baseline;
	margin-right: 10px;
}

.toolbar {
	padding: 10px; //border:1px solid #dfe6ec;
	margin: 10px 0px;

	.el-form-item {
		margin-bottom: 10px;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: all .2s ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

// 滚动条
::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track {
	--webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.1);
	--webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0, 0, 0, 0.1);
}</style>